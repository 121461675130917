class Chain3 {
     constructor(service, successor) {
          this.service = service;
          this.successor = successor;
     }
     handleRequest(payload){
          //get only required param
          const {cumulatedGoldWonAfterReachingTotalEnemies, cumulatedGoldFromQuest, percentageGoldAllBonus} = payload;

          //cumulate gold won (first time => percentageGoldAllBonus = 0)
          const cumulatedGoldWon = this.service.getTotalGoldOnLevelReach(
              cumulatedGoldWonAfterReachingTotalEnemies,
              cumulatedGoldFromQuest, percentageGoldAllBonus
          );


          //prepare new payload
          let newPayload = {
               cumulatedGoldWon
          }

          //merge old and new to return payload
          let returnPayload = Object.assign({}, payload, newPayload)

          //forward to another successor or return
          if (this.successor) {
               return this.successor.handleRequest(returnPayload);
          } else {
               return returnPayload;
          }
     }
}

export default Chain3;