<template>
    <div>
        &Sigma;$D Boss: <strong>{{ kpiDiamondBossCollect }}</strong>
        <br/>&Sigma;$D Achievement: <strong>{{ kpiDiamondAchievementCollect }}</strong><v-label @click="onViewAchievement"> 👁️‍🗨️ </v-label>
        <br/>&Sigma;$D Total: <strong>{{ kpiTotalDiamonds }}</strong>
        <br/>$D available for pet ({{ kpiRatioAllocatedToPet }}%): <strong>{{ kpiDiamondAvailableForPet }}</strong>
<!--        <br/>PetElement: <strong>{{ kpiPetElementType }}</strong>-->

        <v-dialog
                max-width="600"
                v-model="shouldShowAchievement"
                @close="shouldShowAchievement = false"
        >
            <AchievementListPopup
                    v-if="shouldShowAchievement"
                    :pAchievementList="kpiAchievementList"
                    :pReward="kpiAchievementReward"
            />
        </v-dialog>
    </div>
</template>

<script>
import ElementTypeAndName from "../../constants/ElementTypeAndName";
import AchievementListPopup from "./components/AchievementListPopup";

export default {
    name: "GroupDiamondCollect",
    props: ['payload'],
    components: {
        AchievementListPopup,
    },
    data: () => ({
        shouldShowAchievement: false,
    }),
    computed: {
        kpiDiamondBossCollect () {
          const {kpiDiamondBossCollect} = this.payload;
          return kpiDiamondBossCollect;
        },
        kpiDiamondAchievementCollect () {
            const {kpiDiamondAchievementCollect} = this.payload;
            return kpiDiamondAchievementCollect;
        },
        kpiTotalDiamonds () {
            const {kpiTotalDiamonds} = this.payload;
            return kpiTotalDiamonds;
        },
        kpiRatioAllocatedToPet () {
            const {kpiRatioAllocatedToPet} = this.payload;
            return kpiRatioAllocatedToPet;
        },
        kpiDiamondAvailableForPet () {
            const {kpiDiamondAvailableForPet} = this.payload;
            return kpiDiamondAvailableForPet;
        },
        kpiPetElementType(){
            const {teamBattleInfo} = this.payload;
            if(teamBattleInfo.petData){
                return ElementTypeAndName[teamBattleInfo.petData.elementType];
            } else {
                return 'NONE';
            }
        },
        kpiAchievementList(){
            const {kpiAchievementCompleteList} = this.payload;
            return kpiAchievementCompleteList;
        },
        kpiAchievementReward(){
            const {kpiDiamondAchievementCollect} = this.payload;
            return kpiDiamondAchievementCollect;
        },
    },
    methods: {
        onViewAchievement(){
            this.shouldShowAchievement = true;
        }
    },
}
</script>

<style scoped>

</style>