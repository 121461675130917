import { render, staticRenderFns } from "./PanelMarketItemRequire.vue?vue&type=template&id=4061bfaa&scoped=true&"
import script from "./PanelMarketItemRequire.vue?vue&type=script&lang=js&"
export * from "./PanelMarketItemRequire.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4061bfaa",
  null
  
)

export default component.exports