class CaptureKpiUtils {
    constructor(localStorageUtils){
        this.captureKpiResults = [];

        this.localStorageUtils = localStorageUtils;

        //load to local storage
        let localCaptures = this.localStorageUtils.getObject("captureKpiResults");
        if(localCaptures){
            this.captureKpiResults = localCaptures;
        }
    }

    captureKpiResultSet(kpiResultSet){
        //remove exiting exiting
        if(this.captureKpiResults.length > 0){
            this.captureKpiResults = this.captureKpiResults.filter(rs => rs.kpiGameLevel !== kpiResultSet.kpiGameLevel);
        }

        //add new capture
        this.captureKpiResults.push(kpiResultSet);

        this.saveKpiCaptures();
    }

    clearKpiCaptures(){
        this.captureKpiResults = [];
        this.saveKpiCaptures();
    }

    saveKpiCaptures(){
        //save to local storage
        this.captureKpiResults = this.localStorageUtils.saveObject("captureKpiResults", this.captureKpiResults);
    }
}

export default CaptureKpiUtils;