<template>
    <v-card
        min-width="550"
        class="mt-2"
        elevation="0"
    >
        <div class="font-weight-black">DIAMOND SETTING ({{ kpiTotalDiamonds }})</div>
        <v-card
            class="pt-4 pb-0"
            elevation="0">
            <v-subheader>Allocated to Pet ({{kpiDiamondAvailableForPet}} of {{kpiTotalDiamonds}})</v-subheader>
            <v-slider
                v-model="ratioAllocatedToPet"
                track-color="grey"
                @input="onRatioPetChange"
                always-dirty
                thumb-label
                min="0"
                max="100"
            >
                <template v-slot:append>
                    <v-text-field
                        v-model="ratioAllocatedToPet"
                        class="mt-0 pt-0"
                        type="number"
                        min="0"
                        max="100"
                    ></v-text-field>
                </template>
            </v-slider>
        </v-card>
    </v-card>
</template>

<script>
export default {
    name: "PanelDiamondConfig",
    props:["payload", "defaultInputSet"],
    created() {
        this.ratioAllocatedToPet = this.defaultInputSet.ratioAllocatedToPet;
    },
    data() {
        return {
            ratioAllocatedToPet: 50
        }
    },
    computed: {
        kpiTotalDiamonds () {
            const { kpiTotalDiamonds } = this.payload;
            return kpiTotalDiamonds;
        },
        kpiDiamondAvailableForPet () {
            const { kpiDiamondAvailableForPet } = this.payload;
            return kpiDiamondAvailableForPet;
        }
    },
    methods: {
        onRatioPetChange() {
            this.emitState();
        },
        emitState(){
            const outPayload = {
                ratioAllocatedToPet: this.ratioAllocatedToPet
            }

            this.$emit("onUpdateSettingDiamond", outPayload);
        }
    }
}
</script>

<style scoped>

</style>