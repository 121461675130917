class Chain7 {
     constructor(service, successor) {
          this.service = service;
          this.successor = successor;
     }
     handleRequest(payload){
          //get only required param
          const {
               percentageGoldBonus,
               supportSkills,
               percentageGoldFromPet,
               percentControlSupportGoldSkill,
               percentControlPetGoldSkill,
               isUseSupportSkillGold,
               isUsePetSkillGold,
               heroGoldSkillBonus,
               percentControlHeroGoldSkill
          } = payload;

          //update gold bonus from all
          const goldPercentagePayload = {
               percentageGoldBonus,
               percentageGoldFromSupport:supportSkills.skillGoldBonus,
               percentageGoldFromPet,
               percentControlSupportGoldSkill,
               percentControlPetGoldSkill,
               isUseSupportSkillGold,
               isUsePetSkillGold,
               heroGoldSkillBonus,
               percentControlHeroGoldSkill
          }

          const percentageGoldAllBonus = this.service.getPercentageGoldBonus(goldPercentagePayload);


          //prepare new payload
          let newPayload = {
               percentageGoldAllBonus
          }

          //merge old and new to return payload
          let returnPayload = Object.assign({}, payload, newPayload)

          //forward to another successor or return
          if (this.successor) {
               return this.successor.handleRequest(returnPayload);
          } else {
               return returnPayload;
          }
     }
}

export default Chain7;