//import QuestSimulationService from "@/quest-editor/services/QuestSimulationService";
//import lodash from "lodash";
import SMPNum from "@/SMPNum";

class Chain2 {
     constructor(service, successor, simulationService, dailySimulationService) {
          this.service = service;
          this.successor = successor;
          this.simulationService = simulationService;
          this.dailySimulationService = dailySimulationService;
     }
     handleRequest(payload){
          let totalQuestGold = new SMPNum(0);
          let totalQuestCount = 0;

          //get only required param
          const {gameLevel, dailyGameLevelPerDay, isIncludeMiniQuestGold, isIncludeDailyQuestGold, percentIncludeMiniQuestGold, percentIncludeDailyQuestGold} = payload;

          if(isIncludeMiniQuestGold){
               //compute quests after reach to gameLevel, consider we finish previous gameLevel (gameLevel - 1)
               this.simulationService.initGameQuests();
               const {reward: miniQuestGold, countCompleted: miniQuestCount} = this.simulationService.computeRewardForAllQuests(gameLevel - 1, 0);

               let goldInclude = SMPNum.multSmpNum(miniQuestGold, new SMPNum(percentIncludeMiniQuestGold/100));
               totalQuestGold = SMPNum.plus(totalQuestGold, goldInclude);
               totalQuestCount += miniQuestCount;
          }

          if(isIncludeDailyQuestGold){
               //compute daily quest
               this.dailySimulationService.initGameQuests();
               const {reward: dailyQuestGold, countCompleted: dailyQuestCount} = this.dailySimulationService.computeRewardForAllQuests(gameLevel - 1, dailyGameLevelPerDay);

               let goldInclude = SMPNum.multSmpNum(dailyQuestGold, new SMPNum(percentIncludeDailyQuestGold/100));
               totalQuestGold = SMPNum.plus(totalQuestGold, goldInclude);
               totalQuestCount += dailyQuestCount;
          }

          const cumulatedGoldFromQuest = totalQuestGold;
          const questCount = totalQuestCount;

          //prepare new payload
          let newPayload = {
               cumulatedGoldFromQuest,
               questCount
          }

          //merge old and new to return payload
          let returnPayload = Object.assign({}, payload, newPayload)

          //forward to another successor or return
          if (this.successor) {
               return this.successor.handleRequest(returnPayload);
          } else {
               return returnPayload;
          }
     }
}

export default Chain2;