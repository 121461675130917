<template>
    <v-card
        class="mt-2"
        elevation="0"
        outlined
    >
        <v-subheader><strong>SHARING GOLD BETWEEN HEROES LEVELS AND SKILLS ({{validatedGoldAvailable.ToReadableAlphabetV2()}})</strong></v-subheader>
        <v-subheader>Allocate to Level ({{ goldAllocatedToHeroLevels }} of
            {{ validatedGoldAvailable.ToReadableAlphabetV2() }})</v-subheader>
        <v-card-text class="py-0">
            <v-slider
                dense
                v-model="percentageAllocatedToHeroLevels"
                @change="onChangedPercentageAllocatedToHeroLevels"
                track-color="grey"
                thumb-label
                min="0"
                max="100"
            >
                <template v-slot:append>
                    <v-text-field
                        v-model="percentageAllocatedToHeroLevels"
                        @input="onInputPercentageAllocatedToHeroSkills"
                        style="width: 50px"
                        dense
                        type="number"
                        min="0"
                        max="100"
                    ></v-text-field>
                </template>
            </v-slider>
        </v-card-text>

        <v-subheader>Allocate to Skills ({{ goldAllocatedToHeroSkills }} of
            {{ validatedGoldAvailable.ToReadableAlphabetV2() }})</v-subheader>
        <v-card-text class="py-0">
            <v-slider
                dense
                v-model="percentageAllocatedToHeroSkills"
                @change="onChangedPercentageAllocatedToSupports"
                track-color="grey"
                thumb-label
                min="0"
                max="100"
            >
                <template v-slot:append>
                    <v-text-field
                        v-model="percentageAllocatedToHeroSkills"
                        @input="onInputPercentageAllocatedToSupports"
                        style="width: 50px"
                        dense
                        type="number"
                        min="0"
                        max="100"
                    ></v-text-field>
                </template>
            </v-slider>
        </v-card-text>

    </v-card>
</template>

<script>
import SMPNum from "@/SMPNum";

export default {
    name: "SettingGoldBetweenHeroLevelsAndSkills",
    props: ["goldAvailableForHeroes", "defaultInputSet"],
    data() {
        return {
            percentageAllocatedToHeroLevels: 50,
            percentageAllocatedToHeroSkills: 50
        }
    },
    created() {
        this.percentageAllocatedToHeroSkills = this.defaultInputSet.ratioAllocatedToHeroSkill;
        this.percentageAllocatedToHeroLevels = 100 - this.percentageAllocatedToHeroSkills;
    },
    computed: {
        validatedGoldAvailable(){
            if(!this.goldAvailableForHeroes) {
                return SMPNum.fromNum(0);
            }else {
                return this.goldAvailableForHeroes;
            }
        },
        goldAllocatedToHeroLevels(){
            const totalGold = this.validatedGoldAvailable;
            return SMPNum.multSmpNum(totalGold, SMPNum.fromNum(this.percentageAllocatedToHeroLevels / 100)).ToReadableAlphabetV2();
        },
        goldAllocatedToHeroSkills(){
            const totalGold = this.validatedGoldAvailable;
            return SMPNum.multSmpNum(totalGold, SMPNum.fromNum(this.percentageAllocatedToHeroSkills / 100)).ToReadableAlphabetV2();
        },
    },
    methods: {
        onChangedPercentageAllocatedToHeroLevels() {
            if (this.percentageAllocatedToHeroLevels > 100) {
                this.percentageAllocatedToHeroLevels = 100;
            }
            this.percentageAllocatedToHeroSkills = 100 - this.percentageAllocatedToHeroLevels;
            this.emitState();
        },
        onChangedPercentageAllocatedToSupports() {
            if (this.percentageAllocatedToHeroSkills > 100){
                this.percentageAllocatedToHeroSkills = 100;
            }
            this.percentageAllocatedToHeroLevels = 100 - this.percentageAllocatedToHeroSkills;
            this.emitState();
        },
        onInputPercentageAllocatedToHeroSkills() {
            if (this.percentageAllocatedToHeroLevels > 100) {
                this.percentageAllocatedToHeroLevels = 100;
            }
            this.percentageAllocatedToHeroSkills = 100 - this.percentageAllocatedToHeroLevels;
            this.emitState();
        },
        onInputPercentageAllocatedToSupports() {
            if (this.percentageAllocatedToHeroSkills > 100){
                this.percentageAllocatedToHeroSkills = 100;
            }
            this.percentageAllocatedToHeroLevels = 100 - this.percentageAllocatedToHeroSkills;
            this.emitState();
        },
        emitState(){
            const outPayload = {
                percentageAllocatedToHeroLevels: this.percentageAllocatedToHeroLevels,
                goldAllocatedToHeroLevels: this.goldAllocatedToHeroLevels,

                percentageAllocatedToHeroSkills: this.percentageAllocatedToHeroSkills,
                goldAllocatedToHeroSkills: this.goldAllocatedToHeroSkills
            }

            this.$emit("onUpdateSettingGoldBetweenHeroLevelsAndSkills", outPayload);
        }
    }
}
</script>

<style scoped>

</style>