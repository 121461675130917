<template>
    <div>
        &Sigma;$G Ghost <small>({{ kpiTotalGhost }})</small>
        : <strong>
        {{ kpiCumulatedGoldWonAfterReachingGhostLevel }}</strong> <br/>

        &Sigma;$G Boss <small>({{ kpiTotalBoss }})</small>
        : <strong>
        {{ kpiCumulatedGoldWonAfterReachingBossLevel }}</strong> <br/>

        &Sigma;$G Zone Boss <small>({{ kpiTotalZoneBoss }})</small>
        : <strong>
        {{ kpiCumulatedGoldWonAfterReachingZoneBossLevel }}</strong> <br/>

        &Sigma;$G Enemies <small>({{ kpiTotalEnemies }})</small>
        : <strong>
        {{ kpiCumulatedGoldWonAfterReachingTotalEnemies }}</strong> <br/>

        &Sigma;$G Quest <small>({{ kpiQuestCount }})</small>
        : <strong>
        {{ kpiCumulatedGoldFromQuest }}</strong> <br/>

        &Sigma;$G Total <small>(+{{ kpiPercentageGoldAllBonus }}%)</small>
        : <strong>
        {{ kpiCumulatedGoldWon }}</strong> <br/><br/>

        =========ITEM=========<br/>
        $G Item: <strong>
        {{ kpiGoldItemBonus }}</strong> <br/>
    </div>
</template>

<script>
//import SMPNum from "@/SMPNum";

export default {
    name: "GroupGoldCollect",
    props: ['payload'],
    computed: {
        kpiTotalGhost () {
            const {kpiTotalGhost} = this.payload;
            return kpiTotalGhost;
        },
        kpiCumulatedGoldWonAfterReachingGhostLevel () {
            const {kpiCumulatedGoldWonAfterReachingGhostLevel} = this.payload;
            return kpiCumulatedGoldWonAfterReachingGhostLevel.ToReadableAlphabetV2();
        },
        kpiTotalBoss () {
            const {kpiTotalBoss} = this.payload;
            return kpiTotalBoss;
        },
        kpiCumulatedGoldWonAfterReachingBossLevel () {
            const {kpiCumulatedGoldWonAfterReachingBossLevel} = this.payload;
            return kpiCumulatedGoldWonAfterReachingBossLevel.ToReadableAlphabetV2();
        },
        kpiTotalZoneBoss () {
            const {kpiTotalZoneBoss} = this.payload;
            return kpiTotalZoneBoss;
        },
        kpiCumulatedGoldWonAfterReachingZoneBossLevel () {
            const {kpiCumulatedGoldWonAfterReachingZoneBossLevel} = this.payload;
            return kpiCumulatedGoldWonAfterReachingZoneBossLevel.ToReadableAlphabetV2();
        },
        kpiTotalEnemies () {
            const {kpiTotalEnemies} = this.payload;
            return kpiTotalEnemies;
        },
        kpiCumulatedGoldWonAfterReachingTotalEnemies () {
            const {kpiCumulatedGoldWonAfterReachingTotalEnemies} = this.payload;
            return kpiCumulatedGoldWonAfterReachingTotalEnemies.ToReadableAlphabetV2();
        },
        kpiQuestCount () {
            const {kpiQuestCount} = this.payload;
            return kpiQuestCount;
        },
        kpiCumulatedGoldFromQuest (){
            const {kpiCumulatedGoldFromQuest} = this.payload;
            return kpiCumulatedGoldFromQuest.ToReadableAlphabetV2();
        },
        kpiPercentageGoldAllBonus (){
            const {kpiPercentageGoldAllBonus} = this.payload;
            return kpiPercentageGoldAllBonus;
        },
        kpiCumulatedGoldWon (){
            const {kpiCumulatedGoldWon} = this.payload;
            return kpiCumulatedGoldWon.ToReadableAlphabetV2();
        },
        kpiGoldItemBonus(){
            const {kpiGoldItemBonus} = this.payload;
            return kpiGoldItemBonus.ToReadableAlphabetV2();
        }
    },
}
</script>

<style scoped>

</style>