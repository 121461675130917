import ItemReceivers from "@/item-editor/constants/ItemReceivers";
import {BehaviourByNames} from "@/item-editor/constants/BehaviourTypes";

export default [
     //inc-speed-support
     {
          type : BehaviourByNames.incSpeedSupport,
          receivers : [ItemReceivers.allActiveSupports],
          diKpiLevel: 3,
     },
     {
          type : BehaviourByNames.incSpeedSupport,
          receivers : [ItemReceivers.selectedActiveSupport],
          diKpiLevel: 2,
     },

     //fill_hp
     {
          type : BehaviourByNames.fillHp,
          receivers : [ItemReceivers.selectedHero],
          diKpiLevel: 1,
     },
     {
          type : BehaviourByNames.fillHp,
          receivers : [ItemReceivers.allActiveSupports],
          diKpiLevel: 2,
     },
     {
          type : BehaviourByNames.fillHp,
          receivers : [ItemReceivers.selectedHero, ItemReceivers.allActiveSupports],
          diKpiLevel: 3,

     },
     {
          type : BehaviourByNames.fillHp,
          receivers : [ItemReceivers.selectedSupport],
          diKpiLevel: 1,
     },
     {
          type : BehaviourByNames.fillHp,
          receivers : [ItemReceivers.selectedHero, ItemReceivers.selectedSupport],
          diKpiLevel: 2,
     },
     {
          type : BehaviourByNames.fillHp,
          receivers : [ItemReceivers.allActiveSupports],
          diKpiLevel: 2,
     },
     {
          type : BehaviourByNames.fillHp,
          receivers : [ItemReceivers.allSupports],
          diKpiLevel: 3,
     },

     //inc_dps
     {
          type : BehaviourByNames.incDps,
          receivers : [ItemReceivers.team],
          diKpiLevel: 3,
     },

     //cure_freeze
     {
          type : BehaviourByNames.cureFreeze,
          receivers : [ItemReceivers.selectedHero],
          diKpiLevel: -1,
     },
     {
          type : BehaviourByNames.cureFreeze,
          receivers : [ItemReceivers.team],
          diKpiLevel: 0,
     },
     {
          type : BehaviourByNames.cureFreeze,
          receivers : [ItemReceivers.allActiveSupports],
          diKpiLevel: 0,
     },
     {
          type : BehaviourByNames.cureFreeze,
          receivers : [ItemReceivers.selectedHero, ItemReceivers.selectedActiveSupport],
          diKpiLevel: 1,
     },
     {
          type : BehaviourByNames.cureFreeze,
          receivers : [ItemReceivers.selectedActiveSupport],
          diKpiLevel: 0,
     },

     //cure_poison
     {
          type : BehaviourByNames.curePoison,
          receivers : [ItemReceivers.selectedHero],
          diKpiLevel: -1,
     },
     {
          type : BehaviourByNames.curePoison,
          receivers : [ItemReceivers.team],
          diKpiLevel: 0,
     },
     {
          type : BehaviourByNames.curePoison,
          receivers : [ItemReceivers.allActiveSupports],
          diKpiLevel: 0,
     },
     {
          type : BehaviourByNames.curePoison,
          receivers : [ItemReceivers.selectedHero, ItemReceivers.selectedSupport],
          diKpiLevel: 1,
     },
     {
          type : BehaviourByNames.curePoison,
          receivers : [ItemReceivers.selectedSupport],
          diKpiLevel: -1,
     },

     //cure_sheep
     {
          type : BehaviourByNames.cureSheep,
          receivers : [ItemReceivers.selectedHero],
          diKpiLevel: -1,
     },
     {
          type : BehaviourByNames.cureSheep,
          receivers : [ItemReceivers.team],
          diKpiLevel: 0,
     },
     {
          type : BehaviourByNames.cureSheep,
          receivers : [ItemReceivers.allActiveSupports],
          diKpiLevel: 0,
     },
     {
          type : BehaviourByNames.cureSheep,
          receivers : [ItemReceivers.selectedHero, ItemReceivers.selectedSupport],
          diKpiLevel: 1,
     },
     {
          type : BehaviourByNames.cureSheep,
          receivers : [ItemReceivers.selectedSupport],
          diKpiLevel: -1,
     },

     //cure_fire
     {
          type : BehaviourByNames.cureFire,
          receivers : [ItemReceivers.selectedHero],
          diKpiLevel: -1,
     },
     {
          type : BehaviourByNames.cureFire,
          receivers : [ItemReceivers.team],
          diKpiLevel: 0,
     },
     {
          type : BehaviourByNames.cureFire,
          receivers : [ItemReceivers.allActiveSupports],
          diKpiLevel: 0,
     },
     {
          type : BehaviourByNames.cureFire,
          receivers : [ItemReceivers.selectedHero, ItemReceivers.selectedSupport],
          diKpiLevel: 0,
     },
     {
          type : BehaviourByNames.cureFire,
          receivers : [ItemReceivers.selectedSupport],
          diKpiLevel: -1,
     },

     //damage_enemy
     {
          type : BehaviourByNames.damageEnemy,
          receivers : [ItemReceivers.enemy],
          diKpiLevel: 5,
     },

     //auto_tap
     {
          type : BehaviourByNames.autoTap,
          receivers : [ItemReceivers.selectedHero],
          diKpiLevel: 4,
     },

     //holding_tap
     {
          type : BehaviourByNames.holdingTap,
          receivers : [ItemReceivers.selectedHero],
          diKpiLevel: 4,
     },

     //fill_mana
     {
          type : BehaviourByNames.fillMana,
          receivers : [ItemReceivers.selectedHero],
          diKpiLevel: 0,
     },

     //inc_mana
     {
          type : BehaviourByNames.incMana,
          receivers : [ItemReceivers.selectedHero],
          diKpiLevel: 1,
     },

     //inc_mana_refill_speed
     {
          type : BehaviourByNames.incManaRefillSpeed,
          receivers : [ItemReceivers.selectedHero],
          diKpiLevel: 0,
     },

     //freeze_enemy
     {
          type : BehaviourByNames.freezeEnemy,
          receivers : [ItemReceivers.enemy],
          diKpiLevel: 1,
     },

     //poison-enemy
     {
          type : BehaviourByNames.poisonEnemy,
          receivers : [ItemReceivers.enemy],
          diKpiLevel: 1,
     },


     //sheep-enemy
     {
          type : BehaviourByNames.sheepEnemy,
          receivers : [ItemReceivers.enemy],
          diKpiLevel: 1,
     },


     //inc_bag_slot
     {
          type : BehaviourByNames.incBagSlot,
          receivers : [ItemReceivers.menu],
          diKpiLevel: 5,
     },

     //inc_critical_hit_ratio
     {
          type : BehaviourByNames.incCriticalHitRatio,
          receivers : [ItemReceivers.selectedHero],
          diKpiLevel: -1,
     },

     //inc_gold_enemy_drop
     {
          type : BehaviourByNames.incGoldEnemyDrop,
          receivers : [ItemReceivers.gold],
          diKpiLevel: 2,
     },

     //inc_hp
     {
          type : BehaviourByNames.incHp,
          receivers : [ItemReceivers.selectedHero],
          diKpiLevel: 1,
     },
     {
          type : BehaviourByNames.incHp,
          receivers : [ItemReceivers.allActiveSupports],
          diKpiLevel: 2,
     },
     {
          type : BehaviourByNames.incHp,
          receivers : [ItemReceivers.team],
          diKpiLevel: 3,
     },
     {
          type : BehaviourByNames.incHp,
          receivers : [ItemReceivers.selectedSupport],
          diKpiLevel: 1,
     },
     {
          type : BehaviourByNames.incHp,
          receivers : [ItemReceivers.selectedHero, ItemReceivers.selectedSupport],
          diKpiLevel: 2,
     },

     //inc_pet_active_time
     {
          type : BehaviourByNames.incPetActiveTime,
          receivers : [ItemReceivers.allActivePets],
          diKpiLevel: 5,
     },
     {
          type : BehaviourByNames.incPetActiveTime,
          receivers : [ItemReceivers.selectedPet],
          diKpiLevel: 4,
     },

     //inc_powerup_capacity
     {
          type : BehaviourByNames.incPowerUpCapacity,
          receivers : [ItemReceivers.selectedHero],
          diKpiLevel: 1,
     },

     //inc_size_hero
     {
          type : BehaviourByNames.incHeroSize,
          receivers : [ItemReceivers.selectedHero],
          diKpiLevel: -2,
     },

     //inc-gold-drop
     {
          type : BehaviourByNames.incGoldDrop,
          receivers : [ItemReceivers.gold],
          diKpiLevel: 2,
     },

     //revive_hero
     {
          type : BehaviourByNames.reviveHero,
          receivers : [ItemReceivers.selectedHero],
          diKpiLevel: 5,
     },

     //revive_support
     {
          type : BehaviourByNames.reviveSupport,
          receivers : [ItemReceivers.allActiveSupports],
          diKpiLevel: 5,
     },
     {
          type : BehaviourByNames.reviveSupport,
          receivers : [ItemReceivers.allSupports],
          diKpiLevel: 6,
     },
     {
          type : BehaviourByNames.reviveSupport,
          receivers : [ItemReceivers.selectedSupport],
          diKpiLevel: 4,
     },
]