let SMPNum = require("../../SMPNum");

class SMPItemConfigHelper {
    constructor(gamePlayService) {
        this.gamePlayService = gamePlayService;
    }

    getPercentFromDMG(dmg, currentLevelStage) {
        let hp = this.gamePlayService.getBossHP(currentLevelStage);
        let per = SMPNum.multSmpNum(SMPNum.divSmpNum(dmg, hp), new SMPNum(100));
        return per.ToDoubleIfPossible();
    }

    getImpactReduceDurationToEndLevel(duration, levelStart, levelEnd, currentLevelStage) {
        let range = levelEnd - levelStart;
        let durationEnd = duration * 0.02;//remain only 2%
        let pPerLv = (duration - durationEnd) / range;
        let result = duration - (currentLevelStage - levelStart) * pPerLv;
        if (result < 2) {
            result = 2;
        }
        return result;
    }
}

module.exports = SMPItemConfigHelper;