<template>
    <v-card
            min-width="500"
            min-height="500"
    >
        <v-simple-table>
            <template v-slot:default>
                <thead>
                <tr>
                    <th>
                        <v-avatar>
                            <img
                                    :src="Tools.renderLocalPicture('supports/'+selectedSupportDetail.icon)"
                            ></v-avatar>
                    </th>

                </tr>
                <tr>
                    <th>
                        {{ kpiSupportLabelInfos }}
                    </th>
                </tr>
                <tr/>
                <tr>
                    <th class="text-left">
                        KPI Name
                    </th>
                    <th class="text-left">
                        KPI Value
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr
                >
                    <td>$G available for all support</td>
                    <td>{{totalGoldAvailableForSupport.ToReadableAlphabetV2()}}</td>
                </tr>
                <tr
                >
                    <td>Cumulated $G to bring this support to this level</td>
                    <td>{{kpiSupportSelectedUnlockAndLevelUpCost.ToReadableAlphabetV2()}}</td>
                </tr>
                <tr
                >
                    <td>$G Remain</td>
                    <td>{{kpiSupportSelectedGoldRemainComeThisLevel.ToReadableAlphabetV2()}}</td>
                </tr>
                <tr
                >
                    <td>Possible Level Up</td>
                    <td>{{supportPossibleLevel}}</td>
                </tr>
                <tr
                >
                    <td> Support standard DPS</td>
                    <td> {{ kpiSupportDpsStandard.ToReadableAlphabetV2() }}</td>
                </tr>
                <tr
                >
                    <td> Cost to level up to the next level</td>
                    <td>{{kpiSupportSelectedNextLvCost.ToReadableAlphabetV2()}}</td>
                </tr>

                <tr
                >
                    <td> SUPPORT_TAP_DAMAGE</td>
                    <td> {{ kpiSupportTapDamage.ToReadableAlphabetV2() }}</td>
                </tr>
                </tbody>
            </template>
        </v-simple-table>
        <br/>&nbsp;List of sills unlocked for support at possible skill level <small>({{supportPossibleSkillLevel}})</small><br/>
        <v-simple-table>
            <template>
                <tbody>
                <tr
                >
                    <td> Skill TAP_DAMAGE</td>
                    <td> {{ kpiSupportSkillTapDamage }}</td>
                </tr>

                <tr
                >
                    <td> Skill SUPPORT_DAMAGE</td>
                    <td> {{ kpiSupportSkillSupportDamage }}</td>
                </tr>

                <tr
                >
                    <td> Skill ALL_DAMAGE</td>
                    <td> {{ kpiSupportSkillAllDamage }}</td>
                </tr>

                <tr
                >
                    <td> Skill CAT BONUS</td>
                    <td> {{ kpiSupportCatBonus }}</td>
                </tr>

                <tr
                >
                    <td> Skill CRITICAL CHANCE</td>
                    <td> {{ kpiSupportCriticalChance }}</td>
                </tr>

                <tr
                >
                    <td> Skill MANA BONUS</td>
                    <td> {{ kpiSupportManaBonus }}</td>
                </tr>

                <tr
                >
                    <td> Skill CRITICAL DAMAGE</td>
                    <td> {{ kpiSupportCriticalDamage }}</td>

                </tr>

                <tr
                >
                    <td> Skill GOLD DROP</td>
                    <td> {{ kpisSupportGoldDrop }}</td>

                </tr>


                <tr
                >
                    <td> Skill TAP DAMAGE TOTAL DPS</td>

                    <td> {{ kpiSupportTapDamageTotalDps }}</td>

                </tr>
                </tbody>
            </template>
        </v-simple-table>
    </v-card>
</template>

<script>
import Tools from "@/utils/Tools";
import SMPNum from "@/SMPNum";
import SupportSkillsConstant from "@/constants/SupportSkillsConstants";

export default {
    name: "PanelSupportDetail",
    props: {
        selectedSupportDetail: {type: Object, default: null, required: true},
        payload: {type: Object, default: null, required: true},
        pService: {type:Object, default: null, required: true}
    },
    data () {
      return {
          Tools: Tools,
          gamePlayDataService: null
      }
    },
    computed: {
        supportPossibleLevel() {
            // const {kpiSupportPossibleLevel} = this.payload;
            // return kpiSupportPossibleLevel;
            return this.selectedSupportDetail.possibleLevel;
        },
        supportPossibleSkillLevel() {
            // const {kpiSupportPossibleSkillLevel} = this.payload;
            // return kpiSupportPossibleSkillLevel.ToIntValue();
            return this.selectedSupportDetail.possibleSkillLevel;
        },
        kpiSupportDpsStandard() {
            // const {kpiSupportDpsStandard} = this.payload;
            // return kpiSupportDpsStandard;
            return this.selectedSupportDetail.possibleDps;
        },
        totalGoldAvailableForSupport() {
            const {kpiGoldAvailableForSupport} = this.payload;
            return kpiGoldAvailableForSupport
        },
        kpiSupportLabelInfos() {
            let res = "";
            if (this.selectedSupportDetail !== null) {
                res = this.selectedSupportDetail.name;
            }
            return res;
        },
        kpiSupportSelectedUnlockAndLevelUpCost(){
            let id = this.selectedSupportDetail.supportId;
            let possibleLevel = this.supportPossibleLevel;
            let possibleSkillLevel = this.supportPossibleSkillLevel;
            let cost = this.service.supportKpiUtils.TotalSupportSpentReachToLevelById(id, possibleLevel, possibleSkillLevel);
            return cost;
        },
        kpiSupportSelectedGoldRemainComeThisLevel(){
            let cost = this.kpiSupportSelectedUnlockAndLevelUpCost;
            let remain = SMPNum.minus(this.totalGoldAvailableForSupport, cost);
            return remain;
        },
        kpiSupportSelectedNextLvCost(){
            let lv = this.supportPossibleLevel;
            let cost = this.gamePlayDataService.getCostUpdateSupport(lv, lv+1);
            return cost;
        },
        kpiSupportTapDamage() {
            let possibleLevelUp = this.supportPossibleLevel;
            possibleLevelUp = parseInt(possibleLevelUp);
            let temp = this.gamePlayDataService.DMGStandardSupport(possibleLevelUp);
            return temp;
        },
        kpiSupportTapDamageTotalDps() {
            return this.getSkillValue(SupportSkillsConstant.TAP_DAMAGE_TOTAL_DPS);
        },

        kpisSupportGoldDrop() {
            return this.getSkillValue(SupportSkillsConstant.GOLD_DROP);
        },

        kpiSupportCriticalDamage() {
            return this.getSkillValue(SupportSkillsConstant.CRITICAL_DMG);
        },

        kpiSupportManaBonus() {
            return this.getSkillValue(SupportSkillsConstant.MANA_BONUS);
        },

        kpiSupportCriticalChance() {
            return this.getSkillValue(SupportSkillsConstant.CRITICAL_CHANCE);
        },

        kpiSupportCatBonus() {
            return this.getSkillValue(SupportSkillsConstant.CAT_BONUS);
        },

        kpiSupportSkillAllDamage() {
            return this.getSkillValue(SupportSkillsConstant.ALL_DAMAGE);
        },

        kpiSupportSkillSupportDamage() {
            return this.getSkillValue(SupportSkillsConstant.SUPPORT_DAMAGE);
        },


        kpiSupportSkillTapDamage() {
            return this.getSkillValue(SupportSkillsConstant.TAP_DAMAGE);
        },
    },
    methods: {
      getSkillValue(skillType){
          const {kpiSupportList} = this.payload;
          const supportId = this.selectedSupportDetail.supportId;
          const skillValue = this.service.defineSupportSelectedBonusSkill(skillType, supportId, kpiSupportList);
          return skillValue;
      }
    },
    created() {
        this.gamePlayDataService = this.$store.state.gamePlayDataService;
        this.service = this.pService;
    },
}
</script>

<style scoped>

</style>