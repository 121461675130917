import axios from "axios";
import NetworkUtils from "@/NetworkUtils";
import store from "../../store";
const authorize =  `Bearer ${store.getters.getToken}`;
const config = {
    headers: {
        'Content-Type': 'application/json',
        Authorization: authorize
    },
};

export default {
    async pullConfig() {
        let data = await axios.get(NetworkUtils.HOST + "/kpi-config", config);
        let configRS = undefined;
        if (data.data.length > 0) {
            configRS = data.data[0].config;
        }
        return configRS;
    },

    async pushConfig(configDataPush) {
        let response = await axios.get(NetworkUtils.HOST + "/kpi-config", config);

        if (response.data && response.data.length > 0) {
           return await axios.put(NetworkUtils.HOST + "/kpi-config/" + response.data[0]._id, {config: configDataPush}, config);
        } else {
           return await axios.post(NetworkUtils.HOST + "/kpi-config",{config:configDataPush}, config)
        }
    },
}