import SupportSkillsConstant from "@/constants/SupportSkillsConstants";
import PetSkillConstants from "@/constants/PetSkillConstants";
import SMPNum from "@/SMPNum";
import AchievementSimulationService from "@/achievement-simulation/services/AchievementSimulationService";
import lodash from "lodash";
import {MAX_LEVEL_ON_STAGE, MAX_ZONE, MAX_FLYING_SUPPORT} from "@/quest-editor/constants/GameConstant";
import HeroSkillType from "../constants/HeroSkillType";

class LogicSimulationService {
     constructor(balanceKpiUtils, gamePlayDataService, supportKpiUtils, petKpiUtils, heroSkillKpiUtils, balanceBuilder, enemyKpiUtils, charactersKpiUtils, multipleSessionKpiUtils) {
          this.balanceKpiUtils = balanceKpiUtils;
          this.gamePlayDataService = gamePlayDataService;
          this.supportKpiUtils = supportKpiUtils;
          this.petKpiUtils = petKpiUtils;
          this.heroSkillKpiUtils = heroSkillKpiUtils;
          this.balanceBuilder = balanceBuilder;
          this.enemyKpiUtils = enemyKpiUtils;
          this.charactersKpiUtils = charactersKpiUtils;
          this.multipleSessionKpiUtils = multipleSessionKpiUtils;
     }
     kpiPetGamePlayStandardDMG(kpiGameplayPlayerTapDamage, petPossibleLevel, heroSkillPayload) {
          const {percentControlHeroDmgSkill} = heroSkillPayload;
          return this.petKpiUtils.getPetStandardDMG(kpiGameplayPlayerTapDamage, petPossibleLevel, percentControlHeroDmgSkill);
     }
     kpiPetTotalPassiveDMG(heroDmg, petList) {
          return this.petKpiUtils.getTotalPetPassiveDMG(heroDmg, petList);
     }
     kpiPetDps(heroDmg, petList, hitPerSecond, heroSkillPayload) {
          const {percentControlHeroDmgSkill} = heroSkillPayload;
          return this.petKpiUtils.getPossibleDPS(heroDmg, petList, hitPerSecond, percentControlHeroDmgSkill);
     }
     kpiSupportActiveCounter(supportCount) {
          let activeSupport = 0;
          if (supportCount >= 4) {
               activeSupport = 4;
          } else {
               activeSupport = supportCount;
          }
          return activeSupport;
     }
     computeSupportDpsStandard(supportPayload, petPayload, heroSkillPayload, unlockFlyingSupportCounter) {
          const {supportList, isUseSupportSkillDmg, itemImpactBonus, teamBattleInfo} = supportPayload;
          const {petList, isUsePetSkillDmg} = petPayload;

          let allDmgPercent = 0;
          let petSupportDmgPercent = 0;
          let petAllDmgPercent = 0;

          if (isUseSupportSkillDmg) {
               allDmgPercent = this.defineSupportBonusSkill(SupportSkillsConstant.ALL_DAMAGE, supportList);
          }

          if (isUsePetSkillDmg) {
               petSupportDmgPercent = this.getPetBonusSkill(PetSkillConstants.ALL_SUPPORTER_DAMAGE, petList, heroSkillPayload);
               petAllDmgPercent = this.getPetBonusSkill(PetSkillConstants.ALL_DAMAGE, petList, heroSkillPayload);
          }

          let totalPercent = Number(allDmgPercent) + Number(petSupportDmgPercent) + Number(petAllDmgPercent);

          if(itemImpactBonus){
               totalPercent += Number(itemImpactBonus.supportSpeedPercentBonus);
          }

          const {isUseHeroSkillDmg, percentControlHeroDmgSkill, heroActiveSkillList} = heroSkillPayload;
          let controlPercentHeroDmg = percentControlHeroDmgSkill / 100;
          if(isUseHeroSkillDmg){
               let heroSkillDmgPercent = 0;
               if(heroActiveSkillList){
                    heroActiveSkillList.forEach(skDis => {
                         if(skDis.skill.m_iID === HeroSkillType.FAST_AND_FURIOUS){
                              heroSkillDmgPercent += controlPercentHeroDmg * skDis.bonus;
                              //console.log('bonus FAST_AND_FURIOUS ',(controlPercent * skDis.bonus));
                         }
                    });
               }
               totalPercent += heroSkillDmgPercent;

               // if(heroBossSkillList){
               //      heroBossSkillList.forEach(skDis => {
               //           if(skDis.skill.m_iID === HeroSkillType.FLYING_SUPPORT){
               //                unlockFlyingSupportCounter = Math.min(skDis.levelPossible, MAX_FLYING_SUPPORT);
               //                //console.log('bonus FLYING_SUPPORT ',unlockFlyingSupportCounter);
               //           }
               //      });
               // }
          }

          //define possible support dps for all unlock support
          //let supportHasUnlockCount = supportCount;
          supportList.forEach(supports => {
               supports.forEach(support =>{
                    if(support.isUnlock && support.possibleLevel >= 1){
                         let kpiSupportTapDamage = this.gamePlayDataService.DMGStandardSupport(support.possibleLevel);

                         //include support skill bonus of his own unlock
                         let bonusFromOwnSkill = 0;
                         if(isUseSupportSkillDmg){
                              bonusFromOwnSkill = this.defineSupportSelectedBonusSkill(SupportSkillsConstant.SUPPORT_DAMAGE, support.m_iID, supportList);
                         }

                         let totalHisOwnPercentBonus = (Number(bonusFromOwnSkill) + totalPercent)/100;
                         let dmgPerSupportBonus = SMPNum.multSmpNum(kpiSupportTapDamage, new SMPNum(totalHisOwnPercentBonus));
                         let dmgPerSupport = SMPNum.plus(kpiSupportTapDamage, dmgPerSupportBonus);
                         support.possibleDps = dmgPerSupport;
                    }
               });
          });

          //dps only supporter on ground not include flying support
          let totalDmgSupport = new SMPNum(0);
          if(teamBattleInfo.supportsData && teamBattleInfo.supportsData.length > 0){
               supportList.forEach(supports => {
                    supports.forEach(support =>{
                         if(teamBattleInfo.supportsData.find(s => s.m_iID === support.m_iID && s.m_SupportStandType !== 2)){
                              if(support.possibleDps){
                                   totalDmgSupport = SMPNum.plus(totalDmgSupport, support.possibleDps);
                              }
                         } else if(isUseHeroSkillDmg && unlockFlyingSupportCounter > 0){
                              if(teamBattleInfo.supportsData.find(s => s.m_iID === support.m_iID && s.m_SupportStandType === 2)){
                                   if(support.possibleDps){
                                        let flyDps = SMPNum.multSmpNum(support.possibleDps, new SMPNum(controlPercentHeroDmg))
                                        totalDmgSupport = SMPNum.plus(totalDmgSupport, flyDps);
                                        unlockFlyingSupportCounter--;
                                        //console.log('plus flying support dps: '+flyDps.ToReadableAlphabetV2());
                                   }
                              }
                         }
                    });
               });
          }

          return totalDmgSupport;
     }

     computeAllSupportDps(supportList) {
          let totalDmgSupport = new SMPNum(0);
          supportList.forEach(supports => {
               supports.forEach(support =>{
                    if(support.possibleDps){
                         totalDmgSupport = SMPNum.plus(totalDmgSupport, support.possibleDps);
                    }
               });
          });
          return totalDmgSupport;
     }

     getMaximumSupportLevelWithDefaultUnlockLevel(firstPossibleLevel, supportList){
          let maxLevel = firstPossibleLevel;
          supportList.forEach(supports => {
               supports.forEach(support => {
                    if(support.isUnlock){
                         if(maxLevel < support.levelUnlock){
                              maxLevel = support.levelUnlock;
                         }
                    }
               });
          });
          return maxLevel;
     }

     computeMaximumSupportLevelAchievable(firstPossibleLevel, availableGold, supportList) {
          let found = false;

          let target = Math.round(firstPossibleLevel / 2);
          if(target<1){
               target = 1;
          }
          let previousTarget = firstPossibleLevel;
          let securityLoop = 1;
          let levelToReturn = -1;
          do {
               let remaining = this.computeSupportLevelRemainingMoney(target, availableGold, supportList);

               let goLeft = remaining.isZero;
               if (!goLeft) {
                    levelToReturn = target;
               }
               let temp = target;
               if (goLeft) {
                    target = target - Math.floor((Math.abs(target - previousTarget)) / 2);
               } else {
                    target = target + Math.floor((Math.abs(previousTarget - target)) / 2);
               }
               previousTarget = temp;
               if (previousTarget === target) {
                    found = true;
               }
               securityLoop++;
          } while (!found && securityLoop < 100);
          return levelToReturn;
     }

     computeSupportLevelRemainingMoney(supportLevel, goldAvailable, supportList) {
          if(supportLevel >= 1){
               let willSpentOnSupportLevel = this.gamePlayDataService.mathGamePlay.ComputeSumGeometricForSeries('CostSupport', supportLevel);

               //add gold from no level cost on unlock each support level
               let supportSpentCount = 0;
               let freeGoldForFirstUnk = new SMPNum(0);
               supportList.forEach(supports => {
                    supports.forEach(support => {
                         if(support.isUnlock){
                              let maxFreeLevel = supportLevel < support.levelUnlock ? supportLevel : support.levelUnlock;
                              let costFirstLv = this.gamePlayDataService.mathGamePlay.ComputeSumGeometricForSeries('CostSupport', maxFreeLevel);
                              freeGoldForFirstUnk = SMPNum.plus(freeGoldForFirstUnk, costFirstLv);
                              supportSpentCount++;
                         }
                    });
               });

               //console.log('Target level: '+supportLevel+' support count '+supportSpentCount);
               //console.log(' gold av: '+goldAvailable.ToReadableAlphabetV2());

               //total spent for possible Support unlock
               willSpentOnSupportLevel = SMPNum.multSmpNum(willSpentOnSupportLevel, new SMPNum(supportSpentCount));

               //not include free level from support unlock
               if(SMPNum.greaterThan(willSpentOnSupportLevel, freeGoldForFirstUnk)){
                    willSpentOnSupportLevel = SMPNum.minus(willSpentOnSupportLevel, freeGoldForFirstUnk);
               } else {
                    willSpentOnSupportLevel = new SMPNum(0);
               }

               //console.log(' support spent: '+willSpentOnSupportLevel.ToReadableAlphabetV2());

               if (SMPNum.greaterThan(goldAvailable, willSpentOnSupportLevel)) {
                    let temp = SMPNum.minus(goldAvailable, willSpentOnSupportLevel);
                    return temp;
               } else {
                    return new SMPNum(0);
               }
          }
          return new SMPNum(0);
     }

     kpiPetCount(gameLevel){
          return this.gamePlayDataService.getPetCountUnlockOnLevel(gameLevel);
     }
     kpiPetLevel(){
          return this.petKpiUtils.getCurrentPetActivePossibleLevel();
     }
     definePetLevel(totalDiamondForPet, petList){
          this.petKpiUtils.definePetLevel(totalDiamondForPet, petList);
     }
     getCurrentPetActiveSkillBonus(heroSkillPayload){
          const {percentControlHeroDmgSkill} = heroSkillPayload;
          return this.petKpiUtils.getCurrentPetActiveSkillBonus(percentControlHeroDmgSkill);
     }
     getPetBonusSkill(skillType, petList, heroSkillPayload) {
          let activeBonus = 0;
          if(this.petKpiUtils.getCurrentPetActiveSkillType() === skillType){
               activeBonus = this.petKpiUtils.getCurrentPetActiveSkillBonusValue();
          }
          activeBonus += this.getPetActiveSkillBonusFromJoinPet(skillType, heroSkillPayload);
          let passive = this.petKpiUtils.getPetTotalPassiveSkillBonus(skillType, petList);
          return Number(activeBonus + passive).toFixed(2);

          /*old rule with average bonus
          let avg = this.petKpiUtils.getPetAvgActiveSkillBonus(skillType, petLevel, petCount);
          let passive = this.petKpiUtils.getPetTotalPassiveSkillBonus(skillType, petLevel, petCount);
          return Number(avg + passive).toFixed(2);*/
     }
     getPetPossibleBonusSkill(skillType, petList) {
          let avg = this.petKpiUtils.getPetAvgActiveSkillBonus(skillType, petList);
          let passive = this.petKpiUtils.getPetTotalPassiveSkillBonus(skillType, petList);
          return Number(avg + passive).toFixed(2);
     }
     getPetAvgActiveBonusSkill(skillType, petList){
          let avg = this.petKpiUtils.getPetAvgActiveSkillBonus(skillType, petList);
          return Number(avg).toFixed(2);
     }
     getPetPassiveBonusSkill(skillType, petList){
          let passive = this.petKpiUtils.getPetTotalPassiveSkillBonus(skillType, petList);
          return Number(passive).toFixed(2);
     }
     getPetActiveSkillBonusFromJoinPet(skillType, heroSkillPayload){
          const {isUseHeroSkillDmg, percentControlHeroDmgSkill} = heroSkillPayload;
          let heroSkillGoldPercent = 0;
          if(isUseHeroSkillDmg){
               let skillControl = Number(percentControlHeroDmgSkill) / 100;
               heroSkillGoldPercent += skillControl * this.petKpiUtils.getPetSkillBonusFromJoinPet(skillType);
               //console.log('bonus ANGER_OF_PET '+(skillControl * this.petKpiUtils.getPetSkillBonusFromJoinPet(skillType)));
          }
          return heroSkillGoldPercent;
     }
     getHeroSkillGoldBonus(heroSkillPayload){
          const {isUseHeroSkillGold, heroActiveSkillList} = heroSkillPayload;
          let heroSkillGoldPercent = 0;
          if(isUseHeroSkillGold){
               if(heroActiveSkillList){
                    heroActiveSkillList.forEach(skDis => {
                         if(skDis.skill.m_iID === HeroSkillType.GOLD_FINGER){
                              heroSkillGoldPercent += skDis.bonus;
                              //console.log('bonus GOLD_FINGER '+skDis.bonus);
                         }
                    });

               }
          }
          return heroSkillGoldPercent;
     }
     getPercentageGoldBonus(payload) {
          const {percentageGoldBonus,
               percentageGoldFromSupport,
               percentageGoldFromPet,
               percentControlSupportGoldSkill,
               percentControlPetGoldSkill,
               isUseSupportSkillGold,
               isUsePetSkillGold,
               heroGoldSkillBonus,
               percentControlHeroGoldSkill} = payload;

          let percent = percentageGoldBonus;
          if (isUseSupportSkillGold) {
               percent += (percentControlSupportGoldSkill * percentageGoldFromSupport)/100;
          }
          if (isUsePetSkillGold){
               percent += (percentControlPetGoldSkill * percentageGoldFromPet)/100;
          }
          if(heroGoldSkillBonus > 0){
               percent += (Number(percentControlHeroGoldSkill) * heroGoldSkillBonus)/100;
          }
          return percent;
     }
     getTotalGoldOnLevelReach(totalGoldEnemies, totalGoldQuest, percentageGoldBonus){
          let total = SMPNum.plus(totalGoldEnemies, totalGoldQuest);

          if(percentageGoldBonus > 0){
               let bonus = SMPNum.multSmpNum(total, SMPNum.fromNum(percentageGoldBonus/100));
               total = SMPNum.plus(total, bonus)
          }

          //test
          //total = new SMPNum(500 * Math.pow(10, 49));

          return total;
     }
     getFlyingSupportPossibleUnlock(heroBossSkillList){
          let unlockFlyingSupportCounter = 0;
          heroBossSkillList.forEach(skDis => {
               if(skDis.skill.m_iID === HeroSkillType.FLYING_SUPPORT){
                    unlockFlyingSupportCounter = Math.min(skDis.levelPossible, MAX_FLYING_SUPPORT);
                    //console.log('unlock FLYING_SUPPORT ',unlockFlyingSupportCounter);
               }
          });
          return unlockFlyingSupportCounter;
     }
     definePossibleUnlockSupportCounter(availableGold, gameLevel, unlockFlyingSupportCounter) {
          return this.supportKpiUtils.GetNumberOfSupportUnlockableAndGoldRemain(availableGold, gameLevel, unlockFlyingSupportCounter);
     }
     defineSupportBonusSkill(skillType, supportList) {
          let skillPercent = this.supportKpiUtils.computeSkillBonus(skillType, supportList);
          return skillPercent;
     }

     defineGoldAvailableForHeroes(totalGoldAvailable, ratioAllocatedHeroes){
          if (!totalGoldAvailable.isZero && ratioAllocatedHeroes > 0) {
               let result = SMPNum.multSmpNum(new SMPNum(ratioAllocatedHeroes), totalGoldAvailable);
               result = SMPNum.divSmpNum(result, new SMPNum(100));
               return result;
          } else {
               return SMPNum.fromNum(0);
          }
     }
     defineGoldAvailableForHeroSkill(totalGoldAvailableForHeroes, ratioAllocatedToHeroSkill){
          if (!totalGoldAvailableForHeroes.isZero && ratioAllocatedToHeroSkill > 0) {
               let result = SMPNum.multSmpNum(new SMPNum(ratioAllocatedToHeroSkill), totalGoldAvailableForHeroes);
               result = SMPNum.divSmpNum(result, new SMPNum(100));
               return result;
          } else {
               return SMPNum.fromNum(0);
          }
     }
     defineLevelForHeroSkillPossibleReach(heroList, heroSelectID, gameLevel, goldAvailable) {
          let heroSkillsUnlockList = [];
          let totalGoldHeroSkillHaveSpent = SMPNum.fromNum(0);

          //unlock and update selected hero first
          let mainHero = heroList.find(hero => hero.m_iID === heroSelectID);
          if(mainHero.heroIsUnlock){
               let heroLevel = mainHero.possibleLevel;
               let skillUnlocksAndSpent = this.heroSkillKpiUtils.getHeroSkillUnlockedAndSpent(heroLevel, gameLevel, goldAvailable);

               if(skillUnlocksAndSpent.skillDisplays.length > 0){
                    //define possible level
                    if(SMPNum.greaterThan(skillUnlocksAndSpent.totalSpent, goldAvailable)){
                         console.log("Invalid on available gold and unlock hero skill");
                    } else {
                         totalGoldHeroSkillHaveSpent = SMPNum.plus(totalGoldHeroSkillHaveSpent, skillUnlocksAndSpent.totalSpent);
                         goldAvailable = SMPNum.minus(goldAvailable, skillUnlocksAndSpent.totalSpent);
                         let goldSkillSpent = this.heroSkillKpiUtils.definePossibleHeroSkillLevel(skillUnlocksAndSpent.skillDisplays, gameLevel, goldAvailable);
                         totalGoldHeroSkillHaveSpent = SMPNum.plus(totalGoldHeroSkillHaveSpent, goldSkillSpent);
                    }

                    skillUnlocksAndSpent.skillDisplays.forEach(sk => {
                         heroSkillsUnlockList.push(sk);
                    });
               }
          }

          //other hero skill
          heroList.forEach(hero => {
               if(hero.m_iID !== heroSelectID && hero.heroIsUnlock){
                    let heroLevel = hero.possibleLevel;
                    let skillUnlocksAndSpent = this.heroSkillKpiUtils.getHeroSkillUnlockedAndSpent(heroLevel, gameLevel, goldAvailable);

                    if(skillUnlocksAndSpent.skillDisplays.length > 0){
                         //define possible level
                         if(SMPNum.greaterThan(skillUnlocksAndSpent.totalSpent, goldAvailable)){
                              console.log("Invalid on available gold and unlock hero skill");
                         } else {
                              totalGoldHeroSkillHaveSpent = SMPNum.plus(totalGoldHeroSkillHaveSpent, skillUnlocksAndSpent.totalSpent);
                              goldAvailable = SMPNum.minus(goldAvailable, skillUnlocksAndSpent.totalSpent);
                              let goldSkillSpent = this.heroSkillKpiUtils.definePossibleHeroSkillLevel(skillUnlocksAndSpent.skillDisplays, gameLevel, goldAvailable);
                              totalGoldHeroSkillHaveSpent = SMPNum.plus(totalGoldHeroSkillHaveSpent, goldSkillSpent);
                         }
                    }
               }
          });

          return {
               heroSkillsUnlockList,
               totalGoldHeroSkillHaveSpent
          }
     }
     defineGoldEnding(totalGoldRemainFromHeroSpent, totalGoldSupportHaveSpent, totalGoldHeroSkillHaveSpent){

          let goldEnding = SMPNum.fromNum(0);
          let spent = SMPNum.plus(totalGoldSupportHaveSpent, totalGoldHeroSkillHaveSpent);
          if(SMPNum.greaterThan(totalGoldRemainFromHeroSpent, spent)){
               let end = SMPNum.minus(totalGoldRemainFromHeroSpent, spent);
               goldEnding.setFromSMPNum(end);
          }

          return goldEnding;
     }
     defineLevelForSupportPossibleReach(goldAvailableForSupportLevel, goldAvailableForSupportSkill, supportList, teamSize) {

          const haveSupports = teamSize > 0;

          if (haveSupports) {
               let levelPossible = this.supportKpiUtils.innerFunctionBuildPossibleLevelUpForSupport(goldAvailableForSupportLevel, supportList);

               //get max level between free unlock level and gold level
               //levelPossible = this.getMaximumSupportLevelWithDefaultUnlockLevel(levelPossible, supportList);

               //apply possible level like hero
               levelPossible = this.computeMaximumSupportLevelAchievable(levelPossible, goldAvailableForSupportLevel, supportList);

               //update possible level
               supportList.forEach(supports => {
                    supports.forEach(support => {
                         if(support.isUnlock){
                              if(levelPossible >= support.levelUnlock){
                                   support.possibleLevel = levelPossible;
                              } else{
                                   support.possibleLevel = support.levelUnlock;
                              }
                         }
                    });
               });

               //this method will define support possibleSkillLevel
               let resultSkillPossible = this.supportKpiUtils.innerFunctionBuildPossibleUnlockSkillForSupport(goldAvailableForSupportSkill, supportList);

               let supportUnlockAndLvSpent = this.supportKpiUtils.TotalSupportSpentReachToLevel(
                    supportList
               );
               return {
                    levelPossible: SMPNum.fromNum(levelPossible),
                    //skillPossibleLevel: SMPNum.fromNum(resultSkillPossible.skillPossibleLevel),
                    totalSkillSpent: resultSkillPossible.totalSkillSpent,
                    supportUnlockAndLvSpent: supportUnlockAndLvSpent,

               }
          } else {
               return {
                    levelPossible: SMPNum.fromNum(0),
                    //skillPossibleLevel: SMPNum.fromNum(0),
                    totalSkillSpent: SMPNum.fromNum(0),
                    supportUnlockAndLvSpent: SMPNum.fromNum(0),
               }
          }
     }

     defineLevelForOneSupportPossibleReach(goldAvailableForSupportLevel, currentLevel) {
          let levelPossible = this.supportKpiUtils.innerFunctionBuildPossibleLevelUpForOneSupport(goldAvailableForSupportLevel, currentLevel);
          let supportLevelSpent = this.supportKpiUtils.TotalOneSupportSpentOnLevelOnly(currentLevel,levelPossible);
          return {
               levelPossible: levelPossible,
               supportLevelSpent: supportLevelSpent,
          }
     }

     kpiGameplayPlayerTapDamage(supportPayload, petPayload, heroLevel, heroDmgSessionGainBoss, heroDmgSessionFromPet, heroSkillPayload) {
          const {supportList,supportDps, isUseSupportSkillDmg} = supportPayload;
          const {petList, isUsePetSkillDmg} = petPayload;

          const {isUseHeroSkillDmg, percentControlHeroDmgSkill, heroActiveSkillList, isUseThunderAttackSkill} = heroSkillPayload;

          let tapDamagePercent = 0;
          let allDmgPercent = 0;
          let petTapDmgPercent = 0;
          let petAllDmgPercent = 0;
          let dmgBonusFromSupportDps = SMPNum.fromNum(0);
          if (isUseSupportSkillDmg) {
               tapDamagePercent = this.defineSupportBonusSkill(SupportSkillsConstant.TAP_DAMAGE, supportList);
               allDmgPercent = this.defineSupportBonusSkill(SupportSkillsConstant.ALL_DAMAGE, supportList);
          }
          if (isUsePetSkillDmg) {
               petTapDmgPercent = this.getPetBonusSkill(PetSkillConstants.TAP_DAMAGE, petList, heroSkillPayload);
               petAllDmgPercent = this.getPetBonusSkill(PetSkillConstants.ALL_DAMAGE, petList, heroSkillPayload);
          }

          let heroSkillDmgPercent = 0;
          if(isUseHeroSkillDmg){
               let bonusHeroSkill = 0;
               let skillControl = Number(percentControlHeroDmgSkill) / 100;
               if(heroActiveSkillList){
                    heroActiveSkillList.forEach(skDis => {
                         if(isUseThunderAttackSkill && skDis.skill.m_iID === HeroSkillType.THUNDER_ATTACK){
                              bonusHeroSkill += skillControl * skDis.bonus;
                              //console.log('bonus thunder '+skDis.bonus);
                         } else if(skDis.skill.m_iID === HeroSkillType.TWIN_SHADOW){
                              bonusHeroSkill += skillControl;//equal to two heroes dmg
                              //console.log('bonus twin '+skillControl);
                         } else if(skDis.skill.m_iID === HeroSkillType.ANGER_OF_GOD){
                              bonusHeroSkill += skillControl * skDis.bonus / 100;
                              //console.log('bonus ANGER_OF_GOD '+(skillControl * skDis.bonus / 100));
                         } else if(skDis.skill.m_iID === HeroSkillType.THE_TEAMMATE){
                              bonusHeroSkill += skillControl;//equal to two heroes dmg
                              //console.log('bonus THE_TEAMMATE '+(skillControl));
                         }
                    });

               }
               heroSkillDmgPercent+=bonusHeroSkill;
          }
          //console.log('skill from hero bonus: '+heroSkillDmgPercent);

          if (isUseSupportSkillDmg) {
               if (!supportDps.isZero) {
                    let percentTapDMGFromSupportDPS = this.defineSupportBonusSkill(SupportSkillsConstant.TAP_DAMAGE_TOTAL_DPS, supportList);
                    if (percentTapDMGFromSupportDPS > 0) {
                         dmgBonusFromSupportDps = SMPNum.multSmpNum(supportDps, SMPNum.fromNum(percentTapDMGFromSupportDPS / 100));
                    }
               }
          }

          let params = {
               heroLevel: heroLevel,
               tapDamagePercent: tapDamagePercent,
               allDmgPercent: allDmgPercent,
               petTapDmgPercent: petTapDmgPercent,
               petAllDmgPercent: petAllDmgPercent,
               dmgBonusFromSupportDps: dmgBonusFromSupportDps,
               heroDmgSessionGainBoss: heroDmgSessionGainBoss,
               heroDmgSessionFromPet: heroDmgSessionFromPet,
               heroSkillDmgPercent: heroSkillDmgPercent,
          }
          let finalResult = this.gamePlayDataService.kpiGameplayPlayerTapDamage(params);

          return finalResult;
     }
     computeHitPerGhosts(gameLevel, heroLevel) {
          if (this.balanceKpiUtils) {
              return this.balanceKpiUtils.getTotalHitsRequiredToKillAGhostAtLevel(gameLevel, heroLevel)
          } else {
              return 0;
          }
      }
     computeHeroDps(heroDmg, hitPerSecond) {
          return SMPNum.multSmpNum(heroDmg, new SMPNum(hitPerSecond));
     }
     computeHeroDpsBossMode(payLoad){
          const {
               gameLevel,
               currentHeroLevel,
               goldAvailableForSupportUnlock,
               goldAvailableForSupportLevel,
               goldAvailableForSupportSkill,
               diamondAvailableForPet,
               hitBossPerSecond,
               isUseSupportSkillDmg,
               isUsePetSkillDmg,
               isUsingPet,
               supportList,
               itemImpactBonus,
               heroDmgSessionGainBoss,
               heroDmgSessionFromPet,
               teamBattleInfo,
               petList,
               heroSkillPayload,
               unlockFlyingSupportCounter
          } = payLoad;

          return this.computeHeroDpsWithItemImpactBonus(gameLevel, currentHeroLevel, goldAvailableForSupportUnlock, goldAvailableForSupportLevel
                                                      , goldAvailableForSupportSkill, diamondAvailableForPet, hitBossPerSecond, isUseSupportSkillDmg
                                                      , isUsePetSkillDmg, isUsingPet, supportList, itemImpactBonus
                                                       , heroDmgSessionGainBoss, heroDmgSessionFromPet, teamBattleInfo, petList, heroSkillPayload, unlockFlyingSupportCounter);
     }
     computeHeroDpsWithItemImpactBonus(gameLevel, currentHeroLevel, goldAvailableForSupportUnlock, goldAvailableForSupportLevel
                                       , goldAvailableForSupportSkill, diamondAvailableForPet, hitBossPerSecond, isUseSupportSkillDmg
                                       , isUsePetSkillDmg, isUsingPet, supportList, itemImpactBonus
                                       , heroDmgSessionGainBoss, heroDmgSessionFromPet, teamBattleInfo, petList, heroSkillPayload, unlockFlyingSupportCounter){
          const {
               totalUnlockSupport: supportCount,
          } = this.definePossibleUnlockSupportCounter(goldAvailableForSupportUnlock, gameLevel);

          const {
               levelPossible: supportLevel,
               skillPossibleLevel: supportSkillLevel
          } = this.defineLevelForSupportPossibleReach(goldAvailableForSupportLevel, goldAvailableForSupportSkill, supportCount);

          // let petCount = this.kpiPetCount(gameLevel);
          // let petLevel = this.kpiPetLevel(diamondAvailableForPet, petCount);

          const supportPayload1 = {supportList, supportCount, supportLevel, supportSkillLevel, isUseSupportSkillDmg, itemImpactBonus, teamBattleInfo};
          const petPayload = {petList, isUsePetSkillDmg};

          let supportDps = this.computeSupportDpsStandard(supportPayload1, petPayload, heroSkillPayload, unlockFlyingSupportCounter);

          const supportPayload2 = {supportList, supportDps, isUseSupportSkillDmg}
          let heroDmg = this.kpiGameplayPlayerTapDamage(supportPayload2, petPayload, currentHeroLevel, heroDmgSessionGainBoss, heroDmgSessionFromPet, heroSkillPayload);

          let heroDps = this.computeHeroDps(heroDmg, hitBossPerSecond);

          let dps = heroDps;
          if(isUsingPet){
               let petAvgDps = this.kpiPetDps(heroDmg, petList, hitBossPerSecond, heroSkillPayload);
               dps = SMPNum.plus(dps, petAvgDps);
          }

          if(itemImpactBonus){
               //add item impact dmg
               //because of dmgImpactBonusBossOnly is a one shot per hit
               //his dps = dmg/1 => dps = dmg
               dps = SMPNum.plus(dps, itemImpactBonus.dmgImpactBonusBossOnly);

               //add dps item bonus
               dps = SMPNum.plus(dps, this.getTotalDpsItemBonusBossMode(itemImpactBonus, dps));
          }

          let activeSupport = this.kpiSupportActiveCounter(supportCount);

          if (activeSupport > 0) {
               let dpsOfOneSupport = SMPNum.divSmpNum(supportDps, new SMPNum(activeSupport));
               if (SMPNum.greaterThan(dpsOfOneSupport, dps)) {
                    dps = dpsOfOneSupport;
               }
          }

          return dps;
     }

     computeTotalHeroSpent(heroList){
          let totalSpent = new SMPNum(0);
          heroList.forEach(hero => {
               if(hero.possibleLevel > 1){
                    let heroLvSpent = this.gamePlayDataService.mathGamePlay.ComputeSumGeometricForSeries('CostHero', hero.possibleLevel);
                    let costFirstLv = this.gamePlayDataService.mathGamePlay.ComputeSumGeometricForSeries('CostHero', hero.levelUnlock);
                    if(SMPNum.greaterThan(heroLvSpent, costFirstLv)){
                         heroLvSpent = SMPNum.minus(heroLvSpent, costFirstLv);
                    } else {
                         heroLvSpent = new SMPNum(0);
                    }
                    totalSpent = SMPNum.plus(totalSpent, heroLvSpent)
               }
          });
          return totalSpent;
     }
     computeGoldRemainFromHeroSpent(totalGoldWon, totalGoldHeroSpent) {
          if(SMPNum.greaterThan(totalGoldWon, totalGoldHeroSpent)){
               let remain = SMPNum.minus(totalGoldWon, totalGoldHeroSpent);
               return remain;
          } else {
               return SMPNum.fromNum(0);
          }
     }
     kpiTTKG(gameLevel, dps){
          return this.balanceKpiUtils.getTimeToKillGhostByDPSSMPNum(gameLevel, dps);
     }
     kpiTTKB(gameLevel, dps){
          const seconds = this.balanceKpiUtils.getRealTimeToKillBossByHeroDPS(gameLevel, dps);
          return seconds;
     }
     kpiTTKZB(gameLevel, dps){
          const seconds = this.balanceKpiUtils.getRealTimeToKillZoneBossByHeroDPS(gameLevel, dps);
          return seconds;
     }
     kpiTTKBReadable(gameLevel, dps){
          const seconds = this.balanceKpiUtils.getRealTimeToKillBossByHeroDPS(gameLevel, dps);
          return SMPNum.ToReadableTimeValue(seconds);
     }
     getNextLevelHeroCanUp(heroLevelSelected, heroList){
          let levelMinLevel = heroLevelSelected;
          heroList.forEach(hero => {
               if(hero.heroIsUnlock && levelMinLevel > hero.possibleLevel){
                    levelMinLevel = hero.possibleLevel;
               }
          });
          return levelMinLevel + 1;
     }
     getHeroCounterCanLevelUp(heroLevel, heroList){
          let heroCounter = 0;
          heroList.forEach(hero => {
               if(hero.heroIsUnlock && heroLevel > hero.possibleLevel){
                    heroCounter ++;
               }
          });
          return heroCounter;
     }
     costHero(heroLevel) {
          let res = this.gamePlayDataService.getHeroCost(heroLevel);
          return res;
     }
     goldDrop(gameLevel) {
          let res = this.gamePlayDataService.getCoinDrop(gameLevel);
          return res;
     }
     ghostHp(gameLevel){
          const hp = this.gamePlayDataService.getGhostHP(gameLevel);
          return hp;
     }
     normalBossHp(gameLevel) {
          let temp = this.gamePlayDataService.getBossHP(gameLevel);
          return temp;
     }
     zoneBossHp(gameLevel) {
          let temp = this.gamePlayDataService.getZoneBossHP(gameLevel);
          return temp;
     }
     heroBaseDmg(heroLevel) {
          return  this.gamePlayDataService.getHeroDMG(heroLevel);
     }
     sprintTimeToKillBossWithoutBonus(gameLevel, heroLevel, hitBossPerSecond, teamBattleInfo){
          let baseDmg = this.gamePlayDataService.getHeroDMG(heroLevel);
          let bossInfo = this.getBossInfoByGameLevel(gameLevel);
          //we don't define new teamBattleInfo by boss, because on getHeroDMGSessionResultFromGainBoss has use only Hero element only
          let heroDmgSessionGainBoss = this.getHeroDMGSessionResultFromGainBoss(baseDmg, bossInfo, teamBattleInfo);
          let dmgMTS = this.gamePlayDataService.getHeroDMGOnMultipleSession(baseDmg, heroDmgSessionGainBoss, null);
          let heroDps = this.computeHeroDps(dmgMTS, hitBossPerSecond);
          if(this.isZoneBoss(gameLevel)){
               return this.kpiTTKZB(gameLevel, heroDps);
          } else {
               return this.kpiTTKB(gameLevel, heroDps);
          }
     }
     sprintTimeToKillGhostWithoutBonus(gameLevel, heroLevel, hitPerSecond){
          //one level have 9 ghost
          let ghostCountPerLv = this.gamePlayDataService.ratiosConfig.ghostCounterPerLv;

          let heroDmg = this.gamePlayDataService.getHeroDMG(heroLevel);
          let heroDps = this.computeHeroDps(heroDmg, hitPerSecond);
          let ttkg = this.kpiTTKG(gameLevel, heroDps);
          return SMPNum.multSmpNum(ttkg, new SMPNum(ghostCountPerLv));
     }
     timeToKillBoss(payLoad) {
          const {gameLevel} = payLoad;
          const dps = this.computeHeroDpsBossMode(payLoad);
          return this.kpiTTKB(gameLevel, dps);
     }
     timeToKillZoneBoss(payLoad) {
          const {gameLevel} = payLoad;
          const dps = this.computeHeroDpsBossMode(payLoad);
          return this.kpiTTKZB(gameLevel, dps);
     }
     timeToKillGhost(payLoad) {
          const {
               gameLevel,
               currentHeroLevel,
               goldAvailableForSupportUnlock,
               goldAvailableForSupportLevel,
               goldAvailableForSupportSkill,
               hitPerSecond,
               isUseSupportSkillDmg,
               isUsePetSkillDmg,
               isUsingPet,
               supportList,
               itemImpactBonus,
               heroDmgSessionGainBoss,
               heroDmgSessionFromPet,
               teamBattleInfo,
               petList,
               heroSkillPayload,
               unlockFlyingSupportCounter
          } = payLoad;

          const {
               totalUnlockSupport: supportCount,
          } = this.definePossibleUnlockSupportCounter(goldAvailableForSupportUnlock, gameLevel);
          const {
               levelPossible: supportLevel,
               skillPossibleLevel: supportSkillLevel
          } = this.defineLevelForSupportPossibleReach(goldAvailableForSupportLevel, goldAvailableForSupportSkill, supportCount);

          // let petCount = this.kpiPetCount(gameLevel);
          // let petLevel = this.kpiPetLevel(diamondAvailableForPet, petCount);

          const supportPayload1 = {supportList, supportCount, supportLevel, supportSkillLevel, isUseSupportSkillDmg, itemImpactBonus, teamBattleInfo}
          const petPayload = {petList, isUsePetSkillDmg}

          let supportDps = this.computeSupportDpsStandard(supportPayload1, petPayload, heroSkillPayload, unlockFlyingSupportCounter);

          const supportPayload2 = {supportList, supportDps, isUseSupportSkillDmg}
          let heroDmg = this.kpiGameplayPlayerTapDamage(supportPayload2, petPayload, currentHeroLevel, heroDmgSessionGainBoss, heroDmgSessionFromPet, heroSkillPayload);

          let heroDps = this.computeHeroDps(heroDmg, hitPerSecond);

          let dps = SMPNum.plus(heroDps, supportDps);
          if (isUsingPet){
               let petAvgDps = this.kpiPetDps(heroDmg, petList, hitPerSecond, heroSkillPayload);
               dps = SMPNum.plus(dps, petAvgDps);
          }

          //add item impact dmg
          //because of dmgImpactBonusNormalOnly is a one shot per hit
          //his dps = dmg/1 => dps = dmg
          dps = SMPNum.plus(dps, itemImpactBonus.dmgImpactBonusNormalOnly);

          //add dps item bonus
          dps = SMPNum.plus(dps, this.getTotalDpsItemBonusGhostMode(itemImpactBonus, dps));

          return this.kpiTTKG(gameLevel, dps);
     }
     
     heroBaseDMGIncludeBonus(payLoad){
          const {
               gameLevel,
               currentHeroLevel,
               goldAvailableForSupportUnlock,
               goldAvailableForSupportLevel,
               goldAvailableForSupportSkill,
               //diamondAvailableForPet,
               isUseSupportSkillDmg,
               isUsePetSkillDmg,
               supportList,
               itemImpactBonus,
               teamBattleInfo,
               petList,
               heroSkillPayload,
               unlockFlyingSupportCounter
          } = payLoad;

          const {
               totalUnlockSupport: supportCount,
          } = this.definePossibleUnlockSupportCounter(goldAvailableForSupportUnlock, gameLevel);
          const {
               levelPossible: supportLevel,
               skillPossibleLevel: supportSkillLevel
          } = this.defineLevelForSupportPossibleReach(goldAvailableForSupportLevel, goldAvailableForSupportSkill, supportCount);

          // let petCount = this.kpiPetCount(gameLevel);
          // let petLevel = this.kpiPetLevel(diamondAvailableForPet, petCount);
          const supportPayload1 = {supportList, supportCount, supportLevel, supportSkillLevel, isUseSupportSkillDmg, itemImpactBonus, teamBattleInfo}
          const petPayload = {petList, isUsePetSkillDmg};
          let supportDps = this.computeSupportDpsStandard(supportPayload1, petPayload, heroSkillPayload, unlockFlyingSupportCounter);
          const supportPayload2 = {supportList, supportDps, isUseSupportSkillDmg};
          let heroDmg = this.kpiGameplayPlayerTapDamage(supportPayload2, petPayload, currentHeroLevel, null, null, heroSkillPayload);
          return heroDmg;
     }
     
     computeGoldAvailableFromRatio(totalGold, ratioAllocated) {
          if (!totalGold.isZero && ratioAllocated > 0) {
              let result = SMPNum.multSmpNum(new SMPNum(ratioAllocated), totalGold);
              result = SMPNum.divSmpNum(result, new SMPNum(100));
              return result;
          } else {
              return SMPNum.fromNum(0);
          }
      }
     cumulatedGoldWonAfterReachingGhostLevel(gameLevel) {
          let baseDrop = this.gamePlayDataService.mathGamePlay.ComputeSumGeometricForSeries('DropCoins', gameLevel);
          //one level have 9 ghost
          let ghostCountPerLv = this.gamePlayDataService.ratiosConfig.ghostCounterPerLv;
          return SMPNum.multSmpNum(baseDrop, new SMPNum(ghostCountPerLv)).round();
     }
     cumulatedGoldWonAfterReachingBossLevel(gameLevel) {
          let baseDrop = this.gamePlayDataService.mathGamePlay.ComputeSumGeometricForSeries('DropCoins', gameLevel)
          //10 levels have 9 boss
          let baseNormalGoldOnLevelWasZoneBoss = this.gamePlayDataService.mathGamePlay.ComputeSumWithLoop('DropCoins', 9, gameLevel, 10);
          if(!baseNormalGoldOnLevelWasZoneBoss.isZero && SMPNum.greaterThan(baseDrop, baseNormalGoldOnLevelWasZoneBoss)){
               baseDrop = SMPNum.minus(baseDrop, baseNormalGoldOnLevelWasZoneBoss);
          }
          //let bossCounterPerLv = this.gamePlayDataService.ratiosConfig.bossCounterPerLv;
          let bossGoldRatio = this.gamePlayDataService.ratiosConfig.bossGoldGhostRatio;
          return SMPNum.multSmpNum(baseDrop, new SMPNum(bossGoldRatio)).round();
     }
     cumulatedGoldWonAfterReachingZoneBossLevel(gameLevel) {
          let baseDrop = this.gamePlayDataService.mathGamePlay.ComputeSumWithLoop('DropCoins', 9, gameLevel, 10);//mathGamePlay.ComputeSumGeometricForSeries('DropCoins', gameLevel)
          // //10 level have 1 zone boss
          //let zoneBossCounterPerLv = this.gamePlayDataService.ratiosConfig.zoneBossCounterPerLv;
          let zoneBossGoldRatio = this.gamePlayDataService.ratiosConfig.zoneBossGoldGhostRatio;
          let result = SMPNum.multSmpNum(baseDrop, new SMPNum(zoneBossGoldRatio)).round();
          return result;
     }
     totalEnemiesGolds(gameLevel){
          let baseDrop = this.gamePlayDataService.mathGamePlay.ComputeSumGeometricForSeries('DropCoins', gameLevel);

          //ghost
          let ghostCountPerLv = this.gamePlayDataService.ratiosConfig.ghostCounterPerLv;
          let g = SMPNum.multSmpNum(baseDrop, SMPNum.fromNum(ghostCountPerLv)).round();

          //boss
          //let bossCounterPerLv = this.gamePlayDataService.ratiosConfig.bossCounterPerLv;
          let bossGoldRatio = this.gamePlayDataService.ratiosConfig.bossGoldGhostRatio;
          let baseNormalGoldOnLevelWasZoneBoss = this.gamePlayDataService.mathGamePlay.ComputeSumWithLoop('DropCoins', 9, gameLevel, 10);
          let baseBossGold = baseDrop;
          if(!baseNormalGoldOnLevelWasZoneBoss.isZero && SMPNum.greaterThan(baseDrop, baseNormalGoldOnLevelWasZoneBoss)){
               baseBossGold = SMPNum.minus(baseBossGold, baseNormalGoldOnLevelWasZoneBoss);
          }
          let b = SMPNum.multSmpNum(baseBossGold, SMPNum.fromNum(bossGoldRatio)).round();

          //zone boss
         // let zoneBossCounterPerLv = this.gamePlayDataService.ratiosConfig.zoneBossCounterPerLv;
          let baseZoneBoss = this.gamePlayDataService.mathGamePlay.ComputeSumWithLoop('DropCoins', 9, gameLevel, 10);
          let zoneBossGoldRatio = this.gamePlayDataService.ratiosConfig.zoneBossGoldGhostRatio;
          let zb = SMPNum.multSmpNum(baseZoneBoss, SMPNum.fromNum(zoneBossGoldRatio)).round();

          let total = SMPNum.plus(g, b);
          total = SMPNum.plus(total, zb);

          return total.round();
     }
     cumulatedGoldWonAfterAllEnemiesReachingLevel(gameLevel) {
          let total = this.totalEnemiesGolds(gameLevel);
          return total;
     }
     cumulatedGoldWonAfterAllEnemiesFromGameLevelToGameLevel(fromGameLevel, toGameLevel) {
          let totalStart = this.totalEnemiesGolds(fromGameLevel);
          let totalEnd = this.totalEnemiesGolds(toGameLevel);
          return SMPNum.minus(totalEnd, totalStart);
     }
     getTotalGhost(gameLevel) {
          let counterPerLv = this.gamePlayDataService.ratiosConfig.ghostCounterPerLv;
          let total = Math.round(counterPerLv * gameLevel);
          return total;//SMPNum.fromNum(total).ToReadableAlphabetV2();
     }

     getTotalBoss(gameLevel) {
          let counterPerLv = this.gamePlayDataService.ratiosConfig.bossCounterPerLv;
          let total = Math.round(counterPerLv * gameLevel);
          return total;//SMPNum.fromNum(total).ToReadableAlphabetV2();
     }

     getTotalZoneBoss(gameLevel) {
          let counterPerLv = this.gamePlayDataService.ratiosConfig.zoneBossCounterPerLv;
          let total = Math.floor(counterPerLv * gameLevel);
          return total;//SMPNum.fromNum(total).ToReadableAlphabetV2();
     }

     getTotalEnemies(gameLevel) {
          //ghost
          let counterPerLv = this.gamePlayDataService.ratiosConfig.ghostCounterPerLv;
          let total = Math.round(counterPerLv * gameLevel);

          //boss
          counterPerLv = this.gamePlayDataService.ratiosConfig.bossCounterPerLv;
          total += Math.ceil(counterPerLv * gameLevel);

          //zone boss
          counterPerLv = this.gamePlayDataService.ratiosConfig.zoneBossCounterPerLv;
          total += Math.floor(counterPerLv * (gameLevel+1));

          return total;//SMPNum.fromNum(total).ToReadableAlphabetV2();
     }
     getDiamondStartUp(){
          return this.gamePlayDataService.getDiamondStartUp();
     }
     getDiamondBossCollect(gameLevel){
          return this.gamePlayDataService.getDiamondDrop(gameLevel);
     }
     computeDiamondFromAchievements(gameLevel, heroBaseDps, supportLevel, supportCount, achievementList, totalGoldOnLevelReach){
          let service = new AchievementSimulationService(
               lodash.cloneDeep(achievementList),
               this.balanceBuilder,
               this.gamePlayDataService,
               this.supportKpiUtils
          );


          const achievementReward = service.compute(
               gameLevel,
               [], heroBaseDps,
               supportLevel.ToDoubleValue() * supportCount, totalGoldOnLevelReach
          );

          return achievementReward;
     }
     supportList(supportIdsUnlock) {
          let availableSupport = this.supportKpiUtils.getGamePlayUnlockedAndLockTeam(supportIdsUnlock);

          let rows = [];
          let rowCount = 1;
          let tempRow = [];
          for (let i = 0; i < availableSupport.length; i++) {
               tempRow.push(availableSupport[i]);
               if (rowCount === 10 || i === availableSupport.length - 1) {
                    rows.push(tempRow);
                    rowCount = 0;
                    tempRow = [];
               }
               rowCount++;
          }
          return rows;
     }
     getPetList(petCount) {

          let availablePet = this.petKpiUtils.getDisplayPetList(petCount);

          let rows = [];
          let rowCount = 1;
          let tempRow = [];
          for (let i = 0; i < availablePet.length; i++) {
               tempRow.push(availablePet[i]);
               if (rowCount === 10 || i === availablePet.length - 1) {
                    rows.push(tempRow);
                    rowCount = 0;
                    tempRow = [];
               }
               rowCount++;
          }
          return {
               petDisplays: rows,
               petList: availablePet
          };
     }
     getHeroActiveSkillList(heroSkillsUnlockList){
          return this.heroSkillKpiUtils.getHeroSkillPossibleByTypes(heroSkillsUnlockList, [0, 1]);
     }
     getHeroBossSkillList(heroSkillsUnlockList){
          return this.heroSkillKpiUtils.getHeroSkillPossibleByTypes(heroSkillsUnlockList, [2]);
     }

     getHeroPassiveSkillList(heroSkillsUnlockList){
          return this.heroSkillKpiUtils.getHeroSkillPossibleByTypes(heroSkillsUnlockList, [3]);
     }
     computeMaximumLevelAchievable(gameLevel, availableGold, heroList) {
          let found = false;

          let target = Math.floor(gameLevel / 2);
          if(target<1){
               target = 1;
          }
          let previousTarget = gameLevel;
          let securityLoop = 1;
          let levelToReturn = -1;
          do {
               let remaining = this.computeRemainingMoney(target, availableGold, heroList);

               let goLeft = remaining.isZero;
               if (!goLeft) {
                    levelToReturn = target;
               }
               let temp = target;
               if (goLeft) {
                    target = target - Math.floor((Math.abs(target - previousTarget)) / 2);
               } else {
                    target = target + Math.floor((Math.abs(previousTarget - target)) / 2);
               }
               previousTarget = temp;
               if (previousTarget === target) {
                    found = true;
               }
               securityLoop++;
          } while (!found && securityLoop < 100);
          return levelToReturn;
     }

     computeRemainingMoney(heroLevel, goldAvailable, heroList) {
          if(heroLevel >= 1){
               let willSpentOnHeroLevel = this.gamePlayDataService.mathGamePlay.ComputeSumGeometricForSeries('CostHero', heroLevel);

               //add gold from no level cost on unlock each hero level
               let heroSpentCount = 0;
               let freeGoldForFirstUnk = new SMPNum(0);
               heroList.forEach(hero => {
                    if(hero.heroIsUnlock){
                         let maxLevelFree = heroLevel < hero.levelUnlock ? heroLevel : hero.levelUnlock;
                         let costFirstLv = this.gamePlayDataService.mathGamePlay.ComputeSumGeometricForSeries('CostHero', maxLevelFree);
                         freeGoldForFirstUnk = SMPNum.plus(freeGoldForFirstUnk, costFirstLv);
                         heroSpentCount ++;
                    }
               });

               //console.log('Target level: '+heroLevel+' hero count '+heroSpentCount);
               //console.log(' gold av: '+goldAvailable.ToReadableAlphabetV2());

               //total spent for possible Hero unlock
               willSpentOnHeroLevel = SMPNum.multSmpNum(willSpentOnHeroLevel, new SMPNum(heroSpentCount));

               //not include free level from hero unlock
               willSpentOnHeroLevel = SMPNum.minus(willSpentOnHeroLevel, freeGoldForFirstUnk);

               //console.log(' spent '+willSpentOnHeroLevel.ToReadableAlphabetV2());

               //let goldIncludeFreeUnk = SMPNum.plus(goldAvailable, freeGoldForFirstUnk);
               if (SMPNum.greaterThan(goldAvailable, willSpentOnHeroLevel)) {
                    let temp = SMPNum.minus(goldAvailable, willSpentOnHeroLevel);
                    //console.log(heroLevel+' remain '+temp.ToReadableAlphabetV2());
                    return temp;
               } else {
                    return new SMPNum(0);
               }
          }
          return new SMPNum(0);
     }

     defineSupportSelectedBonusSkill(skillType, supportId, supportList) {
          let percent = this.supportKpiUtils.getSupportCumulatedPercent(supportId
              , skillType
              , supportList);

          return percent * 100;
     }

     getZoneByLevel(level){
        return Math.floor(level / MAX_LEVEL_ON_STAGE);
     }
     GetRealZoneByLevel (level) {
          let zone_index = this.getZoneByLevel(level + 1);
          let timeOfBigger = 0;
          if (zone_index >= MAX_ZONE) {
               timeOfBigger = Math.floor(zone_index / MAX_ZONE);
               zone_index = zone_index - (MAX_ZONE * timeOfBigger);
          }

          return zone_index;
     }
     getBossIndexByLevel(level){
          let z = this.getZoneByLevel(level);
          if (level < MAX_LEVEL_ON_STAGE){
               level -= 1;
          }
          let b = level - (z * MAX_LEVEL_ON_STAGE);
          return b;
     }
     getMaxBossOnZone(zone){
          if(zone === 0){
               return 9;
          }else {
               return MAX_LEVEL_ON_STAGE;
          }
     }
     getMaxBossOnLevelStage(lvTarget){
          return this.getMaxBossOnZone(this.getZoneByLevel(lvTarget));
     }

     isZoneBoss(level){
          const zoneBossIndex = this.getMaxBossOnLevelStage(level);
          const currentBossIndex = this.getBossIndexByLevel(level);
          return zoneBossIndex === currentBossIndex + 1;
     }

     getTotalDpsItemBonus(itemImpactBonus, totalDps){
          //add dps bonus by direct
          let dps = SMPNum.plus(new SMPNum(0), itemImpactBonus.dpsImpactBonus);

          //add dps by percentage of item bonus
          if(itemImpactBonus.dpsPercentBonus > 0){
               dps = SMPNum.plus(dps, SMPNum.multSmpNum(totalDps, new SMPNum(itemImpactBonus.dpsPercentBonus)));
          }
          return dps;
     }

     getTotalDpsItemBonusBossMode(itemImpactBonus, totalDps){
          //add dps bonus by direct
          let dps = SMPNum.plus(new SMPNum(0), itemImpactBonus.dpsImpactBonusBossOnly);

          //add dps by percentage of item bonus
          if(itemImpactBonus.dpsPercentBonusBossOnly > 0){
               dps = SMPNum.plus(dps, SMPNum.multSmpNum(totalDps, new SMPNum(itemImpactBonus.dpsPercentBonusBossOnly)));
          }
          return dps;
     }

     getTotalDpsItemBonusGhostMode(itemImpactBonus, totalDps){
          //add dps bonus by direct
          let dps = SMPNum.plus(new SMPNum(0), itemImpactBonus.dpsImpactBonusNormalOnly);

          //add dps by percentage of item bonus
          if(itemImpactBonus.dpsPercentBonusNormalOnly > 0){
               dps = SMPNum.plus(dps, SMPNum.multSmpNum(totalDps, new SMPNum(itemImpactBonus.dpsPercentBonusNormalOnly)));
          }
          return dps;
     }

     getTotalHpItemBonus(itemImpactBonus, heroLevel){
          //add hp bonus by direct
          let hp = SMPNum.plus(new SMPNum(0), itemImpactBonus.hpImpactBonus);

          //add dps by percentage of item bonus
          if(itemImpactBonus.hpPercentBonus > 0){
               let heroHpGameLevel = this.gamePlayDataService.getHeroHp(1, heroLevel);
               hp = SMPNum.plus(hp, SMPNum.multSmpNum(heroHpGameLevel, new SMPNum(itemImpactBonus.hpPercentBonus)));
          }
          return hp;
     }

     getTotalHeroHpWithItemBonus(heroHp, itemHp){
          return SMPNum.plus(heroHp, itemHp);
     }

     getHeroHp(heroLevel){
          return this.gamePlayDataService.getHeroHp(1, heroLevel);
     }
     getTimeHeroPossibleDieWithNormalBoss(heroHp, gameLevel){
          let bossDps = this.gamePlayDataService.getBossDps(gameLevel, heroHp);
          return SMPNum.divSmpNum(heroHp, bossDps)
     }
     getTimeHeroPossibleDieWithZoneBoss(heroHp, gameLevel){
          let bossDps = this.gamePlayDataService.getZoneBossDps(gameLevel, heroHp);
          return SMPNum.divSmpNum(heroHp, bossDps)
     }
     getBossDps(gameLevel, finalHeroHp){
          let bossDps = this.gamePlayDataService.getBossDps(gameLevel, finalHeroHp);
          return bossDps;
     }
     getZoneBossDps(gameLevel, finalHeroHp){
          let bossDps = this.gamePlayDataService.getZoneBossDps(gameLevel, finalHeroHp);
          return bossDps;
     }
     getGeneratedHeroHpPerSecond(heroHp){
          const regenerateHpFromLeafPercentage = new SMPNum(0.15); //15% per second
          const generatedHp = SMPNum.multSmpNum(heroHp, regenerateHpFromLeafPercentage)

          return generatedHp;
     }
     getExpectedGeneratedHp(hpPerSecond, timePossibleDie){
          return SMPNum.multSmpNum(hpPerSecond, timePossibleDie)
     }
     getPossibleSurvive(heroHp, bossDps, hpPerSecond){
          
          let timeToDie = SMPNum.divSmpNum(heroHp, bossDps);
          let possibleSurviveTime = timeToDie;

          let generatedHp = SMPNum.multSmpNum(hpPerSecond, timeToDie);

          let stillHaveTime = SMPNum.greaterThan(generatedHp, bossDps);

          let projectInfinite = 20;

          let risk = 8;
          while(stillHaveTime && projectInfinite > 0){
               //reduce hpPerSecond by include risk (risk is when hp generated not always working, ex: leaf not always drop)
               let reduceSecond = SMPNum.multSmpNum(hpPerSecond, new SMPNum(0.1 * risk));
               if(SMPNum.greaterThan(hpPerSecond, reduceSecond)){
                    hpPerSecond = SMPNum.minus(hpPerSecond, reduceSecond);
               } else {
                    hpPerSecond.setNumDouble(0);
               }

               timeToDie = SMPNum.divSmpNum(generatedHp, bossDps);
               possibleSurviveTime = SMPNum.plus(timeToDie, possibleSurviveTime);
               generatedHp = SMPNum.multSmpNum(hpPerSecond, timeToDie);
               stillHaveTime = SMPNum.greaterThan(generatedHp, bossDps);

               projectInfinite --;
               risk++;
          }
          
         return possibleSurviveTime;
     }
     getHpItemRequirePayload(timeOnBoss, heroHp, bossDps, timeRequireForHpItem) {
          //Time-ZoneBoss (timeOnBoss) under 10 seconds is hard to play
          let minPossible = new SMPNum(timeRequireForHpItem);
          if (SMPNum.greaterThan(minPossible, timeOnBoss)) {
               let heroHpInNormalTimeRequire = SMPNum.multSmpNum(minPossible, bossDps);
               let hpTarget = heroHpInNormalTimeRequire;

               if (SMPNum.greaterThan(hpTarget, heroHp)) {
                    return {
                         require: SMPNum.minus(hpTarget, heroHp),
                         target: hpTarget
                    };
               }
          }
          return undefined;
     }
     getDmgItemRequirePayload(timeToKillBoss, payLoad, timeRequireForDmgItem) {
          //timeToKillBoss over 40 seconds is hard to play
          let minPossible = new SMPNum(timeRequireForDmgItem);
          if (SMPNum.greaterThan(timeToKillBoss, minPossible)) {
               const {gameLevel} = payLoad;
               const heroDps = this.computeHeroDpsBossMode(payLoad);
               let bossHp = this.zoneBossHp(gameLevel);
               let dmgTarget = SMPNum.divSmpNum(bossHp, minPossible);
               if(SMPNum.greaterThan(dmgTarget, heroDps)) {
                    return {
                         require: SMPNum.minus(dmgTarget, heroDps),
                         target: dmgTarget
                    };
               }
          }
          return undefined;
     }
     getDmgItemWillBonusOnMarketPlace(marketItemWillBonus, payload){
          const {
               gameLevel,
               heroLevel,
               goldAvailableForSupportUnlock,
               goldAvailableForSupportLevel,
               goldAvailableForSupportSkill,
               diamondAvailableForPet,
               hitBossPerSecond,
               isUseSupportSkillDmg,
               isUsePetSkillDmg,
               isUsingPet,
               supportList,
               teamBattleInfo,
               petList,
               heroSkillPayload,
               unlockFlyingSupportCounter,
          } = payload;

          let totalDps = this.computeHeroDpsWithItemImpactBonus(gameLevel, heroLevel, goldAvailableForSupportUnlock, goldAvailableForSupportLevel
              , goldAvailableForSupportSkill, diamondAvailableForPet, hitBossPerSecond, isUseSupportSkillDmg
              , isUsePetSkillDmg, isUsingPet, supportList, marketItemWillBonus, undefined, undefined, teamBattleInfo, petList, heroSkillPayload, unlockFlyingSupportCounter);

          let dpsWithoutItem = this.computeHeroDpsWithItemImpactBonus(gameLevel, heroLevel, goldAvailableForSupportUnlock, goldAvailableForSupportLevel
              , goldAvailableForSupportSkill, diamondAvailableForPet, hitBossPerSecond, isUseSupportSkillDmg
              , isUsePetSkillDmg, isUsingPet, supportList, undefined, undefined, undefined, teamBattleInfo, petList, heroSkillPayload, unlockFlyingSupportCounter);
          return SMPNum.minus(totalDps, dpsWithoutItem);
     }

     getBossInfoByGameLevel(gameLevel){
          return this.enemyKpiUtils.getBossInfoByGameLevel(gameLevel);
     }

     getHeroUnlockedCount(gameLevel){
          return this.charactersKpiUtils.getHeroUnlockedCount(this.getZoneByLevel(gameLevel));
     }

     isHeroUnlockYet(teamBattleInfo, gameLevel){
          return this.charactersKpiUtils.isHeroUnlockYet(teamBattleInfo.heroData.m_iID, this.getZoneByLevel(gameLevel));
     }

     getTeamsBattleData(bossInfo, heroCount, gameLevel, kpiHeroList){
          return this.charactersKpiUtils.getTeamsBattleData(bossInfo, heroCount, gameLevel, kpiHeroList);
     }

     getDisplayHeroList(heroCount, heroLevel){
          let heroList = this.charactersKpiUtils.getDisplayHeroList(heroCount);
          heroList.forEach(hero => {
               if(hero.heroIsUnlock && heroLevel > hero.levelUnlock){
                    hero.possibleLevel = heroLevel;
               }
          });
          return heroList;
     }

     setCurrentHeroToTeamBattleInfo(heroId, teamBattleInfo){
          teamBattleInfo.heroData = this.charactersKpiUtils.getHeroDataById(heroId);
          this.setCurrentHeroStore(teamBattleInfo)
     }

     setCurrentHeroStore(teamBattleInfo){
          this.gamePlayDataService.multipleSessionDataStore.heroStore.heroData = teamBattleInfo.heroData;//setCurrentHeroStore(teamBattleInfo.heroData, heroLevel);
          this.gamePlayDataService.multipleSessionDataStore.heroStore.heroLevel = teamBattleInfo.heroData.possibleLevel;
     }

     getCurrentHeroLevel(){
          return this.gamePlayDataService.multipleSessionDataStore.heroStore.heroLevel;
     }

     setCurrentBossStore(bossInfo, gameLevel){
          this.gamePlayDataService.multipleSessionDataStore.bossStore.bossData = bossInfo;//setCurrentBossStore(bossInfo);
          this.gamePlayDataService.multipleSessionDataStore.bossStore.gameLevel = gameLevel;
     }

     setSelectPetActive(petData){
          if(petData){
               this.petKpiUtils.setSelectPetActive(petData);
          }
     }

     definePetJoinTop3(petList, isUseHeroSkillDmg, isUsePetAllianceSkill, heroBossSkillList){
          let isEnablePetTop3 = false;
          if(isUseHeroSkillDmg && isUsePetAllianceSkill){
               if(heroBossSkillList){
                    heroBossSkillList.forEach(skDis => {
                         if(skDis.skill.m_iID === HeroSkillType.ANGER_OF_PET){
                              isEnablePetTop3 = true;
                         }
                    });

               }
          }
          this.petKpiUtils.definePetJoinTop3(petList, isEnablePetTop3);
     }

     getPetDataById(petId){
          return this.charactersKpiUtils.getPetDataById(petId);
     }

     getHeroDMGSessionResultFromGainBoss(baseDmg, bossInfo, teamBattleInfo){
          return this.multipleSessionKpiUtils.getHeroDMGSessionResultFromGainBoss(baseDmg, teamBattleInfo.heroData.elementType, bossInfo.elementType);
     }

     getBaseHeroDMGWithMultipleSession(baseDmg, heroDmgSessionGainBoss, heroDmgSessionFromPet){
          return this.multipleSessionKpiUtils.getBaseHeroDMGWithMultipleSession(baseDmg, heroDmgSessionGainBoss, heroDmgSessionFromPet);
     }

     getHeroDmgSessionResultFromPets(baseDmg, bossInfo, teamBattleInfo){
          let petsElement = [];
          let petActives = [];
          if(teamBattleInfo.petData){
               petsElement.push(teamBattleInfo.petData.elementType);
               petActives.push(teamBattleInfo.petData);
          }
          let petTop3Datas = this.petKpiUtils.getTop3PetDatas();
          if(petTop3Datas.length > 0){
               petTop3Datas.forEach(pet => {
                    petsElement.push(pet.elementType);
                    petActives.push(pet);
               });
          }
          return this.multipleSessionKpiUtils.getHeroDmgSessionResultFromPets(baseDmg, teamBattleInfo.heroData.elementType, petsElement, petActives);
     }

     defineBestSupportTeam(teamBattleInfo, supportsHaveUnlock, isUseFlyingSupport){
          let bestSupportTeam = this.charactersKpiUtils.getBestSupportTeam(teamBattleInfo.heroData.elementType, supportsHaveUnlock, isUseFlyingSupport);
          teamBattleInfo.supportsData = bestSupportTeam;
     }

     getHeroHpCapSessionFromSupport(teamBattleInfo, supportsHaveUnlock){
          let supportElements = [];
          let bestSupportTeam = teamBattleInfo.supportsData;
          let supportUnlocked = [];
          bestSupportTeam.forEach(supportData =>{
               if(!supportElements.includes(supportData.elementType)){
                    supportElements.push(supportData.elementType);
               }

               let support = supportsHaveUnlock.find(s => s.m_iID === supportData.m_iID);
               if(support){
                    supportUnlocked.push(support);
               } else {
                    console.log('why support here is null in m_iID: '+supportData.m_iID);
               }
          });
          return this.multipleSessionKpiUtils.getHeroHpSessionResultFromSupports(teamBattleInfo.heroData.elementType, supportElements, supportUnlocked);
     }
}

export default LogicSimulationService