<template>
    <v-card
        max-height="900"
    >
        <v-row class="ml-1 pt-4">
            <div class="font-weight-black">PETS</div>
        </v-row>
        <v-row class="ml-1 pt-4"
               v-for="(rows, row) in kpiPetList"
               :key="row"
               dense no-gutters
        >
            <v-col
                v-for="(item, i) in rows"
                :key="i"
            >
                <v-badge :color="getUnlockTextColor(item)"
                         offset-x="20"
                         offset-y="60"
                         :content="item.levelUnlock"
                >
                    <v-avatar start>
                        <v-img
                                :src="Tools.renderLocalPicture('pets/'+item.petID+'.png')"
                                :gradient="getUnlockAvatarGradient(item)"
                                @click="onSelectPet(item)"
                        >
                        </v-img>
                    </v-avatar>
                    <v-badge
                            v-if="item.petIsUnlock"
                            color="#FF8C00"
                            offset-x="20"
                            offset-y="-10"
                            :content="getPetPossibleLevel(item)"
                    >
                    </v-badge>
                    <v-badge
                            color="#89CFF0"
                            :offset-x="getElementX(item)"
                            offset-y="32"
                            :content="getPetElement(item)"
                    >
                    </v-badge>
                    <v-badge
                            v-if="isSelectedPet(item)"
                            offset-x="50"
                            offset-y="-10"
                            content="✔"
                    >
                    </v-badge>
                </v-badge>
            </v-col>
        </v-row>
        <v-row class="ml-1 pt-4">
            <v-col>
                <v-row class="pa-0">
                    <div class="font-weight-black"> Current Active PET SKILLS BONUS</div>
                </v-row>
                <v-row class="ml-0 pt-0">
                    <v-col>
                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                <tr>
                                    <th class="text-left">
                                        Type
                                    </th>
                                    <th class="text-left">
                                        Bonus
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>{{skillCurrentPetTypeName}}</td>
                                    <td>{{skillCurrentPetBonus}}</td>
                                </tr>
                                <tr v-for="petJoin in getPetJoinTop3Bonus"
                                    :key='"petJoin-"+petJoin.petId'>
                                    <td>{{petJoin.petBonusTypeName}} (Alliance)</td>
                                    <td>{{petJoin.bonusValue}} ({{petJoin.allianceBonus}})</td>
                                </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row class="ml-1 pt-4">
            <v-col>
                <v-row class="pa-0">
                    <div class="font-weight-black"> PET SKILLS BONUS</div>
                </v-row>
                <v-row class="ml-0 pt-0">
                    <v-col>
                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                <tr>
                                    <th class="text-left">
                                        Type
                                    </th>
                                    <th class="text-left">
                                        Avg. Bonus
                                    </th>
                                    <th class="text-left">
                                        &Sigma;Passive Bonus
                                    </th>
                                    <th class="text-left">
                                        &Sigma;Possible Bonus
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>ALL_DAMAGE</td>
                                    <td>{{skillPetAvgActiveAllDmg}}</td>
                                    <td>{{skillPetPassiveAllDmg}}</td>
                                    <td><strong>{{skillPetAllDmg}}</strong></td>
                                </tr>
                                <tr>
                                    <td>ALL_GOLD</td>
                                    <td>{{skillPetAvgActiveAllGold}}</td>
                                    <td>{{skillPetPassiveAllGold}}</td>
                                    <td><strong>{{skillPetAllGold}}</strong></td>
                                </tr>
                                <tr>
                                    <td>ALL_SUPPORT_DMG</td>
                                    <td>{{skillPetAvgActiveSupportDmg}}</td>
                                    <td>{{skillPetPassiveSupportDmg}}</td>
                                    <td><strong>{{skillPetSupportDmg}}</strong></td>
                                </tr>
                                <tr>
                                    <td>TAP_DAMAGE</td>
                                    <td>{{skillPetAvgActiveTapDmg}}</td>
                                    <td>{{skillPetPassiveTapDmg}}</td>
                                    <td><strong>{{skillPetTapDmg}}</strong></td>
                                </tr>

                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import Tools from "../../../utils/Tools";
import ElementTypeAndName, {ElementType} from "../../constants/ElementTypeAndName";
import {GlobalEvents, EVENT_TO_SELECT_PET} from "@/item-editor/events/GlobalEvents";

export default {
    name: "GroupTabPet",
    props: ['payload'],
    data () {
        return {
            Tools: Tools,
        }
    },
    computed: {
        kpiPetList () {
            const {kpiPetDisplays} = this.payload;
            return kpiPetDisplays;
        },
        skillPetAvgActiveAllDmg () {
            const {kpiPetSkills: {skillPetAvgActiveAllDmg} = 0} = this.payload;
            return skillPetAvgActiveAllDmg;
        },
        skillPetPassiveAllDmg () {
            const {kpiPetSkills: {skillPetPassiveAllDmg} = 0} = this.payload;
            return skillPetPassiveAllDmg;
        },
        skillPetAllDmg () {
            const {kpiPetSkills: {skillPetAllDmg} = 0} = this.payload;
            return skillPetAllDmg;
        },
        skillPetAvgActiveAllGold () {
            const {kpiPetSkills: {skillPetAvgActiveAllGold} = 0} = this.payload;
            return skillPetAvgActiveAllGold;
        },
        skillPetPassiveAllGold () {
            const {kpiPetSkills: {skillPetPassiveAllGold} = 0} = this.payload;
            return skillPetPassiveAllGold;
        },
        skillPetAllGold () {
            const {kpiPetSkills: {skillPetAllGold} = 0} = this.payload;
            return skillPetAllGold;
        },
        skillPetAvgActiveSupportDmg () {
            const {kpiPetSkills: {skillPetAvgActiveSupportDmg} = 0} = this.payload;
            return skillPetAvgActiveSupportDmg;
        },
        skillPetPassiveSupportDmg () {
            const {kpiPetSkills: {skillPetPassiveSupportDmg} = 0} = this.payload;
            return skillPetPassiveSupportDmg;
        },
        skillPetSupportDmg () {
            const {kpiPetSkills: {skillPetSupportDmg} = 0} = this.payload;
            return skillPetSupportDmg;
        },
        skillPetAvgActiveTapDmg () {
            const {kpiPetSkills: {skillPetAvgActiveTapDmg} = 0} = this.payload;
            return skillPetAvgActiveTapDmg;
        },
        skillPetPassiveTapDmg () {
            const {kpiPetSkills: {skillPetPassiveTapDmg} = 0} = this.payload;
            return skillPetPassiveTapDmg;
        },
        skillPetTapDmg () {
            const {kpiPetSkills: {skillPetTapDmg} = 0} = this.payload;
            return skillPetTapDmg;
        },
        skillCurrentPetTypeName(){
            return this.getCurrentPetBonus().petBonusTypeName;
        },
        skillCurrentPetBonus(){
            return this.getCurrentPetBonus().bonusValue;
        },
        getPetJoinTop3Bonus(){
            return this.getCurrentPetBonus().petJoinsTop3;
        },
        // getPetPossibleLevel(){
        //     const {kpiPetLevel} = this.payload;
        //     return kpiPetLevel;
        // }
    },
    methods: {
        getUnlockTextColor(item){
            return item.petIsUnlock ? 'green' : 'rgb(139,0,0)';
        },
        getUnlockAvatarGradient(item){
            if(item.petIsUnlock){
                /* disable circle
                let currentActivePet = this.getCurrentPetBonus();
                if(currentActivePet.petId === item.petID){
                    return "to top right, rgba(0,255,255,.3), rgba(255,255,255,.4)";
                }*/
                return "" ;
            } else {
                return "to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)";
            }
        },
        getCurrentPetBonus(){
            const {kpiPetSkills: {skillCurrentActivePet} = {
                petId: -1,
                petBonusType: -1,
                petBonusTypeName: "Null",
                bonusValue: 0
            }} = this.payload;
            return skillCurrentActivePet;
        },
        getPetElement(item){
            return ElementTypeAndName[item.elementType];
        },
        getElementX(item){
            return item.elementType !== ElementType.Fire ? 58 : 47;
        },
        onSelectPet(item){
            if(item.petIsUnlock){// && this.heroSelectId() !== item.m_iID) {
                let currentActivePet = this.getCurrentPetBonus();
                if(currentActivePet.petId !== item.petID){
                    GlobalEvents.$emit(EVENT_TO_SELECT_PET, item.petID);
                }
            }
        },
        isSelectedPet(item){
            if(item.petIsUnlock){
                let currentActivePet = this.getCurrentPetBonus();
                if(currentActivePet.petId === item.petID){
                    return true;
                }
            }
        },
        getPetPossibleLevel(item){
            return item.possibleLevel;
        },
    },
}
</script>

<style scoped>

</style>