<template>
  <v-card
        min-width="420"
        max-width="420"
        min-height="350"
        class="pa-2"
  >
    <v-row v-if="isSync">
      <v-progress-linear
              color="deep-purple accent-4"
              indeterminate
              rounded
              height="20"
      ></v-progress-linear>
    </v-row>
    <v-card-title>Sync Config with Server</v-card-title>
    <v-row>
      <v-col>
        <v-card
                min-width="400"
                max-width="400"
                class="pa-4 my-0"
                elevation="0"
                outlined
        >
          <div class="pb-2">Get all kpi config from server.</div>
          <v-row>
            <v-col cols="3" class="pt-2">
              <v-btn
                      @click="doPull"
                      :disabled="isSync"
              >
                Pull
              </v-btn>
            </v-col>
            <v-col>
              {{msgPull}}
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card
                min-width="400"
                max-width="400"
                class="pa-4 my-0"
                elevation="0"
                outlined
        >
          <div class="pb-2">Push all kpi local config to server.</div>
          <v-row>
            <v-col cols="3" class="pt-2">
              <v-btn
                      @click="doPush"
                      :disabled="isSync"
              >
                Push
              </v-btn>
            </v-col>
            <v-col>
              {{msgPush}}
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="justify-end">
      <v-col cols="3">
        <v-btn
                @click="doClose"
                :disabled="isSync"
        >
          Close
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>

  import KpiConfigLoader from './../../../dao/KpiConfigLoader';

  export default {
    name: "SyncConfigPopup",
    props: ["pConfig"],
    data() {
      return {
        msgPull: '',
        msgPush: '',
        isSync: false,
      }
    },
    methods:{
      async doPull(){
        if (confirm('Are you sure you want to pull config from server?')) {
          this.isSync=true;
          this.msgPull = 'pulling...';
          let data = await KpiConfigLoader.pullConfig();
          if(data){
            this.msgPull = 'sync to use server config success!';
          } else {
            this.msgPull = 'no data for pull!';
          }
          this.$emit("onPulled", data);
          this.isSync=false;
        }
      },
      async doPush(){
        if (confirm('Are you sure you want to push local config to server?')) {
          this.isSync=true;
          this.msgPush = 'pushing...';
          let data = await KpiConfigLoader.pushConfig(this.pConfig);
          if(data.status === 200){
            this.msgPush = 'push success!';
          } else {
            this.msgPush = 'push failed: '+data.statusText;
          }
          this.isSync=false;
        }
      },
      doClose(){
        this.$emit("close");
      },
    },
  }
</script>

<style scoped>

</style>