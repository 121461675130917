<template>
    <v-card
            max-height="900"
    >
        <v-row class="ml-1 pt-4">
            <v-col>
                <v-row class="pa-0">
                    <div class="font-weight-black">Heroes</div>
                </v-row>
                <v-row class="ml-1 pt-4"
                       dense no-gutters
                >
                    <v-col
                            v-for="item in kpiHeroesList"
                            :key="'hero_avatar'+item.m_iID"
                    >
                        <v-badge :color="getUnlockTextColor(item)"
                                 offset-x="25"
                                 offset-y="60"
                                 :content="item.levelUnlock"
                        >
                            <v-avatar start>
                                <v-img
                                        :src="Tools.renderHeroPicture(item.m_iID)"
                                        :gradient="getUnlockAvatarGradient(item)"
                                        @click="onSelectHero(item)"
                                >
                                </v-img>
                            </v-avatar>
                            <v-badge
                                    v-if="item.heroIsUnlock"
                                    color="#FF8C00"
                                    offset-x="20"
                                    offset-y="-10"
                                    :content="heroPossibleLevel(item)"
                            >
                            </v-badge>
                            <v-badge
                                    color="#89CFF0"
                                    offset-x="65"
                                    offset-y="32"
                                    :content="getHeroElement(item)"
                            >
                            </v-badge>
                            <v-badge
                                    v-if="isSelectedHero(item)"
                                    offset-x="50"
                                    offset-y="-10"
                                    content="✔"
                            >
                            </v-badge>
                        </v-badge>
                    </v-col>
                </v-row>
                <v-row class="pa-0">
                    <div class="font-weight-black">Active Skill</div>
                </v-row>
                <v-row class="ml-0 pt-0">
                    <v-simple-table>
                        <template v-slot:default>
                            <tbody>
                            <tr v-for="item in kpiHeroActiveSkillList"
                                :key="item.key">
                                <td>
                                    <v-avatar><img
                                        :src="Tools.renderLocalPicture('hero_skill/'+item.skill.m_iID+'.png')"
                                    ></v-avatar>
                                </td>
                                <td>{{item.skill.m_sName}}</td>
                                <td>Lv: {{item.levelPossible}}</td>
                                <td>{{item.bonusDisplay}}</td>
                            </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-row>
                <v-row class="pa-0">
                    <div class="font-weight-black">Boss Skill</div>
                </v-row>
                <v-row class="ml-0 pt-0">
                    <v-simple-table>
                        <template v-slot:default>
                            <tbody>
                            <tr v-for="item in kpiHeroBossSkillList"
                                :key="item.key">
                                <td>
                                    <v-avatar><img
                                        :src="Tools.renderLocalPicture('hero_skill/'+item.skill.m_iID+'.png')"
                                    ></v-avatar>
                                </td>
                                <td>{{item.skill.m_sName}}</td>
                                <td>Lv: {{item.levelPossible}}</td>
                                <td>{{item.bonusDisplay}}</td>
                            </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-row>
                <v-row class="pa-0">
                    <div class="font-weight-black">Passive Skill</div>
                </v-row>
                <v-row class="ml-0 pt-0 pb-5">
                    <v-simple-table>
                        <template v-slot:default>
                            <tbody>
                            <tr v-for="item in kpiHeroPassiveSkillList"
                                :key="item.key">
                                <td>
                                    <v-avatar><img
                                        :src="Tools.renderLocalPicture('hero_skill/'+item.skill.m_iID+'.png')"
                                    ></v-avatar>
                                </td>
                                <td>{{item.skill.m_sName}}</td>
                                <td>Lv: {{item.levelPossible}}</td>
                                <td>{{item.bonusDisplay}}</td>
                            </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-row>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import Tools from "../../../utils/Tools";
import ElementTypeAndName from "../../constants/ElementTypeAndName";
import {GlobalEvents, EVENT_TO_SELECT_HERO} from "@/item-editor/events/GlobalEvents";

export default {
    name: "GroupTabHeroes",
    props: ['payload'],
    data () {
        return {
            Tools: Tools,
        }
    },
    computed: {
        kpiHeroesList(){
            const {kpiHeroList} = this.payload;
            return kpiHeroList;
        },
        kpiHeroActiveSkillList () {
            const {kpiHeroActiveSkillList} = this.payload;
            return kpiHeroActiveSkillList;
        },
        kpiHeroPassiveSkillList () {
            const {kpiHeroPassiveSkillList} = this.payload;
            return kpiHeroPassiveSkillList;
        },
        kpiHeroBossSkillList () {
            const {kpiHeroBossSkillList} = this.payload;
            return kpiHeroBossSkillList;
        },
        // getHeroPossibleLevel(){
        //     const {kpiHeroLevel} = this.payload;
        //     return kpiHeroLevel;
        // },
        teamBattleInfo(){
            const {teamBattleInfo} = this.payload;
            return teamBattleInfo;
        },
    },
    methods: {
        getUnlockTextColor(item){
            return item.heroIsUnlock ? 'green' : 'rgb(139,0,0)';
        },
        getUnlockAvatarGradient(item){
            if(item.heroIsUnlock){
                return "" ;
                /*
                if(this.heroSelectId() === item.m_iID){
                    return "to top right, rgba(0,255,255,.3), rgba(255,255,255,.4)";
                } else {
                    return "" ;
                }*/
            } else {
                return "to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)";
            }
        },
        isSelectedHero(item){
            return this.heroSelectId() === item.m_iID;
        },
        heroSelectId(){
            let id = 1;
            if(this.teamBattleInfo){
                id = this.teamBattleInfo.heroData.m_iID;
            }
            return id;
        },
        heroPossibleLevel(item){
           return item.possibleLevel;
        },
        getHeroElement(item){
            return ElementTypeAndName[item.elementType];
        },
        onSelectHero(item){
            if(item.heroIsUnlock && this.heroSelectId() !== item.m_iID) {
                GlobalEvents.$emit(EVENT_TO_SELECT_HERO, item.m_iID);
            }
        },
    },
}
</script>

<style scoped>

</style>