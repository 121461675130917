<template>
    <v-card
            max-height="650"
    >
        <v-dialog
            max-width="600"
            v-model="shouldShowSupportDetail"
            @close="shouldShowSupportDetail = false"
        >
            <PanelSupportDetail
                v-if="shouldShowSupportDetail"
                :payload="this.payload"
                :pService="pService"
                :selected-support-detail="this.selectedSupport"
                                />
        </v-dialog>
        <v-row class="ml-1 pt-4">
            <div class="font-weight-black">SUPPORTS</div>
        </v-row>
        <v-row class="ml-1 pt-4"
               v-for="(rows, row) in kpiSupportList"
               :key="row"
               dense no-gutters
        >
            <v-col
                    v-for="(item, i) in rows"
                    :key="i"
                    @click="didClickOnSupport(item)"
            >
                <v-badge :color="getUnlockTextColor(item)"
                         offset-x="13"
                         offset-y="60"
                         :content="getUnlockLevel(item)"
                >
                    <v-avatar start>
                        <v-img
                                :src="Tools.renderLocalPicture('supports/'+item.icon)"
                                :gradient="getUnlockAvatarGradient(item)"
                        >
                        </v-img>
                    </v-avatar>
                    <v-badge
                            v-if="item.isUnlock"
                            color="#FF8C00"
                            offset-x="20"
                            offset-y="-10"
                            :content="getSupportLevel(item)"
                    >
                    </v-badge>
                    <v-badge
                            :color="getSupportColor(item)"
                            offset-x="50"
                            offset-y="32"
                            :content="getSupportElement(item)"
                    >
                    </v-badge>
                    <v-badge
                            v-if="isSelectedSupport(item)"
                            color="#808080"
                            offset-x="50"
                            offset-y="-10"
                            content="✔"
                    >
                    </v-badge>
                </v-badge>
            </v-col>
        </v-row>
        <v-row class="ml-1 pt-4">
            <v-col>
                <v-row class="pa-0">
                    <div class="font-weight-black"> TEAM SKILLS BONUS</div>
                </v-row>
                <v-row class="ml-0 pt-0">
                    <v-col>
                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                <tr>
                                    <th class="text-left">
                                        Type
                                    </th>
                                    <th class="text-left">
                                        &Sigma;Bonus
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>TAP_DAMAGE</td>
                                    <td>{{ kpiSkillTapDmg }}</td>
                                </tr>
                                <tr
                                >
                                    <td>SUPPORT_DAMAGE</td>
                                    <td>{{ kpiSkillSupportDmg }}</td>
                                </tr>
                                <tr
                                >
                                    <td>ALL_DAMAGE</td>
                                    <td>{{ kpiSkillAllDmg }}</td>
                                </tr>
                                <tr
                                >
                                    <td>CAT BONUS</td>
                                    <td>{{ kpiSkillCatBonus }}</td>
                                </tr>
                                <tr
                                >
                                    <td>CRITICAL CHANCE</td>
                                    <td>{{ kpiSkillCriticalChance }}</td>
                                </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                    <v-col>
                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                <tr>
                                    <th class="text-left">
                                        Type
                                    </th>
                                    <th class="text-left">
                                        &Sigma;Bonus
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>MANA BONUS</td>
                                    <td>{{ kpiSkillManaBonus }}</td>
                                </tr>
                                <tr
                                >
                                    <td>CRITICAL DAMAGE</td>
                                    <td>{{ kpiSkillCriticalDmg }}</td>
                                </tr>
                                <tr
                                >
                                    <td>GOLD DROP</td>
                                    <td>{{ kpiSkillGoldBonus }}</td>
                                </tr>
                                <tr
                                >
                                    <td>TAP DAMAGE TOTAL DPS</td>
                                    <td>{{ kpiSkillTapDmgFromTotalDps }}</td>
                                </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import Tools from "../../../utils/Tools";
import PanelSupportDetail from "@/kpi-editor/modules/components/PanelSupportDetail.vue";
import ElementTypeAndName from "../../constants/ElementTypeAndName";
import HeroAndColorType from "../../constants/HeroAndColorType";

export default {
    name: "GroupTabSupport",
    components: {PanelSupportDetail},
    props: ['payload', 'pService'],
    data() {
        return {
            Tools: Tools,
            shouldShowSupportDetail: false,
            selectedSupport: null
        }
    },
    computed: {
        kpiSupportList() {
            const {kpiSupportList} = this.payload;
            return kpiSupportList;
        },
        kpiSkillGoldBonus() {
            const {kpiSupportSkills: {skillGoldBonus}} = this.payload;
            return skillGoldBonus;
        },
        kpiSkillTapDmg() {
            const {kpiSupportSkills: {skillTapDmg}} = this.payload;
            return skillTapDmg;
        },
        kpiSkillSupportDmg() {
            const {kpiSupportSkills: {skillSupportDmg}} = this.payload;
            return skillSupportDmg;
        },
        kpiSkillAllDmg() {
            const {kpiSupportSkills: {skillAllDmg}} = this.payload;
            return skillAllDmg;
        },
        kpiSkillCatBonus() {
            const {kpiSupportSkills: {skillCatBonus}} = this.payload;
            return skillCatBonus;
        },
        kpiSkillCriticalChance() {
            const {kpiSupportSkills: {skillCriticalChance}} = this.payload;
            return skillCriticalChance;
        },
        kpiSkillManaBonus() {
            const {kpiSupportSkills: {skillManaBonus}} = this.payload;
            return skillManaBonus;
        },
        kpiSkillCriticalDmg() {
            const {kpiSupportSkills: {skillCriticalDmg}} = this.payload;
            return skillCriticalDmg;
        },
        kpiSkillTapDmgFromTotalDps() {
            const {kpiSupportSkills: {skillTapDmgFromTotalDps}} = this.payload;
            return skillTapDmgFromTotalDps;
        },
        getTeamBattleInfo(){
            const {teamBattleInfo} = this.payload;
            return teamBattleInfo;
        },
    },
    methods: {
        didClickOnSupport(item) {
            if(item.isUnlock) {
                this.selectedSupport = item;
                this.shouldShowSupportDetail = true;
            }
        },
        getUnlockTextColor(item){
            return item.isUnlock ? 'green' : 'rgb(139,0,0)';
        },
        getUnlockAvatarGradient(item){
            //return item.isUnlock ? "" : "to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)";

            if(item.isUnlock){
                return "";
                /*no filter
                let supportsData = this.getTeamBattleInfo.supportsData;
                if(supportsData.find(s => s.m_iID === item.m_iID)){
                    return "to top right, rgba(0,255,255,.3), rgba(255,255,255,.4)";
                } else {
                    return "" ;
                }*/
            } else {
                return "to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)";
            }
        },
        isSelectedSupport(item){
            if(item.isUnlock){
                let supportsData = this.getTeamBattleInfo.supportsData;
                if(supportsData.find(s => s.m_iID === item.m_iID)){
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        },
        getSupportLevel(item){
            return item.possibleLevel;
        },
        getSupportElement(item){
           return ElementTypeAndName[item.data.elementType];
        },
        getSupportColor(item){
            return HeroAndColorType[item.data.m_iFruitType];
        },
        getUnlockLevel(item){
            if(item.data.m_SupportStandType === 2){
                return "flying";
            } else {
                return item.levelUnlock;
            }
        },
    }
}
</script>

<style scoped>

</style>