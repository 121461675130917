<template>
    <div>
        TTKG : <strong> {{ timeToKillGhostReadable }}</strong> <br/>
        TTKNB : <strong>{{ timeToKillBossReadable }}</strong> <br/>
        TTKZB<small>({{ kpiLevelThatZoneBossAppear }})</small> : <strong>{{ timeToKillZoneBossReadable }}</strong> <br/>
        <div v-if="isItPossible">
            SPRINT NEXT LEVEL: <strong>possible</strong><br/>
            <small class="ml-5">=> Leve reached :</small> <strong>From {{gameLevel}} to {{ sprintLevelReached }}</strong> <br/>
            <small class="ml-5">=> Gap of level :</small> <strong>{{ sprintNumLevel }}</strong> <br/>
            <small class="ml-5">=> TTKB reached :</small> <strong>{{ sprintTtkb }}</strong> <br/>
            <small class="ml-5">=> TTKG reached :</small> <strong>{{ sprintTtkg }}</strong> <br/>
        </div>
        <div v-else>
            SPRINT NEXT LEVEL: <strong>Impossible</strong><br/>
            <small class="ml-5">=> Leve reached :</small> -- <br/>
            <small class="ml-5">=> Gap of level :</small> -- <br/>
            <small class="ml-5">=> TTKB reached :</small> -- <br/>
            <small class="ml-5">=> TTKG reached :</small> -- <br/>
        </div>
        NTL : <strong>{{ heroNextLevel }} </strong><br/>
        $GAP <small>({{heroAndNextLevelInfo}})</small> : <strong> {{ totalCostHeroNextLevel }} </strong>
        <br/>
        FT
        <small>({{ gameLevel }})</small>
        : <strong> {{ kpiFarmingRequiredToLevelUp }} </strong><br/>
<!--        BossName: <strong> {{ bossName }} </strong><br/>-->
        Difficulty<small>({{ bossType }})</small>: <strong> {{ gameDifficultyStatus }} </strong>
    </div>
</template>

<script>
import SMPNum from "@/SMPNum";

export default {
    name: "GroupTimeToKill",
    props: ['payload'],

    computed: {

        gameLevel () {
            const {kpiGameLevel} = this.payload;
            return kpiGameLevel;
        },

        heroNextLevel () {
            const {kpiHeroNextLevelInfo: {heroNextLevel}} = this.payload;
            return heroNextLevel;
        },

        kpiFarmingRequiredToLevelUp() {
            const {kpiFarmingTargetRequiredToUpdateHeroToNextLevel} = this.payload;
            return kpiFarmingTargetRequiredToUpdateHeroToNextLevel.ToReadableAlphabetV2();
        },
        heroAndNextLevelInfo(){
            const {kpiHeroNextLevelInfo: {oneHeroCostNextLevel, heroCounterNextLevelUp}} = this.payload;
            return `${oneHeroCostNextLevel.ToReadableAlphabetV2()} x ${heroCounterNextLevelUp}`;
        },
        //===================================================
        costHeroNextLevel() {
            const {kpiHeroNextLevelInfo: {oneHeroCostNextLevel}} = this.payload;
            return oneHeroCostNextLevel.ToReadableAlphabetV2();
        },
        totalCostHeroNextLevel() {
            const {kpiHeroNextLevelInfo: {totalCostHeroNextLevel}} = this.payload;
            return totalCostHeroNextLevel.ToReadableAlphabetV2();
        },
        //===================================================
        sprintLevelReached(){
            const {kpiSprintLevelInfo: {sprintMaxLevel}} = this.payload;
            return sprintMaxLevel;
        },
        sprintTtkb(){
            const {kpiSprintLevelInfo: {totalTTKB}} = this.payload;
            return SMPNum.ToReadableTimeValue(totalTTKB);
        },
        sprintTtkg(){
            const {kpiSprintLevelInfo: {totalTTKG}} = this.payload;
            return SMPNum.ToReadableTimeValue(totalTTKG);
        },
        sprintNumLevel(){
            const {kpiSprintLevelInfo: {sprintLevelCounter}} = this.payload;
            return sprintLevelCounter;
        },
        isItPossible(){
            const {kpiSprintLevelInfo: {sprintLevelCounter}} = this.payload;
            return sprintLevelCounter > 0;
        },
        kpiSprintLevel() {
            const {kpiSprintLevelInfo: {gameLevel, time, sprintLevelCounter}} = this.payload;
            let label = 'Can reach level' + gameLevel + '(TTKB: ' + SMPNum.ToReadableTimeValue(time) + ')';
            if (sprintLevelCounter === 0) {
                label = 'Impossible';
            }
            return label;
        },
        //===================================================
        timeToKillBossReadable() {
            const {kpiTimeToKillBoss} = this.payload;
            return SMPNum.ToReadableTimeValue(kpiTimeToKillBoss);
        },
        timeToKillZoneBossReadable() {
            const {kpiTimeToKillZoneBoss} = this.payload;
            return SMPNum.ToReadableTimeValue(kpiTimeToKillZoneBoss);
        },
        //===================================================
        timeToKillGhostReadable() {
            const {kpiTimeToKillGhost} = this.payload;
            return SMPNum.ToReadableTimeValue(kpiTimeToKillGhost);
            // return timeToKill;
        },
        kpiLevelThatZoneBossAppear() {
            const {kpiLevelThatZoneBossAppear} = this.payload;
            return kpiLevelThatZoneBossAppear;
        },

        bossName(){
            const {bossInfo} = this.payload;
            return bossInfo.name;
        },

        bossType(){
            const {bossInfo} = this.payload;
            return bossInfo.kpiBossType;
        },

        gameDifficultyStatus(){
            const {kpiTimeToKillBoss, kpiTimeToKillZoneBoss, kpiHeroPossibleSurvive, kpiHeroPossibleSurviveZoneBoss} = this.payload;

            let ttkb = 0;
            let ttsv = 0;
            let maxValue = 1.7976931348623157E+308;
            if(this.bossType === 'NormalBoss'){
                if(!kpiTimeToKillBoss.IsDoubleInifinity()){
                    ttkb = kpiTimeToKillBoss.ToDoubleIfPossible();
                } else{
                    ttkb = maxValue;
                }
                if(!kpiHeroPossibleSurvive.IsDoubleInifinity()){
                    ttsv = kpiHeroPossibleSurvive.ToDoubleIfPossible();
                } else {
                    ttsv = maxValue;
                }
            } else {
                if(!kpiTimeToKillZoneBoss.IsDoubleInifinity()){
                    ttkb = kpiTimeToKillZoneBoss.ToDoubleIfPossible();
                } else{
                    ttkb = maxValue;
                }
                if(!kpiHeroPossibleSurviveZoneBoss.IsDoubleInifinity()){
                    ttsv = kpiHeroPossibleSurviveZoneBoss.ToDoubleIfPossible();
                } else {
                    ttsv = maxValue;
                }
            }

            //check survive or time to kill boss difficulty
            if(ttsv <= 1 || ttkb >= 200){
                return 'Impossible';
            } else if(ttsv <= 2 || ttkb >= 60){
                return 'Very Hard'
            } else if(ttsv <= 4 || ttkb >= 40){
                return 'Hard';
            } else if(ttsv <= 6 || ttkb >= 30){
                return 'Normal';
            } else if(ttsv <= 10 || ttkb >= 20){
                return 'Easy';
            } else {
                return 'Very Easy';
            }


            /*old rule
            //define difficulty base on ttkb difficulty
            let svDif = 0;
            if(ttsv <= 1){
                //Impossible
                svDif = 200;
            } else if(ttsv <= 2){
                //Very Hard
                svDif = 60;
            } else if(ttsv <= 4){
                //Hard
                svDif = 40;
            } else if(ttsv <= 6){
                //Normal
                svDif = 30;
            } else if(ttsv <= 10){
                //Easy
                svDif = 20;
            } else {//if(ttsv <= 15){
                //Very Easy
                svDif = 0;
            }

            let difficulty = (ttkb + svDif) / 2; //medium of ttkb and ttsv(apply value base on ttkb)

            let text = '';
            if (difficulty >= 200){
                text = "Impossible";
            } else if (difficulty >= 60) {
                text = "Very Hard Boss";
            } else if (difficulty >= 40) {
                text = "Hard Boss";
            } else if (difficulty >= 30) {
                text = "Normal Boss";
            } else if (difficulty >= 20) {
                text = "Easy Boss";
            } else if (difficulty >= 0.0) {
                text = "Very Easy Boss";
            }
            //text += '{'+difficulty.toFixed(2)+'}';
            return text;*/
        }
    }
}
</script>

<style scoped>

</style>