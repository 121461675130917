<template>
    <div>
        HeroHP : <strong> {{ kpiHeroHp }} </strong> <small>(+{{kpiHeroHpBonus}})</small> <br/>
        GenHP : <strong> {{ kpiGeneratedHeroHpPerSecond }} </strong> <br/>
        ===============<br/>
        Bdps : <strong> {{ kpiBossDps }} </strong> <br/>
        GenHP-NB : <strong> {{ kpiExpectedGeneratedHpWithBoss }} </strong> <br/> 
        Time-NB : <strong> {{ kpiTimeHeroPossibleDieWithNormalBoss }}</strong> <br/>
        Survive : <strong> {{ kpiHeroPossibleSurvive }}</strong> <br/>
        ===============<br/>
        ZBdps : <strong> {{ kpiZoneBossDps }} </strong> <br/>
        GenHP-ZB : <strong> {{ kpiExpectedGeneratedHpWithZoneBoss }} </strong> <br/> 
        Time-ZB : <strong> {{ kpiTimeHeroPossibleDieWithZoneBoss }}</strong> <br/>
        Survive : <strong> {{ kpiHeroPossibleSurviveZoneBoss }}</strong> <br/>

    </div>
</template>

<script>
import SMPNum from "@/SMPNum";

export default {
    name: "GroupFailPossibility",
    props: ['payload'],

    computed: {
        kpiHeroHp() {
            const {kpiHeroHp} = this.payload;
            return kpiHeroHp.ToReadableAlphabetV2();
        },
        kpiTimeHeroPossibleDieWithNormalBoss() {
            const {kpiTimeHeroPossibleDieWithNormalBoss} = this.payload;
            return SMPNum.ToReadableTimeValue(kpiTimeHeroPossibleDieWithNormalBoss);
        },
        kpiTimeHeroPossibleDieWithZoneBoss() {
            const {kpiTimeHeroPossibleDieWithZoneBoss} = this.payload;
            return SMPNum.ToReadableTimeValue(kpiTimeHeroPossibleDieWithZoneBoss);
        },
        kpiBossDps() {
            const {kpiBossDps} = this.payload;
            return kpiBossDps.ToReadableAlphabetV2();
        },
        kpiZoneBossDps() {
            const {kpiZoneBossDps} = this.payload;
            return kpiZoneBossDps.ToReadableAlphabetV2();
        },
        kpiGeneratedHeroHpPerSecond (){
            const {kpiGeneratedHeroHpPerSecond} = this.payload;
            return kpiGeneratedHeroHpPerSecond.ToReadableAlphabetV2();
        },
        kpiExpectedGeneratedHpWithBoss (){
            const {kpiExpectedGeneratedHpWithBoss} = this.payload;
            return kpiExpectedGeneratedHpWithBoss.ToReadableAlphabetV2();
        },
        kpiExpectedGeneratedHpWithZoneBoss (){
            const {kpiExpectedGeneratedHpWithZoneBoss} = this.payload;
            return kpiExpectedGeneratedHpWithZoneBoss.ToReadableAlphabetV2();
        },
        kpiHeroPossibleSurvive (){
            const {kpiHeroPossibleSurvive} = this.payload;
            return SMPNum.ToReadableTimeValue(kpiHeroPossibleSurvive);
        },
        kpiHeroPossibleSurviveZoneBoss (){
            const {kpiHeroPossibleSurviveZoneBoss} = this.payload;
            return SMPNum.ToReadableTimeValue(kpiHeroPossibleSurviveZoneBoss);
        },
        kpiHeroHpBonus(){
            const {heroHpCapSessionFromSupport} = this.payload;
            return heroHpCapSessionFromSupport.bonusHp.ToReadableAlphabetV2();
        },
        //kpiHeroPossibleSurviveZoneBoss
        //kpiHeroPossibleSurvive

        //kpiExpectedGeneratedHpWithBoss
    },
}
</script>

<style scoped>

</style>