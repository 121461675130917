<template>
    <v-card
        class="mt-2"
        elevation="0"
        outlined
    >
        <v-subheader><strong>SHARING GOLD BETWEEN HEROES AND SUPPORTS ({{totalGold.ToReadableAlphabetV2()}})</strong></v-subheader>
        <v-subheader>Allocate to Heroes ({{ goldAllocatedToHeroes.ToReadableAlphabetV2() }} of {{ totalGold.ToReadableAlphabetV2() }})</v-subheader>
        <v-card-text class="py-0">
            <v-slider
                dense
                v-model="percentageAllocatedToHeroes"
                @change="onChangedPercentageAllocatedToHeroes"
                track-color="grey"
                thumb-label
                min="0"
                max="100"
            >
                <template v-slot:append>
                    <v-text-field
                        v-model="percentageAllocatedToHeroes"
                        @input="onInputPercentageAllocatedToHeroes"
                        style="width: 50px"
                        dense
                        type="number"
                        min="0"
                        max="100"
                    ></v-text-field>
                </template>
            </v-slider>
        </v-card-text>

        <v-subheader>Allocate to Supports ({{ goldAllocatedToSupports.ToReadableAlphabetV2() }} of {{ totalGold.ToReadableAlphabetV2() }})</v-subheader>
        <v-card-text class="py-0">
            <v-slider
                dense
                v-model="percentageAllocatedToSupports"
                @change="onChangedPercentageAllocatedToSupports"
                track-color="grey"
                thumb-label
                min="0"
                max="100"
            >
                <template v-slot:append>
                    <v-text-field
                        v-model="percentageAllocatedToSupports"
                        @input="onInputPercentageAllocatedToSupports"
                        style="width: 50px"
                        dense
                        type="number"
                        min="0"
                        max="100"
                    ></v-text-field>
                </template>
            </v-slider>
        </v-card-text>

    </v-card>
</template>

<script>
import SMPNum from "@/SMPNum";

export default {
    name: "SettingGoldBetweenHeroesAndSupports",
    props: ["totalGold", "defaultInputSet"],
    data() {
        return {
            percentageAllocatedToHeroes: 50,
            percentageAllocatedToSupports: 50
        }
    },
    computed: {
        goldAllocatedToHeroes(){
            const totalGold = this.totalGold;
            return SMPNum.multSmpNum(totalGold, SMPNum.fromNum(this.percentageAllocatedToHeroes / 100));
        },
        goldAllocatedToSupports(){
            const totalGold = this.totalGold;
            return SMPNum.multSmpNum(totalGold, SMPNum.fromNum(this.percentageAllocatedToSupports / 100));
        },
    },

    watch: {
        totalGold: function(newVal, oldVal) { // watch it
            if (!(newVal._power === oldVal._power)){
                this.emitState();
            }
        }
    },

    created() {
        this.percentageAllocatedToSupports = this.defaultInputSet.ratioAllocatedToSupport ?? 0;
        this.percentageAllocatedToHeroes = 100 - this.percentageAllocatedToSupports;
    },

    methods: {
        onChangedPercentageAllocatedToHeroes() {
            if (this.percentageAllocatedToHeroes > 100) {
                this.percentageAllocatedToHeroes = 100;
            }
            this.percentageAllocatedToSupports = 100 - this.percentageAllocatedToHeroes;
            this.emitState();
        },
        onChangedPercentageAllocatedToSupports() {
            if (this.percentageAllocatedToSupports > 100){
                this.percentageAllocatedToSupports = 100;
            }
            this.percentageAllocatedToHeroes = 100 - this.percentageAllocatedToSupports;
            this.emitState();
        },
        onInputPercentageAllocatedToHeroes() {
            if (this.percentageAllocatedToHeroes > 100) {
                this.percentageAllocatedToHeroes = 100;
            }
            this.percentageAllocatedToSupports = 100 - this.percentageAllocatedToHeroes;
            this.emitState();
        },
        onInputPercentageAllocatedToSupports() {
            if (this.percentageAllocatedToSupports > 100){
                this.percentageAllocatedToSupports = 100;
            }
            this.percentageAllocatedToHeroes = 100 - this.percentageAllocatedToSupports;
            this.emitState();
        },
        emitState(){
            const outPayload = {
                percentageAllocatedToSupports: this.percentageAllocatedToSupports,
                percentageAllocatedToHeroes: this.percentageAllocatedToHeroes,
                goldAllocatedToHeroes: this.goldAllocatedToHeroes,
                goldAllocatedToSupports: this.goldAllocatedToSupports
            }

            this.$emit("onUpdateSettingGoldBetweenHeroesAndSupports", outPayload);
        }
    }
}
</script>

<style scoped>

</style>