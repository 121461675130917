import SMPNum from "@/SMPNum";

class Chain1 {
     constructor(service, successor) {
          this.service = service;
          this.successor = successor;
     }

     handleRequest(payload){
          //get only required param
          const {gameLevel, currentHeroLevel, bossInfo, heroCount, teamBattleInfo, heroList} = payload;

          //computing
          let levelThatZoneBossAppear = gameLevel;
          while (!this.service.isZoneBoss(levelThatZoneBossAppear)){
               levelThatZoneBossAppear++;
          }

          // const heroList = this.service.getDisplayHeroList(heroCount, heroLevel);
          //
          // //heroLevel which selected
          // let currentHeroLevel = this.service.getCurrentHeroLevel();

          // this.service.setCurrentHeroStore(teamBattleInfo);
          // this.service.setCurrentBossStore(bossInfo);
          // this.service.setSelectPetActive(teamBattleInfo.petData);

          let heroNextLevel = this.service.getNextLevelHeroCanUp(currentHeroLevel, heroList);
          let oneHeroCostNextLevel = this.service.costHero(heroNextLevel);
          let heroCounterNextLevelUp = this.service.getHeroCounterCanLevelUp(heroNextLevel, heroList);
          let totalCostHeroNextLevel = SMPNum.multSmpNum(oneHeroCostNextLevel, new SMPNum(heroCounterNextLevelUp));
          const heroNextLevelInfo = {
               heroNextLevel: heroNextLevel,
               oneHeroCostNextLevel: oneHeroCostNextLevel,
               heroCounterNextLevelUp: heroCounterNextLevelUp,
               totalCostHeroNextLevel: totalCostHeroNextLevel
          };

          const goldDropCurrentLevel = this.service.goldDrop(gameLevel);

          const waveValue = Math.floor(gameLevel / 450) + 10;
          const ghostHp = this.service.ghostHp(gameLevel);
          const normalBossHp = this.service.normalBossHp(gameLevel);

          const zoneBossHp = this.service.zoneBossHp(levelThatZoneBossAppear);
          const farmingTargetRequiredToUpdateHeroToNextLevel = SMPNum.divSmpNum(totalCostHeroNextLevel, goldDropCurrentLevel);

          //total enemies on gameLevel, mean that current level 10, the enemies defeat should sum from level 1 to 9
          //=> so we sum enemies of gameLevel - 1
          const totalGhost = this.service.getTotalGhost(gameLevel - 1);
          const totalBoss = this.service.getTotalBoss(gameLevel - 1);
          const totalZoneBoss = this.service.getTotalZoneBoss(gameLevel);
          const totalEnemies = this.service.getTotalEnemies(gameLevel - 1);

          //diamond is 10 level drop, and after that game level, so no need minus 1
          const diamondBossCollect = this.service.getDiamondBossCollect(gameLevel);

          //after won gameLevel, mean that current level 10, the gold won should sum from level 1 to 9
          //=> so we sum gold of gameLevel - 1
          const cumulatedGoldWonAfterReachingGhostLevel = this.service.cumulatedGoldWonAfterReachingGhostLevel(gameLevel - 1);
          const cumulatedGoldWonAfterReachingBossLevel = this.service.cumulatedGoldWonAfterReachingBossLevel(gameLevel - 1);
          const cumulatedGoldWonAfterReachingZoneBossLevel = this.service.cumulatedGoldWonAfterReachingZoneBossLevel(gameLevel - 1);
          const cumulatedGoldWonAfterReachingTotalEnemies = this.service.cumulatedGoldWonAfterAllEnemiesReachingLevel(gameLevel - 1);

          const petCount = this.service.kpiPetCount(gameLevel);
          const {petDisplays, petList} = this.service.getPetList(petCount);

          //prepare new payload
          let newPayload = {
               goldDropCurrentLevel,
               levelThatZoneBossAppear,
               waveValue,
               ghostHp,
               normalBossHp,
               zoneBossHp,
               farmingTargetRequiredToUpdateHeroToNextLevel,
               totalGhost,
               totalBoss,
               totalZoneBoss,
               totalEnemies,
               diamondBossCollect,
               cumulatedGoldWonAfterReachingGhostLevel,
               cumulatedGoldWonAfterReachingBossLevel,
               cumulatedGoldWonAfterReachingZoneBossLevel,
               cumulatedGoldWonAfterReachingTotalEnemies,
               petCount,
               petList,
               petDisplays,
               bossInfo,
               teamBattleInfo,
               heroCount,
               currentHeroLevel,
               heroNextLevelInfo
          };

          //merge old and new to return payload
          let returnPayload = Object.assign({}, payload, newPayload);
          //forward to another successor or return
          if (this.successor) {
              return this.successor.handleRequest(returnPayload);
          } else {
               return returnPayload;
          }
     }
}

export default Chain1;