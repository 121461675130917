<template>
    <v-card
            elevation="5"
            min-width="700"
            :color="this.getItemBGColor"
    >
        <v-row justify="center"
               class="spacing-playground pa-2"
        >
            <v-col cols="2">
                <v-img
                        :src="getBoarderIcon()"
                        max-width="90"
                >
                    <v-row class="fill-height">
                        <v-row justify="center">
                            <v-card-title>
                                <v-img
                                        :src="getItemIcon()"
                                        max-width="60px"
                                        max-height="60px"
                                        dark
                                >
                                </v-img>
                            </v-card-title>
                        </v-row>
                    </v-row>
                </v-img>
            </v-col>
            <v-col cols="8">
                <v-row class="pt-1 mb-sm-n1">
                    <v-badge
                            color="green"
                            offset-x="-5"
                            offset-y="15"
                            :content="getItemLevel"
                    >
                        {{getItemTitleName}}
                    </v-badge>
                </v-row>
                <v-row class="pl-3" v-for="item in levelCardData.behaviorDesc" v-bind:key="item.desc">
                    <div v-html="item.desc"></div>
                </v-row>
                <v-row class="pl-3 pt-sm-n1">
                    {{getLifeDescription()}}
                </v-row>
            </v-col>
            <v-col cols="2">
                <v-row>
                    {{getCost()}}
                </v-row>
                <v-row v-show="!this.pIsPurchased">
                    <v-card
                            elevation="2"
                            min-width="100"
                            max-width="100"
                            max-height="50"
                            :class="getButtonStyleClass" :color="getColorButtonPrice"
                            @click="clickPurchase"
                    >
                        Buy Now
                    </v-card>
                </v-row>
                <v-row v-show="this.pIsPurchased">
                    <v-card
                            elevation="1"
                            min-width="80"
                            max-height="50"
                            class="pl-2"
                    >
                        Bought
                    </v-card>
                </v-row>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
    import ItemColorConstants from "@/item-editor/constants/ItemColorConstants";

    export default {
        name: "AutomateCard.vue",
        props: ["pItemData", "pLevelCardData",'pIsPurchased'],
        data() {
            return {
                itemGroupData: null,
                itemData: null,
                siblingConfig: null,
                isSiblingClicked: false,
            }
        },
        created() {
            this.itemData = this.pItemData;
            this.levelCardData = this.pLevelCardData;
        },
        methods: {
            getBoarderColor() {
                return ItemColorConstants.colors[this.itemData.color];
            },
            getBoarderIcon() {
                let color = this.itemData.color;
                if (!this.itemData.color) {
                    color = 'blue';
                }
                let iconName = ItemColorConstants.iconColorNames[color];
                return require('@/item-editor/assets/icons/itemboarders/' + iconName);
            },
            getItemIcon() {
                let iconName = this.itemData.iconName + ".png";
                let result = null;
                try {
                    result = require('@/item-editor/assets/weapons/textures/' + iconName);
                } catch(e){
                    console.log("Not found image name : " + iconName);
                    result = require('@/item-editor/assets/weapons/no-image.png');
                }

                return result;
                
            },
            getFirstAppear(){
                let levelStart = this.itemData.gameLevel.levelStart;
                return "Appear GLv.:"+ levelStart;
            },
            getEndLevel(){
                let levelStart = this.itemData.gameLevel.levelEnd;
                return "Finish GLv.:"+ levelStart;
            },
            getLifeDescription(){
                let desc = this.itemData.lifeTime.bean.text;
                return "Life: "+ desc;
            },
            getCost(){
                if (this.levelCardData.costInGold){
                    return this.levelCardData.costInGold.ToReadableAlphabetV2() + " coins";
                }
                else {
                    return "unknown"
                }
            },
            clickPurchase(){
                this.$emit('onClickPurchase', this.itemData, this.pLevelCardData);
            },
        },
        computed: {
            getItemTitleName() {
                let title = this.itemData.title;
                if (title !== undefined) {
                    return title.en;
                }
                return "N/A";
            },
            getItemLevel(){
                return 'lvl. '+ this.itemData.level;
            },
            getColorButtonPrice(){
                if (this.pLevelCardData.costColor){
                    return this.pLevelCardData.costColor;
                }
                return '#F020D8';
            },
            getButtonStyleClass(){
                if (this.pLevelCardData.costColor === '#B1B1B1'){
                    return 'pl-4';
                }
                return 'pl-4 white--text';
            },
            getItemBGColor(){
                // if (this.itemData && this.itemData.isUseLess){
                //
                //     return '#555555';
                // }
                return '#FFFFFF';
            }
        },
    }
</script>

<style scoped>
    .v-list--two-line .v-list-item,
    .v-list-item--two-line {
        min-height: 30px;
        height:30px;
    }
</style>