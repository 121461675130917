const MAX_LEVEL_ON_STAGE = 10;
const maxZoneOnBossConfig = 102;

class EnemyKpiUtils {
    constructor (listEnemysData, listEnemyZoneData) {
        this.listEnemysData = listEnemysData;
        this.listEnemyZoneData = listEnemyZoneData;
    }

    getBossInfoByGameLevel(gameLevel){
        let bossId = this.GetBossId(gameLevel);
        let bossInfo = this.listEnemysData.find(boss => boss.id === bossId);
        return bossInfo;
    }

    GetBossId(gameLevel){
        let zoneID = this.GetZoneByLevel(gameLevel);
        let bossIndex = this.GetBossIndexByLevel(gameLevel);
        let bossIdList = this.GetListBossIDinZone(zoneID);

        let bossId = bossIdList[0];
        if (bossIndex < bossIdList.length)
        {
            bossId = bossIdList[bossIndex];
        }

        return bossId;
    }

    GetListBossIDinZone(zone_id){
        let timeOfBigger = 0;
        if (zone_id >= maxZoneOnBossConfig){
            timeOfBigger = Math.floor(zone_id / maxZoneOnBossConfig);
            zone_id = zone_id - (maxZoneOnBossConfig * timeOfBigger);
        }

        if (zone_id >= this.listEnemyZoneData.length || zone_id < 0){
            return null;
        }

        let bossOrder = [];
        if(zone_id === 0 && timeOfBigger > 0)
        {
            bossOrder.push(this.listEnemyZoneData[maxZoneOnBossConfig - 1].bossOrdering[9]);
        }

        this.listEnemyZoneData[zone_id].bossOrdering.forEach(boss => {
            bossOrder.push(boss);
        });


        return bossOrder;
    }

    GetBossIndexByLevel(level){
        let z = this.GetZoneByLevel(level);
        if(level < MAX_LEVEL_ON_STAGE){
            level -= 1;
        }
        let b = level - (z * MAX_LEVEL_ON_STAGE);
        return b;
    }

    GetZoneByLevel(level) {
        return Math.floor(level / MAX_LEVEL_ON_STAGE);
    }
}

export default EnemyKpiUtils;