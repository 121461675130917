<template>
<v-card elevation="0">
    <v-card-title>SKILL SETTINGS</v-card-title>
    <v-card
            min-width="550"
            class="pa-4 my-3"
            elevation="0"
            outlined
    >
        <div class="font-weight-black">Hero skills</div>
        <v-row>
            <v-col class="pt-2">
                <v-checkbox
                        v-model="isUseHeroSkillGold"
                        label="Include hero skill gold bonus"
                        @change="onHeroSkillChange"
                        class="pt-0 pl-0"
                ></v-checkbox>
                <v-card max-width="300"
                        elevation="0">
                    <v-text-field
                            label="Use hero gold skill percentage"
                            v-model="percentControlHeroGoldSkill"
                            @input="onHeroSkillChange"
                            type="number"
                            :min="0"
                            :max="100"
                            :disabled="!isUseHeroSkillGold"
                    />
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="pt-0">
                <v-checkbox
                        v-model="isUseHeroSkillDmg"
                        label="Include hero skill dmg bonus"
                        @change="onHeroSkillChange"
                        class="pt-0 pl-0"
                ></v-checkbox>
                <v-checkbox
                        v-model="isUseThunderAttackSkill"
                        label="+Thunder attack skill dmg"
                        @change="onHeroSkillChange"
                        class="pt-0 pl-0"
                        :disabled="!isUseHeroSkillDmg"
                ></v-checkbox>
                <v-checkbox
                        v-model="isUsePetAllianceSkill"
                        label="+Pets Alliance skill bonus and dmg"
                        @change="onHeroSkillChange"
                        class="pt-0 pl-0"
                        :disabled="!isUseHeroSkillDmg"
                ></v-checkbox>
                <v-card max-width="300"
                        elevation="0">
                    <v-text-field
                            label="Use hero dmg skill percentage"
                            v-model="percentControlHeroDmgSkill"
                            @input="onHeroSkillChange"
                            type="number"
                            :min="0"
                            :max="100"
                            :disabled="!isUseHeroSkillDmg"
                    />
                </v-card>
            </v-col>
        </v-row>
    </v-card>
    <v-card
        min-width="550"
        class="pa-4 my-3"
        elevation="0"
        outlined
    >
        <div class="font-weight-black">Support skills</div>
        <v-row>
            <v-col class="pt-2">
                <v-checkbox
                    v-model="isUseSupportSkillGold"
                    label="Include support skill gold bonus"
                    @change="onSupportSkillGoldSwitching"
                    class="pt-0 pl-0"
                ></v-checkbox>
                <v-card max-width="300"
                        elevation="0">
                    <v-text-field
                        label="Use support gold skill percentage"
                        v-model="percentControlSupportGoldSkill"
                        @input="onPercentControlSupportGoldSkill"
                        type="number"
                        :min="0"
                        :max="100"
                        :disabled="!isUseSupportSkillGold"
                    />
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="pt-0">
                <v-checkbox
                    v-model="isUseSupportSkillDmg"
                    label="Include support skill dmg bonus"
                    @change="changedIsUseSupportSkillDmg"
                    class="pt-0 pl-0"
                ></v-checkbox>
            </v-col>
        </v-row>
    </v-card>
    <v-card
        min-width="550"
        class="pa-4"
        elevation="0"
        outlined
    >
        <div class="font-weight-black">Pet skills</div>
        <v-row>
            <v-col class="pt-2">
                <v-checkbox
                    v-model="isUsePetSkillGold"
                    label="Include pet skill gold bonus"
                    @change="changedIsUsePetSkillGold"
                    class="pt-0 pl-0"
                ></v-checkbox>
                <v-card max-width="300"
                        elevation="0">
                    <v-text-field
                        label="Use pet gold skill percentage"
                        v-model="percentControlPetGoldSkill"
                        @input="changedPercentControlPetGoldSkill"
                        type="number"
                        :min="0"
                        :max="100"
                        :disabled="!isUsePetSkillGold"
                    />
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="pt-0">
                <v-checkbox
                    v-model="isUsePetSkillDmg"
                    label="Include pet skill dmg bonus"
                    @change="changedIsUsePetSkillDmg"
                    class="pt-0 pl-0"
                ></v-checkbox>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="pt-0">
                <v-checkbox
                    v-model="isUsingPet"
                    label="Is using pet"
                    @change="changedIsUsingPet"
                    class="pt-0 pl-0"
                ></v-checkbox>
            </v-col>
        </v-row>
    </v-card>
</v-card>
</template>

<script>
export default {
    name: "PanelSkillConfig",
    props:["payload", "defaultInputSet"],
    created() {
        this.percentControlSupportGoldSkill = this.defaultInputSet.percentControlSupportGoldSkill;
        this.isUseSupportSkillGold = this.defaultInputSet.isUseSupportSkillGold;
        this.isUseSupportSkillDmg = this.defaultInputSet.isUseSupportSkillDmg;
        this.percentControlPetGoldSkill = this.defaultInputSet.percentControlPetGoldSkill;
        this.isUsePetSkillGold = this.defaultInputSet.isUsePetSkillGold;
        this.isUsePetSkillDmg = this.defaultInputSet.isUsePetSkillDmg;
        this.isUsingPet = this.defaultInputSet.isUsingPet;

        this.isUseHeroSkillDmg = this.defaultInputSet.isUseHeroSkillDmg;
        this.isUseHeroSkillGold = this.defaultInputSet.isUseHeroSkillGold;
        this.percentControlHeroDmgSkill = this.defaultInputSet.percentControlHeroDmgSkill;
        this.percentControlHeroGoldSkill = this.defaultInputSet.percentControlHeroGoldSkill;
        this.isUseThunderAttackSkill = this.defaultInputSet.isUseThunderAttackSkill;
        this.isUsePetAllianceSkill = this.defaultInputSet.isUsePetAllianceSkill;
        /*
                percentControlPetGoldSkill: 5,
                percentControlSupportGoldSkill: 5,

                isUsePetSkillGold: true,
                isUsePetSkillDmg: true,
                isUseSupportSkillGold: true,
                isUseSupportSkillDmg: true,
         */
    },
    data() {
        return {
            percentControlSupportGoldSkill: 100,
            percentControlPetGoldSkill: 100,
            isUseSupportSkillGold: true,
            isUseSupportSkillDmg: true,
            isUsePetSkillGold: true,
            isUsePetSkillDmg: true,
            isUsingPet: true,
            isUseHeroSkillGold: true,
            isUseHeroSkillDmg: true,
            percentControlHeroGoldSkill: 100,
            percentControlHeroDmgSkill: 100,
            isUseThunderAttackSkill: true,
            isUsePetAllianceSkill: true,
        }
    },
    methods: {
        onHeroSkillChange() {
            this.emitState();
        },

        onSupportSkillGoldSwitching() {
            this.emitState();
        },
        onPercentControlSupportGoldSkill() {
            this.emitState();
        },
        changedIsUseSupportSkillDmg() {
            this.emitState();
        },
        changedIsUsePetSkillGold() {
            if(this.isUsePetSkillGold){
                this.isUsingPet = true;
            }
            this.emitState();
        },
        changedPercentControlPetGoldSkill() {
            this.emitState();
        },
        changedIsUsePetSkillDmg() {
            if(this.isUsePetSkillDmg){
                this.isUsingPet = true;
            }
            this.emitState();
        },
        changedIsUsingPet() {
            if(!this.isUsingPet){
                this.isUsePetSkillDmg = false;
                this.isUsePetSkillGold = false;
            }
            this.emitState();
        },
        emitState(){
            const outPayload = {
                percentControlSupportGoldSkill: this.percentControlSupportGoldSkill,
                isUseSupportSkillGold: this.isUseSupportSkillGold,
                isUseSupportSkillDmg: this.isUseSupportSkillDmg,
                percentControlPetGoldSkill: this.percentControlPetGoldSkill,
                isUsePetSkillGold: this.isUsePetSkillGold,
                isUsePetSkillDmg: this.isUsePetSkillDmg,
                isUsingPet: this.isUsingPet,

                isUseHeroSkillDmg: this.isUseHeroSkillDmg,
                isUseHeroSkillGold: this.isUseHeroSkillGold,
                percentControlHeroDmgSkill: this.percentControlHeroDmgSkill,
                percentControlHeroGoldSkill: this.percentControlHeroGoldSkill,
                isUseThunderAttackSkill: this.isUseThunderAttackSkill,
                isUsePetAllianceSkill: this.isUsePetAllianceSkill,
            };
            this.$emit("onUpdateSettingSkill", outPayload);
        },

    }
}
</script>

<style scoped>

</style>