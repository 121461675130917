import SMPNum from "@/SMPNum";

class Chain10 {
     constructor(service, successor) {
          this.service = service;
          this.successor = successor;
          this.handleCount = 0;
     }
     handleRequest(payload){
          //get only required param
          const sprintLevelInfo = this.sprintLevel(payload);

          //prepare new payload
          let newPayload = {
               sprintLevelInfo
          }

          //merge old and new to return payload
          let returnPayload = Object.assign({}, payload, newPayload)

          //forward to another successor or return
          this.handleCount += 1;
          // console.log("this.handleCount : " + this.handleCount);
          if (this.successor && this.handleCount < 3) {
               return this.successor.handleRequest(returnPayload);
          } else {
               this.handleCount = 0;
               return returnPayload;
          }
     }
     sprintLevel(params) {

          let {
               gameLevel,
               currentHeroLevel,
               hitPerSecond,
               hitBossPerSecond,
               teamBattleInfo
          } = params;

          let nextLevel = gameLevel;
          let sprintMaxLevel = nextLevel;
          let sprintLevelCounter = 0;

          let loop = 0;
          //let oneHour = new SMPNum(1500);
          let impossibleTime = new SMPNum(200);

          let totalTTKG = this.service.sprintTimeToKillGhostWithoutBonus(nextLevel, currentHeroLevel, hitPerSecond);
          let totalTTKB = this.service.sprintTimeToKillBossWithoutBonus(nextLevel, currentHeroLevel, hitBossPerSecond, teamBattleInfo);
          let timeOneLevelReach = SMPNum.plus(totalTTKG, totalTTKB);
          //console.log('start lv: '+nextLevel+' ttkb: '+totalTTKB.ToReadableAlphabetV2());

          if(SMPNum.greaterThan(impossibleTime, timeOneLevelReach)){
               while (loop < 1000 && SMPNum.greaterThan(impossibleTime, timeOneLevelReach)) {
                    loop++;
                    gameLevel = nextLevel;
                    nextLevel = nextLevel + 1;
                    sprintMaxLevel = nextLevel;
                    sprintLevelCounter++;
                    let timeToKillGhost = this.service.sprintTimeToKillGhostWithoutBonus(nextLevel, currentHeroLevel, hitPerSecond);
                    let timeToKillBoss = this.service.sprintTimeToKillBossWithoutBonus(nextLevel, currentHeroLevel, hitBossPerSecond, teamBattleInfo);
                    timeOneLevelReach = SMPNum.plus(timeToKillGhost, timeToKillBoss);

                    if(SMPNum.greaterThan(impossibleTime, timeOneLevelReach)){
                         totalTTKG = SMPNum.plus(totalTTKG, timeToKillGhost);
                         totalTTKB = SMPNum.plus(totalTTKB, timeToKillBoss);
                         //console.log(' #heroId: '+teamBattleInfo.heroData.m_iID+ ' tOnLVReach: '+timeOneLevelReach.ToReadableAlphabetV2());
                         //console.log(' +lv: '+nextLevel+' +'+timeToKillBoss.ToReadableAlphabetV2()+' = ttkb: '+totalTTKB.ToReadableAlphabetV2());
                    }
               }
          } else {
               totalTTKG = new SMPNum(0);
               totalTTKB = new SMPNum(0);
          }

          return {
               gameLevel,
               totalTTKB,
               totalTTKG,
               sprintMaxLevel,
               sprintLevelCounter
          }
     }
}
export default Chain10;