import PetSkillConstants from "@/constants/PetSkillConstants";

class Chain9 {
     constructor(service, successor) {
          this.service = service;
          this.successor = successor;
     }
     handleRequest(payload){
          //get only required param
          const {gameLevel, heroBaseDps, supportLevel, supportCount, achievementList, diamondBossCollect, ratioAllocatedToPet, petList, cumulatedGoldWon, heroSkillPayload} = payload;

          let achievementResult = this.service.computeDiamondFromAchievements(
              gameLevel, heroBaseDps, supportLevel, supportCount, achievementList, cumulatedGoldWon
          );
          const diamondAchievementCollect = achievementResult.reward;
          const achievementCompleteList = achievementResult.completedData;

          const totalDiamonds = diamondBossCollect + diamondAchievementCollect + this.service.getDiamondStartUp();
          const diamondAvailableForPet = Math.round(totalDiamonds * ratioAllocatedToPet / 100);

          //update pet level
          this.service.definePetLevel(diamondAvailableForPet, petList);
          const petLevel = this.service.kpiPetLevel();

          //update gold bonus from pet
          const percentageGoldFromPet = this.service.getPetBonusSkill(PetSkillConstants.ALL_GOLD, petList, heroSkillPayload);

          //compute pet skills
          const skillPetAvgActiveAllDmg = this.service.getPetAvgActiveBonusSkill(PetSkillConstants.ALL_DAMAGE, petList);
          const skillPetPassiveAllDmg = this.service.getPetPassiveBonusSkill(PetSkillConstants.ALL_DAMAGE, petList);
          const skillPetAllDmg = this.service.getPetPossibleBonusSkill(PetSkillConstants.ALL_DAMAGE, petList);

          const skillPetAvgActiveAllGold = this.service.getPetAvgActiveBonusSkill(PetSkillConstants.ALL_GOLD, petList);
          const skillPetPassiveAllGold = this.service.getPetPassiveBonusSkill(PetSkillConstants.ALL_GOLD, petList);
          const skillPetAllGold = this.service.getPetPossibleBonusSkill(PetSkillConstants.ALL_GOLD, petList);

          const skillPetAvgActiveSupportDmg = this.service.getPetAvgActiveBonusSkill(PetSkillConstants.ALL_SUPPORTER_DAMAGE, petList);
          const skillPetPassiveSupportDmg = this.service.getPetPassiveBonusSkill(PetSkillConstants.ALL_SUPPORTER_DAMAGE, petList);
          const skillPetSupportDmg = this.service.getPetPossibleBonusSkill(PetSkillConstants.ALL_SUPPORTER_DAMAGE, petList);

          const skillPetAvgActiveTapDmg = this.service.getPetAvgActiveBonusSkill(PetSkillConstants.TAP_DAMAGE, petList);
          const skillPetPassiveTapDmg = this.service.getPetPassiveBonusSkill(PetSkillConstants.TAP_DAMAGE, petList);
          const skillPetTapDmg = this.service.getPetPossibleBonusSkill(PetSkillConstants.TAP_DAMAGE, petList);

          const skillCurrentActivePet = this.service.getCurrentPetActiveSkillBonus(heroSkillPayload);

          const petSkills = {
               skillPetAvgActiveAllDmg,
               skillPetPassiveAllDmg,
               skillPetAllDmg,
               skillPetAvgActiveAllGold,
               skillPetPassiveAllGold,
               skillPetAllGold,
               skillPetAvgActiveSupportDmg,
               skillPetPassiveSupportDmg,
               skillPetSupportDmg,
               skillPetAvgActiveTapDmg,
               skillPetPassiveTapDmg,
               skillPetTapDmg,
               skillCurrentActivePet
          }

          //prepare new payload
          let newPayload = {
               diamondAchievementCollect,
               totalDiamonds,
               diamondAvailableForPet,
               petLevel,
               petSkills,
               percentageGoldFromPet,
               achievementCompleteList
          }

          //merge old and new to return payload
          let returnPayload = Object.assign({}, payload, newPayload)

          //forward to another successor or return
          if (this.successor) {
               return this.successor.handleRequest(returnPayload);
          } else {
               return returnPayload;
          }
     }
}

export default Chain9;