export default {
     allSupports: {value: "AllSupports", text: "all-supports"},
     allActiveSupports: {value: "AllActiveSupports", text: "all-active-supports"},
     selectedSupport: {value: "SelectedSupport", text: "selected-support"},
     selectedActiveSupport: {value: "SelectedActiveSupport", text: "selected-active-support"},
     allActivePets: {value: "AllActivePets", text: "all-active-pets"},
     selectedPet: {value: "SelectedPet", text: "selected-pet"},
     selectedHero: {value: "SelectedHero", text: "selected-hero"},
     enemy: {value: "Enemy", text: "enemy"},
     gold: {value: "Gold", text: "gold"},
     menu: {value: "Menu", text: "menu"},
     team: {value: "Team", text: "team"},
}