<template>
    <v-card
            class="mt-2"
            elevation="0"
            outlined
    >
        <v-card-subtitle><strong>MARKET PLACE GOLD</strong></v-card-subtitle>
        <v-row>
            <v-col class="pl-7 pt-0">
                <v-checkbox
                        v-model="isUseAllGoldForMarketPlace"
                        label="Take Σ$G Total for Market place"
                        @change="changedToggleUseChange"
                        class="pt-0 pl-0"
                ></v-checkbox>
            </v-col>

            <v-col class="pt-0">
                <v-checkbox
                        v-model="isNotUseAllGoldForMarketPlace"
                        label="Take $G ending for Market place"
                        @change="changedToggleNotUseChange"
                        class="pt-0 pl-0"
                ></v-checkbox>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
    export default {
        name: "SettingGoldMarketPlace.vue",
        props: ["defaultInputSet"],
        data() {
            return {
                isUseAllGoldForMarketPlace: true,
                isNotUseAllGoldForMarketPlace: false,
            }
        },
        created() {
            this.isUseAllGoldForMarketPlace = this.defaultInputSet.isUseAllGoldForMarketPlace;
            this.isNotUseAllGoldForMarketPlace = !this.isUseAllGoldForMarketPlace;
        },
        methods:{
            changedToggleUseChange() {
                this.isNotUseAllGoldForMarketPlace = !this.isUseAllGoldForMarketPlace;
                this.emitState();
            },
            changedToggleNotUseChange() {
                this.isUseAllGoldForMarketPlace = !this.isNotUseAllGoldForMarketPlace;
                this.emitState();
            },
            emitState(){
                const outPayload = {
                    isUseAllGoldForMarketPlace: this.isUseAllGoldForMarketPlace,
                };
                this.$emit("onUpdateSettingGoldMarketPlace", outPayload);
            },
        },
    }
</script>

<style scoped>

</style>