<template>
    <v-card
        max-height="650"
    >
        <v-row class="ml-1 pt-4">
            <v-col cols="5">
                <v-row class="pa-0">
                    <div class="font-weight-black">GAMEPLAY KPI</div>
                </v-row>
                <v-row class="ml-0 pt-0">
                    <v-simple-table>
                        <template v-slot:default>
                            <tbody>
                            <tr>
                                <td>PLAYER TAP DAMAGE</td>
                                <td>{{ kpiGameplayPlayerTapDamage }}</td>
                            </tr>
                            <tr>
                                <td>PLAYER TAP DPS</td>
                                <td>{{ kpiHeroWithBonusDps }}</td>
                            </tr>
                            <tr>
                                <td>Support possible level</td>
                                <td>{{ kpiSupportPossibleLevel }}</td>
                            </tr>
                            <tr>
                                <td>&Sigma;SUPPORT DPS <small>({{ kpiSupportPossibleUnlockCounter }})</small></td>
                                <td>{{ kpiAllSupportDps }}</td>
                            </tr>

                            <tr>
                                <td>PET possible level</td>
                                <td>{{ kpiPetLevel }}</td>
                            </tr>
                            <tr>
                                <td>PET DMG Standard</td>
                                <td>{{ kpiPetStandardDmg }}</td>
                            </tr>
                            <tr>
                                <td>&Sigma;PET Total Passive DMG</td>
                                <td>{{ kpiPetPassiveDmg }}</td>
                            </tr>
                            <tr>
                                <td>PET Average DPS</td>
                                <td>{{ kpiPetAvgDps }}</td>
                            </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-row>
            </v-col>
            <v-col>
                <v-row>
                    <v-col>
                        <v-img
                                max-height="211"
                                max-width="211"
                                :src="Tools.renderLocalPicture('ui/element_type_benefit.png')"
                        >
                        </v-img>
                    </v-col>
                </v-row>
                <v-row>
                    <v-simple-table>
                        <template v-slot:default>
                            <tbody>
                            <tr>
                                <td>
                                    ========== Element Hero DMG bonus ==========
                                </td>
                            </tr>
                            <tr>
                                <td v-html="heroVsBossElementRow"></td>
                            </tr>
                            <tr>
                                <td v-html="heroVsPetElementRow"></td>
                            </tr>
                            <tr>
                                <td>
                                    ========== Element Hero HP bonus ============
                                </td>
                            </tr>
                            <tr>
                                <td v-html="heroVsSupportElementRow"></td>
                            </tr>
                            <tr>
                                <td v-html="heroVsSupportElementSecondRow"></td>
                            </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-row>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
    import Tools from "@/utils/Tools";
    import ElementTypeAndName from "../../constants/ElementTypeAndName";

    export default {
    name: "GroupTabGameplayKpi",
    props: ['payload'],
    data() {
        return {
            Tools: Tools,
        }
    },
    computed: {
        kpiGameplayPlayerTapDamage () {
          const {kpiGameplayPlayerTapDamage} = this.payload;
          return kpiGameplayPlayerTapDamage.ToReadableAlphabetV2();
        },
        kpiHeroWithBonusDps () {
            const {kpiHeroWithBonusDps} = this.payload;
            return kpiHeroWithBonusDps.ToReadableAlphabetV2();
        },
        kpiSupportPossibleUnlockCounter () {
            const {kpiSupportPossibleUnlockCounter} = this.payload;
            return kpiSupportPossibleUnlockCounter;
        },
        kpiAllSupportDps() {
            const { kpiAllSupportDps } = this.payload;
            return kpiAllSupportDps.ToReadableAlphabetV2();
        },
        kpiPetStandardDmg () {
            const  {kpiPetStandardDmg} = this.payload;
            return kpiPetStandardDmg.ToReadableAlphabetV2();
        },
        kpiPetPassiveDmg () {
            const { kpiPetPassiveDmg} = this.payload;
            return kpiPetPassiveDmg.ToReadableAlphabetV2();
        },
        kpiPetAvgDps () {
            const { kpiPetAvgDps } = this.payload;
            return kpiPetAvgDps.ToReadableAlphabetV2();
        },
        kpiSupportPossibleLevel() {
            const {kpiSupportList} = this.payload;
            let minLv = -1;
            let maxLv = 0;

            kpiSupportList.forEach(supports => {
                supports.forEach(support =>{
                    if(support.isUnlock){
                        if(support.possibleLevel > maxLv){
                            maxLv = support.possibleLevel;
                        }
                        if(minLv === -1 || support.possibleLevel < minLv){
                            minLv = support.possibleLevel;
                        }
                    }
                });
            });
            if(maxLv === minLv || minLv === -1) {
                return maxLv;
            } else {
                return minLv+" - "+maxLv;
            }
        },
        kpiPetLevel () {
            const {kpiPetLevel} = this.payload;
            return kpiPetLevel;
        },

        getTeamBattleInfo(){
            const {teamBattleInfo} = this.payload;
            return teamBattleInfo;
        },

        heroVsBossElementRow(){
            const {heroDmgSessionGainBoss} = this.payload;
            let pubText = '';
            if(heroDmgSessionGainBoss && heroDmgSessionGainBoss.bonusPercent > 0){
                pubText = `<sup>✔</sup>`;
            }
            let html = `Hero: <strong>${this.heroElementType}</strong>${pubText}`;
            html += `&emsp;=>&emsp;Boss: <strong>${this.bossElementType}</strong>`;
            html += `&emsp;${this.heroElementBonusInfo}`;

            return html;
        },
        heroElementType(){
            if(this.getTeamBattleInfo){
                return ElementTypeAndName[this.getTeamBattleInfo.heroData.elementType];
            } else {
                return 'NO TYPE';
            }
        },
        heroElementBonusInfo(){
            const {heroDmgSessionGainBoss} = this.payload;

            if(heroDmgSessionGainBoss.bonusPercent === 0){
                return `<strong>0</strong>`;
            } else if(heroDmgSessionGainBoss.isGain){
                return `<strong style="color:green;">+${heroDmgSessionGainBoss.bonusDmg.ToReadableAlphabetV2()}</strong><small style="color:green;">(+${heroDmgSessionGainBoss.bonusPercent}%)</small>`;
            } else {
                return `<strong style="color:red;">-${heroDmgSessionGainBoss.bonusDmg.ToReadableAlphabetV2()}</strong><small style="color:red;">(${heroDmgSessionGainBoss.bonusPercent}%)</small>`;
            }
        },

        bossElementType(){
            const {bossInfo} = this.payload;
            if(bossInfo){
                return ElementTypeAndName[bossInfo.elementType];
            } else {
                return 'NO TYPE';
            }

        },

        heroVsPetElementRow(){
            let html = `Pet: <strong>${this.petElementType}</strong>`;
            html += `&emsp;=>&emsp;Hero: <strong>${this.heroElementType}</strong>`;
            html += `&emsp;${this.petElementBonusInfo}`;

            return html;
        },
        petElementType(){
            const {heroDmgSessionFromPet} = this.payload;
            let text = '';
            for(let i=0;i<heroDmgSessionFromPet.elementOrder.length;i++){
                let arrowGain = '';
                if(text !== ''){
                    arrowGain = ' => ' ;
                }
                let subText = '';
                if(heroDmgSessionFromPet.elementOrder[i].counter > 1){
                    subText = `<sub>(x${heroDmgSessionFromPet.elementOrder[i].counter})</sub>`;
                }
                let pubText = '';
                if(heroDmgSessionFromPet.elementOrder[i].isGain){
                    pubText = `<sup>✔</sup>`;
                }
                text =`<strong>${ElementTypeAndName[heroDmgSessionFromPet.elementOrder[i].elementType]+pubText+subText}</strong>` +arrowGain+text;
            }
            return text;
        },
        petElementBonusInfo(){
            const {heroDmgSessionFromPet} = this.payload;

            if(heroDmgSessionFromPet.bonusDmg.isZero){
                return `0`;
            } else{
                return `<strong style="color:green;">+${heroDmgSessionFromPet.bonusDmg.ToReadableAlphabetV2()}</strong><small style="color:green;">(+${heroDmgSessionFromPet.bonusPercent}%)</small>`;
            }
        },

        heroVsSupportElementRow(){
            let html = `Support: ${this.supportElementType}`;
            return html;
        },
        heroVsSupportElementSecondRow(){
            let html = `&emsp;=>&emsp;Hero: <strong>${this.heroElementType}</strong>`;
            html += `&emsp;${this.supportElementBonusInfo}`;

            return html;
        },
        supportElementType(){
            const {heroHpCapSessionFromSupport} = this.payload;

            let text = '';
            for(let i=0;i<heroHpCapSessionFromSupport.elementOrder.length;i++){
                let arrowGain = '';
                if(text !== ''){
                    arrowGain = ' => ' ;
                }
                let subText = '';
                if(heroHpCapSessionFromSupport.elementOrder[i].counter > 1){
                    subText = `<sub>(x${heroHpCapSessionFromSupport.elementOrder[i].counter})</sub>`;
                }
                let pubText = '';
                if(heroHpCapSessionFromSupport.elementOrder[i].isGain){
                    pubText = `<sup>✔</sup>`;
                }
                text =`<strong>${ElementTypeAndName[heroHpCapSessionFromSupport.elementOrder[i].elementType]+pubText+subText}</strong>` +arrowGain+text;
            }
            return text;
        },
        supportElementBonusInfo(){
            const {heroHpCapSessionFromSupport} = this.payload;

            if(heroHpCapSessionFromSupport.bonusHp.isZero){
                return `0`;
            } else {
                return `<strong style="color:green;">${heroHpCapSessionFromSupport.bonusHp.ToReadableAlphabetV2()}</strong><small style="color:green;">(x${heroHpCapSessionFromSupport.gainCounter})</small>`;
            }
        },
    },
}
</script>

<style scoped>

</style>