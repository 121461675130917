
export let BehaviourByNames = {
    incSpeedSupport: "inc-speed-support",
    incGoldDrop: "inc-gold-drop",
    damageEnemy: "damage_enemy",
    freezeEnemy: "freeze-enemy",
    poisonEnemy: "poison-enemy",
    sheepEnemy: "sheep-enemy",
    incMana: "inc_mana",
    incManaRefillSpeed: "inc_mana_refill_speed",
    incHeroSize: "inc_size_hero",
    incCriticalHitRatio: "inc_critical_hit_ratio",
    incGoldEnemyDrop: "inc_gold_enemy_drop",
    incPetActiveTime: "inc_pet_active_time",
    reviveHero: "revive_hero",
    reviveSupport: "revive_support",
    cureFreeze: "cure_freeze",
    curePoison: "cure_poison",
    cureSheep: "cure_sheep",
    cureFire: "cure_fire",
    incPowerUpCapacity: "inc_powerup_capacity",
    incHp: "inc_hp",
    fillHp: "fill_hp",
    fillMana: "fill_mana",
    incDps: "inc_dps",
    incBagSlot: "inc_bag_slot",
    autoTap: "auto_tap",
    holdingTap: "holding_tap"
}

export let BehaviourTypeDescription = [

    {
        "Type": "inc-speed-support",
        "Description": "- \"Increase speed support attack\"=> DPS",
        "ImpactDescription" : "Increase speed support attack",
        "cat":"attack",
    },
    {
        "Type": "inc-gold-drop",
        "Description": "- \"Gold rain from bird\"=> Gold",
        "ImpactDescription" : "Gold rain from bird",
        "cat":"farm",
    },
    {
        "Type": "damage_enemy",
        "Description": "- \"Deal damage on enemy\"=> Damage Enemy",
        "ImpactDescription" : "Deal damage on enemy",
        "cat":"attack",
    },
    {
        "Type": "freeze-enemy",
        "Description": "- \"Freeze enemy\"=> Freeze Enemy",
        "ImpactDescription" : "Freeze enemy",
        "cat":"attack",
    },
    {
        "Type": "poison-enemy",
        "Description": "- \"Poison enemy\"=> Poison Enemy",
        "ImpactDescription" : "Poison enemy",
        "cat":"attack",
    },
    {
        "Type": "sheep-enemy",
        "Description": "- \"Sheep enemy\"=> Sheep Enemy",
        "ImpactDescription" : "Sheep enemy",
        "cat":"attack",
    },
    {
        "Type": "inc_mana",
        "Description": "- \"Increase the total mana available\"=> Mana",
        "ImpactDescription" : "Increase the total mana available",
        "cat":"farm",
    },
    {
        "Type": "inc_mana_refill_speed",
        "Description": "- \"Increase speed regeneration of Mana\"=> Mana",
        "ImpactDescription" : "Increase speed regeneration of mana",
        "cat":"farm",
    },
    {
        "Type": "inc_size_hero",
        "Description": "- \"Increase size of hero\"=> Hero Scale",
        "ImpactDescription" : "Increase size of hero",
        "cat":"attack",
    },
    {
        "Type": "inc_critical_hit_ratio",
        "Description": "- \"Increase rate critical hit\"=> Critical hit",
        "ImpactDescription" : "Increase rate critical hit",
        "cat":"attack",
    },
    {
        "Type": "inc_gold_enemy_drop",
        "Description": "- \"Increase gold drop from enemy\"=> Gold",
        "ImpactDescription" : "Increase gold drop from enemy",
        "cat":"farm",
    },
    {
        "Type": "inc_pet_active_time",
        "Description": "- \"Increase pet active time\"=> Pet Active",
        "ImpactDescription" : "Increase pet active time",
        "cat":"attack",
    },
    {
        "Type": "revive_hero",
        "Description": "- \"Revive a hero\"=> Revive",
        "ImpactDescription" : "Revive a hero",
        "cat":"health",
    },
    {
        "Type": "revive_support",
        "Description": "- \"Revive a support\"=> Revive",
        "ImpactDescription" : "Revive a support",
        "cat":"health",
    },
    {
        "Type": "cure_freeze",
        "Description": "- \"Cancel freeze\"=> Cancel",
        "ImpactDescription" : "Cancel freeze",
        "cat":"defense",
    },
    {
        "Type": "cure_poison",
        "Description": "- \"Cancel poison\"=> Cancel",
        "ImpactDescription" : "Cancel poison",
        "cat":"defense",
    },
    {
        "Type": "cure_sheep",
        "Description": "- \"Cancel sheep\"=> Cancel",
        "ImpactDescription" : "Cancel sheep",
        "cat":"defense",
    },
    {
        "Type": "cure_fire",
        "Description": "- \"Cancel fire\"=> Cancel",
        "ImpactDescription" : "Cancel fire",
        "cat":"defense",
    },
    {
        "Type": "inc_powerup_capacity",
        "Description": "- \"Increase the capacity of power up\"=> Power UP capacity",
        "ImpactDescription" : "Increase the capacity of power up",
        "cat":"attack",
    },
    {
        "Type": "inc_hp",
        "Description": "- \"Increase the total HP \"=> HP",
        "ImpactDescription" : "Increase the capacity of power up",
        "cat":"health",
    },
    {
        "Type": "fill_hp",
        "Description": "- \"Refill HP\"=> HP",
        "ImpactDescription" : "Refill hp",
        "cat":"health",
    },
    {
        "Type": "fill_mana",
        "Description": "- \"Refill Mana\"=> Mana",
        "ImpactDescription" : "Refill mana",
        "cat":"farm",
    },
    {
        "Type": "inc_dps",
        "Description": "- \"Increase the damage of the global DPS\"=> DPS",
        "ImpactDescription" : "Increase global dps",
        "cat":"attack",
    },
    {
        "Type": "inc_bag_slot",
        "Description": "- \"Increase Bag Slot\"",
        "ImpactDescription" : "Increase bag slot",
        "cat":"other",
    },
    {
        "Type": "auto_tap",
        "Description": "- \"Auto Tap\" => auto tap",
        "ImpactDescription" : "Auto tap",
        "cat":"attack",
    },
    {
        "Type": "holding_tap",
        "Description": "- \"Holding Tap\" => holding to attack",
        "ImpactDescription" : "Holding tap",
        "cat":"attack",
    }
]

export default {BehaviourByNames, BehaviourTypeDescription};
