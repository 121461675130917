let SMPNum = require("../../SMPNum");
let lodash = require('lodash');

class SiblingItemMultipleImpactUtils {

    isNoneImplementCatch = false;
    msgCatch = "";

    constructor(gamePlayService){
        this.gamePlayService = gamePlayService;

        this.doneImplementImpact = [];

        //isDefineByBossHp
        this.doneImplementImpact.push("damage_enemy");

        //isDefineByCoinDrop
        this.doneImplementImpact.push("inc-gold-drop");
        this.doneImplementImpact.push("inc_gold_enemy_drop");

        //isDefineByBossDMG
        this.doneImplementImpact.push("inc_hp");
        this.doneImplementImpact.push("fill_hp");

        //isDefineByDmgBalanceToKillEnemy
        this.doneImplementImpact.push("inc-speed-support");
        this.doneImplementImpact.push("inc_dps");
        this.doneImplementImpact.push("poison-enemy");

        //isDefineByDurationToKillEnemy
        this.doneImplementImpact.push("freeze-enemy");
        this.doneImplementImpact.push("sheep-enemy");
        this.doneImplementImpact.push("inc_size_hero");

        //isDefineByNumOfTap
        this.doneImplementImpact.push("inc_pet_active_time");

        //isDefineByDurationToKillEnemy and isDefineByLifeTimeLimit
        this.doneImplementImpact.push("auto_tap");
        this.doneImplementImpact.push("holding_tap");

        //isPowerUpType
        this.doneImplementImpact.push("inc_powerup_capacity");
    }

    isHasImplementImpact(impType){
        return this.doneImplementImpact.includes(impType);
    }

    getDirectTitleFirstBehInputBonus(behaviour) {
        let titleInput = {
            "title": "",
            "unit": "",
        };
        if (this.isDefineByLifeTimeLimit(behaviour.type)) {
            titleInput.title = "Life time";
            titleInput.unit = "seconds";
        } else if (this.isDefineByDurationToKillEnemy(behaviour.type)) {
            if (this.isUseCapacityValueType(behaviour.type)) {
                titleInput.title = "Capacity bonus";
                titleInput.unit = "cap bonus";
            } else {
                titleInput.title = "Duration bonus";
                titleInput.unit = "seconds";
            }
        }
        return titleInput;
    }

    getDirectTitleInputBonus(behaviours) {
        let titleInput = {
            "title": "",
            "unit": "",
        };
        if (this.isBehavioursDefineByLifeTimeLimit(behaviours)) {
            titleInput.title = "Life time";
            titleInput.unit = "seconds";
        } else if (this.isImpactAsDurationType(behaviours)) {
            if (this.isUseCapacityValueType(behaviours[0].type)) {
                titleInput.title = "Capacity bonus";
                titleInput.unit = "cap bonus";
            } else {
                titleInput.title = "Duration bonus";
                titleInput.unit = "seconds";
            }
        }
        return titleInput;
    }

    defineDefaultConfig(config, itemData){
        if(itemData !== null){

            config.configOnMultiple = [];
            itemData.behaviours.forEach(behaviour =>{

                let durationBonus = undefined;
                let startBonusPercentage = 40;
                let isDurationType = false;
                let isLifeTimeLimitType = false;

                if(this.isDefineByDurationToKillEnemy(behaviour.type)){
                    isDurationType = true;
                }

                if(this.isDefineByLifeTimeLimit(behaviour.type)){
                    isDurationType = true;
                    isLifeTimeLimitType = true;
                }

                if(isDurationType){
                    if(this.isPowerUpType(behaviour.type)){
                        durationBonus = behaviour.gamePlayImpact.value;
                    } else if(isLifeTimeLimitType){
                        if(itemData.lifeTime.type !== "Forever"){
                            durationBonus = itemData.lifeTime.limit;
                        } else{
                            durationBonus = -1;
                        }
                    } else if(behaviour.gamePlayImpact.valueType !== "Percentage"){
                        durationBonus = behaviour.gamePlayImpact.value;
                    }
                } else if(behaviour.gamePlayImpact.valueType === "Percentage"){
                    startBonusPercentage = behaviour.gamePlayImpact.value;
                }

                config.configOnMultiple.push({
                    durationBonus: durationBonus,
                    startBonusPercentage: startBonusPercentage,
                    isDurationType: isDurationType,
                });
            });
        }
    }

    generateSiblingItemsByConfig(config, itemData){
        if(config.configOnMultiple === undefined){
            this.defineDefaultConfig(config, itemData);
        }
        let intStartLevel = config.startLevel;
        let intEndLevel = config.endLevel;
        let intEndBonus = config.untilBonusPercentage;
        let intLevelRange = config.levelRangeSelect;
        if(intStartLevel > intEndLevel){
            this.isNoneImplementCatch = true;
            this.msgCatch = "Input field not valid";
        } else {
            return this.generateSiblingItems(itemData, intStartLevel, intEndLevel, intEndBonus, intLevelRange, config);
        }
        return [];
    }

    generateSiblingItems(itemData, startLevel, endLevel, untilBonusPercentage, levelRange, config) {
        this.msgCatch = "";
        let cloneList = [];
        let stLevel = startLevel;
        let edLevel = stLevel;
        let maxLevel = endLevel;
        let cloneCount = 0;
        let isNotImplement = false;

        let firstItem = true;

        while (edLevel < maxLevel && stLevel < maxLevel) {
            let clone = lodash.cloneDeep(itemData);
            let isLastImpact = false;
            let isDurationType = false;
            let ratioForCost = 0;
            for(let index = clone.behaviours.length - 1;index >= 0; index--){
                isLastImpact = index === 0;
                let configMult = config.configOnMultiple[index];
                if(configMult.isDurationType && configMult.durationBonus <= 0){
                    if(isLastImpact){
                        edLevel = maxLevel;
                        stLevel = maxLevel;
                        continue;
                    } else {
                        continue;
                    }
                } else {
                    let startBonusPercentage = configMult.startBonusPercentage;
                    let durationBonus = configMult.durationBonus;
                    let beh = clone.behaviours[index];

                    ratioForCost += startBonusPercentage;

                    // if (!this.isHasImplementImpact(beh.type)) {
                    //     isNotImplement = true;
                    //     this.msgCatch = "this impact type not implement yet";
                    // }

                    if(!configMult.isDurationType){
                        this.defineDirectValueBonusForImpact(beh, startBonusPercentage, stLevel);
                        if(isLastImpact){
                            if (firstItem === true){
                                edLevel = stLevel + 9; //this.getLevelOnImpactReach(beh, stLevel, endBonus);
                                firstItem = false;
                            } else {
                                edLevel = stLevel + 10; //this.getLevelOnImpactReach(beh, stLevel, endBonus);
                            }
                        }
                    } else {
                        if(isLastImpact) {
                            isDurationType = true;
                        }

                        if(this.isDefineByLifeTimeLimit(beh.type)){
                            if(isLastImpact){
                                this.defineLifeTimeBonusDirectValue(clone, durationBonus);
                            }
                       } else {
                           if(this.isDefineByImpactDurationAndChangeLifeTime(beh.type)){
                               if(beh.gamePlayImpact.periodValue <= 0) {
                                   if(isLastImpact){
                                       this.defineLifeTimeBonusDirectValue(clone, durationBonus);
                                   }
                               }
                           }
                           this.defineDirectValueBonusDirectValue(beh, durationBonus);
                       }
                        if(isLastImpact){
                            edLevel = stLevel + levelRange;
                        }
                    }

                    if(isLastImpact){
                        if (edLevel === -1) {
                            isNotImplement = true;
                            this.msgCatch = "this type is duration value, so we don't have solution to calculate end level yet";
                            //return;
                        } else {
                            if (edLevel > maxLevel) {
                                edLevel = maxLevel;
                            }
                            clone.gameLevel.levelEnd = edLevel;
                        }
                    }
                }
            }

            if(isLastImpact){
                if (stLevel <= maxLevel) {
                    cloneCount++;
                    clone.seller = "HokMok";
                    clone.level = cloneCount;
                    if (clone.level > 1){
                        clone.gameLevel.levelStart = stLevel + 1;
                    } else {
                        clone.gameLevel.levelStart = stLevel;
                    }

                    clone.renderId = clone.id + "cl" + cloneCount;
                    clone.id = undefined;
                    clone.costConfig = {
                        kpiLevel : clone.gameLevel.levelStart,
                        ratio : ratioForCost / 100,
                        ghostKill : 3
                    };
                    cloneList.push(clone);

                    stLevel = edLevel;
                    if(isDurationType){
                        stLevel++;
                    }
                }
            }
        }
        this.isNoneImplementCatch = isNotImplement;
        return cloneList;
    }

    defineDirectValueBonusForImpact(behaviour, bonus, levelStart){

        if(this.isHasImplementImpact(behaviour.type)){
            let smpGetBase;
            let bonusGoal;
            let isDefineByDuration = this.isDefineByDurationToKillEnemy(behaviour.type);
            let directUnit = "";
            if(this.isDefineByBossHp(behaviour.type)){
                smpGetBase = this.gamePlayService.getBossHP(levelStart);
                bonusGoal = SMPNum.multSmpNum(smpGetBase, new SMPNum(bonus/100));
                directUnit = "dmg";
            } else if(this.isDefineByCoinDrop(behaviour.type)){
                smpGetBase = this.gamePlayService.getCoinDrop(levelStart);
                bonusGoal = SMPNum.multSmpNum(smpGetBase, new SMPNum(bonus/100));
                directUnit = "golds";
            } else if(this.isDefineByBossDMG(behaviour.type)) {
                let smpBonus = new SMPNum(bonus/100);
                let bossDmgBalance = this.gamePlayService.getBossDmgBalance(levelStart);
                bonusGoal = SMPNum.multSmpNum(bossDmgBalance, smpBonus);
                smpGetBase = bossDmgBalance;
                directUnit = "hp";
            } else if(this.isDefineByDmgBalanceToKillEnemy(behaviour.type) || this.isDefineByDMGAndConvertToPercent(behaviour.type)){
                let smpBonus = new SMPNum(bonus/100);
                let dmgBalance = this.gamePlayService.getDmgBalanceToKillEnemy(levelStart);
                bonusGoal = SMPNum.multSmpNum(dmgBalance, smpBonus);
                smpGetBase = dmgBalance;
                if(this.isUseDPSValueType(behaviour.type)){
                    directUnit = "dps";
                } else {
                    directUnit = "dmg";
                }
            } else if(isDefineByDuration){
                let durationBalance = this.gamePlayService.getDurationBalanceToKillEnemy();
                bonusGoal = new SMPNum(durationBalance * bonus/100);
                smpGetBase = new SMPNum(durationBalance);
                directUnit = "seconds";
            }

            //round for bonus
            bonusGoal.round();

            if(this.isDefineByDMGAndConvertToPercent(behaviour.type)){
                let bossHp = this.gamePlayService.getBossHP(levelStart);
                let reduceResult = SMPNum.divSmpNum(bonusGoal, bossHp);
                reduceResult = SMPNum.multSmpNum(reduceResult, new SMPNum(100));
                behaviour.gamePlayImpact.value = reduceResult.ToDoubleValue();
                behaviour.gamePlayImpact.valueType = "DMGToPercent";
                behaviour.gamePlayImpact.dmgToPercent = bonusGoal.StringForSave();
                behaviour.gamePlayImpact.bean.valueType = {};
                behaviour.gamePlayImpact.bean.valueType.text = reduceResult.ToReadableAlphabetV2() + "%";
                behaviour.gamePlayImpact.bean.valueType.value = "DirectDisplayValue";
            } else if(isDefineByDuration){
                behaviour.gamePlayImpact.value = bonusGoal.ToIntValue();
                behaviour.gamePlayImpact.valueType = "DurationInSeconds";
                behaviour.gamePlayImpact.bean.valueType = {};
                behaviour.gamePlayImpact.bean.valueType.text = bonusGoal.ToIntValue()+" seconds";
                behaviour.gamePlayImpact.bean.valueType.value = "DurationDirect";
            } else {
                behaviour.gamePlayImpact.value = bonusGoal.StringForSave();
                behaviour.gamePlayImpact.valueType = "DirectValue";
                behaviour.gamePlayImpact.bean.valueType = {};
                behaviour.gamePlayImpact.bean.valueType.text = bonusGoal.ToReadableAlphabetV2() + " (" + directUnit+")";
                behaviour.gamePlayImpact.bean.valueType.value = "DirectDisplayValue";
            }

            behaviour.testSMPBaseOnStartLv = smpGetBase.StringForSave();
        }
    }

    defineLifeTimeBonusDirectValue(itemData, bonus){
        itemData.lifeTime.limit = bonus;
        itemData.lifeTime.bean = {};
        itemData.lifeTime.bean.text = bonus+" seconds";
        itemData.lifeTime.bean.value = "DurationDirect";
        itemData.behaviours[0].testSMPBaseOnStartLv = "lifeBonus";
    }

    defineDirectValueBonusDirectValue(behaviour, bonus){
        if(this.isUseCapacityValueType(behaviour.type)){
            behaviour.gamePlayImpact.value = bonus;
            behaviour.gamePlayImpact.valueType = "Capacity";
            behaviour.gamePlayImpact.bean.valueType = {};
            behaviour.gamePlayImpact.bean.valueType.text = bonus+" caps";
            behaviour.gamePlayImpact.bean.valueType.value = "CapacityDirect";
        } else {
            let smpBonus = new SMPNum(bonus);
            behaviour.gamePlayImpact.value = smpBonus.ToIntValue();
            behaviour.gamePlayImpact.valueType = "DurationInSeconds";
            behaviour.gamePlayImpact.bean.valueType = {};
            behaviour.gamePlayImpact.bean.valueType.text = smpBonus.ToIntValue()+" seconds";
            behaviour.gamePlayImpact.bean.valueType.value = "DurationDirect";
        }
    }

    getLevelOnImpactReach(behaviour, levelStart, untilBonus){
        let endLevel = levelStart;
        if(this.isHasImplementImpact(behaviour.type)){
            let bonus;
            let smpGetBase;
            let lv = levelStart;
            let smpEndBonus = new SMPNum(untilBonus);
            if(this.isDefineByDMGAndConvertToPercent(behaviour.type)){
                let impValue = SMPNum.fromPowerString(behaviour.gamePlayImpact.dmgToPercent);
                let impValueInPercentage = SMPNum.multSmpNum(impValue, new SMPNum(100));
                do{
                    lv++;
                    smpGetBase = this.gamePlayService.getDmgBalanceToKillEnemy(lv);
                    bonus = SMPNum.divSmpNum(impValueInPercentage, smpGetBase);
                } while (SMPNum.greaterThan( bonus, smpEndBonus));
            } else {
                let impValue = SMPNum.fromPowerString(behaviour.gamePlayImpact.value);
                let impValueInPercentage = SMPNum.multSmpNum(impValue, new SMPNum(100));
                if(this.isDefineByBossHp(behaviour.type)){
                    do{
                        lv++;
                        smpGetBase = this.gamePlayService.getBossHP(lv);
                        bonus = SMPNum.divSmpNum(impValueInPercentage, smpGetBase);
                    } while (SMPNum.greaterThan( bonus, smpEndBonus));
                } else if(this.isDefineByCoinDrop(behaviour.type)){
                    do{
                        lv++;
                        smpGetBase = this.gamePlayService.getCoinDrop(lv);
                        bonus = SMPNum.divSmpNum(impValueInPercentage, smpGetBase);
                    } while (SMPNum.greaterThan( bonus, smpEndBonus));
                } else if(this.isDefineByBossDMG(behaviour.type)){
                    do{
                        lv++;
                        smpGetBase = this.gamePlayService.getBossDmgBalance(lv);
                        bonus = SMPNum.divSmpNum(impValueInPercentage, smpGetBase);
                    } while (SMPNum.greaterThan( bonus, smpEndBonus));
                } else if(this.isDefineByDmgBalanceToKillEnemy(behaviour.type)){
                    do{
                        lv++;
                        smpGetBase = this.gamePlayService.getDmgBalanceToKillEnemy(lv);
                        bonus = SMPNum.divSmpNum(impValueInPercentage, smpGetBase);
                    } while (SMPNum.greaterThan( bonus, smpEndBonus));
                } else if(this.isDefineByDurationToKillEnemy(behaviour.type)){
                    //duration is static so we don't have solution yet
                    lv=-1;
                }
            }
            endLevel = lv;
        }
        return endLevel;
    }

    getBonusTextOnLevelOfImpact(itemData, behaviour, lv){
        let refBonus = {
            "bonus": "n/a",
            "base": "n/a",
            "percentage": "n/a",
            "baseType": "Base"
        };
        if(lv <= 0){
            return refBonus;
        }

        if(this.isHasImplementImpact(behaviour.type)){
            let isDurationType = this.isDefineByDurationToKillEnemy(behaviour.type);
            let isLifeTimeLimitType = this.isDefineByLifeTimeLimit(behaviour.type);
            if(isLifeTimeLimitType){
                isDurationType = true;
            }

            let directUnit = "";
            let baseUnit = "";
            if(this.isDefineByDMGAndConvertToPercent(behaviour.type)){
                refBonus.baseType = "BossHp";
                let bossHp = this.gamePlayService.getBossHP(lv);
                let dmg = SMPNum.fromPowerString(behaviour.gamePlayImpact.dmgToPercent);
                let reduceResult = SMPNum.divSmpNum(dmg, bossHp);
                reduceResult = SMPNum.multSmpNum(reduceResult, new SMPNum(100));
                refBonus.base = bossHp.ToReadableAlphabetV2() +" (hp)";
                refBonus.bonus = reduceResult.ToReadableAlphabetV2() +" (%)";
                refBonus.percentage = "of dmg("+dmg.ToReadableAlphabetV2()+")";//reduceResult.ToReadableAlphabetV2()+"%";
            } else if(!isDurationType){
                let smpGetBase;
                let bonus = new SMPNum();
                let impValue = SMPNum.fromPowerString(behaviour.gamePlayImpact.value);
                let impValueInPercentage = SMPNum.multSmpNum(impValue, new SMPNum(100));
                if(this.isDefineByBossHp(behaviour.type)){
                    smpGetBase = this.gamePlayService.getBossHP(lv);
                    bonus = SMPNum.divSmpNum(impValueInPercentage, smpGetBase);
                    directUnit = "dmg";
                    baseUnit = "hp";
                    refBonus.baseType = "BossHp";
                } else if(this.isDefineByCoinDrop(behaviour.type)){
                    smpGetBase = this.gamePlayService.getCoinDrop(lv);
                    bonus = SMPNum.divSmpNum(impValueInPercentage, smpGetBase);
                    directUnit = "golds";
                    baseUnit = "golds";
                    refBonus.baseType = "GoldDrop";
                } else if(this.isDefineByBossDMG(behaviour.type)){
                    smpGetBase = this.gamePlayService.getBossDmgBalance(lv);
                    bonus = SMPNum.divSmpNum(impValueInPercentage, smpGetBase);
                    directUnit = "hp";
                    baseUnit = "dmg";
                    refBonus.baseType = "BossDMG";
                } else if(this.isDefineByDmgBalanceToKillEnemy(behaviour.type)){
                    smpGetBase = this.gamePlayService.getDmgBalanceToKillEnemy(lv);
                    bonus = SMPNum.divSmpNum(impValueInPercentage, smpGetBase);
                    if(this.isUseDPSValueType(behaviour.type)){
                        directUnit = "dps";
                    } else {
                        directUnit = "dmg";
                    }
                    baseUnit = "hp";
                    refBonus.baseType = "BossHp";
                } else if(this.isDefineByDurationToKillEnemy(behaviour.type)){
                    directUnit = "seconds";
                    baseUnit = "seconds";
                    refBonus.baseType = "DurationKillBoss";
                }
                refBonus.bonus = impValue.ToReadableAlphabetV2()+" ("+directUnit+")";
                refBonus.base = smpGetBase.ToReadableAlphabetV2()+" ("+baseUnit+")";
                refBonus.percentage = bonus.ToReadableAlphabetV2()+"%";
            } else {
                let lvStart = itemData.gameLevel.levelStart;
                let lvEnd = itemData.gameLevel.levelEnd;
                let duration = behaviour.gamePlayImpact.value;
                if(this.isUseCapacityValueType(behaviour.type)){
                    directUnit = "cap";
                    refBonus.baseType = "BaseStartLv.";
                    refBonus.base = "Capacity";
                } else if(isLifeTimeLimitType){
                    duration = itemData.lifeTime.limit;
                    directUnit = "seconds";
                    refBonus.baseType = "BaseStartLv.";
                    refBonus.base = "LifeTime";
                } else {
                    directUnit = "seconds";
                    refBonus.baseType = "BaseStartLv.";
                    refBonus.base = "Duration";
                }
                let reduceResult = this.getImpactReduceDurationToEndLevel(duration, lvStart, lvEnd, lv);
                let maxResult = this.getImpactReduceDurationToEndLevel(duration, lvStart, lvEnd, lvStart);
                if(this.isDefineByNumOfTap(behaviour.type)){
                    refBonus.bonus = Math.round(reduceResult) +" ("+directUnit+")";
                } else{
                    refBonus.bonus = this.roundTwoDecimal(reduceResult) +" ("+directUnit+")";
                }
                refBonus.percentage = Math.round(reduceResult*100/maxResult)+ "%";
            }
        }

        /*not use this for cost more
        if (itemData.costConfig){
            let gold = this.gamePlayService.getCoinDrop(itemData.costConfig.kpiLevel) ;
            gold = SMPNum.multSmpNum(gold, new SMPNum(itemData.costConfig.ghostKill));
            gold = SMPNum.multSmpNum(gold, new SMPNum(itemData.costConfig.ratio));
            if (!gold.IsDoubleInifinity()){
                gold = new SMPNum(Math.round(gold.ToDoubleValue()));
            }
            refBonus.costInGold = gold;
        }*/

        return refBonus;
    }

    getImpactReduceDurationToEndLevel(duration, levelStart, levelEnd, currentLevelStage){
        let range = levelEnd - levelStart;
        let durationEnd = duration * 0.02;//remain only 2%
        let pPerLv = (duration - durationEnd) / range;
        let result = duration - (currentLevelStage - levelStart) * pPerLv;
        result = Math.max(2, result);
        return result;
    }

    roundTwoDecimal(value){
        return Math.round(value * 100) / 100;
    }

    isDefineByBossHp(impType){
        if(impType === "damage_enemy"){
            return true;
        }
        return false;
    }

    isDefineByCoinDrop(impType){
        if(impType === "inc-gold-drop"
            || impType === "inc_gold_enemy_drop"){
            return true;
        }
        return false;
    }

    isDefineByBossDMG(impType){
        if(impType === "inc_hp"
            || impType === "fill_hp"){
            return true;
        }
        return false;
    }

    isDefineByDmgBalanceToKillEnemy(impType){
        if(impType === "inc-speed-support"
          || impType === "inc_dps"
          || impType === "poison-enemy"){
            return true;
        }
        return false;
    }

    isDefineByDMGAndConvertToPercent(impType){
        if(impType === "inc-speed-support"){
            return true;
        } else if(this.isPowerUpType(impType)){
            return true;
        } else if(this.isDefineByNumOfTap(impType)){
            return true;
        }
        return false;
    }

    isDefineByDurationToKillEnemy(impType){
        if(impType === "freeze-enemy"
            || impType === "sheep-enemy"
            || impType === "inc_size_hero"){
            return true;
        }
        return false;
    }

    isDefineByNumOfTap(impType){
        if(impType === "inc_pet_active_time"){
            return true;
        }
        return false;
    }

    isPowerUpType(impType){
        return impType === "inc_powerup_capacity";
    }

    isUseCapacityValueType(impType){
        return this.isDefineByNumOfTap(impType) || this.isPowerUpType(impType);
    }

    isUseDPSValueType(impType){
        if(impType === "inc_dps"){
            return true;
        }
        return false;
    }

    isDefineByLifeTimeLimit(impType){
        if(impType === "holding_tap"
            || impType === "auto_tap"){
            return true;
        }
        return false;
    }

    isDefineByImpactDurationAndChangeLifeTime(impType){
        if(this.isDefineByDurationToKillEnemy(impType)){
            return true;
        }
        return false;
    }

    isImpactAsDurationType(behaviours){
        let isDuration = false;
        behaviours.forEach(behaviour =>{
            if(this.isDefineByDurationToKillEnemy(behaviour.type)){
                isDuration = true;
                return;
            }
        });

        return isDuration;
    }

    isBehavioursDefineByLifeTimeLimit(behaviours){
        let isTimeLimit = false;
        behaviours.forEach(behaviour =>{
            if(this.isDefineByLifeTimeLimit(behaviour.type)){
                isTimeLimit = true;
                return;
            }
        });

        return isTimeLimit;
    }

    isUsingDirectDurationConfig(itemData){
        let isDurationType = this.isImpactAsDurationType(itemData.behaviours);
        let isLifeTimeLimitType = this.isBehavioursDefineByLifeTimeLimit(itemData.behaviours);
        if(isLifeTimeLimitType){
            isDurationType = true;
        }

        return isDurationType;
    }
}

module.exports = SiblingItemMultipleImpactUtils;