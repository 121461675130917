<template>
    <v-card
        class="mt-2"
        elevation="0"
        outlined
    >
        <v-subheader><strong>SHARING GOLD BETWEEN SUPPORT LEVELS AND SKILLS ({{validatedGoldAvailable.ToReadableAlphabetV2()}})</strong></v-subheader>
<!--        <v-subheader>Allocate to Unlock ({{ goldAllocatedToSupportUnlocks }} of-->
<!--            {{ validatedGoldAvailable.ToReadableAlphabetV2() }})</v-subheader>-->
<!--        <v-card-text class="py-0">-->
<!--            <v-slider-->
<!--                dense-->
<!--                v-model="percentageAllocatedToSupportUnlocks"-->
<!--                @change="onChangedPercentageAllocatedToSupportUnlocks"-->
<!--                track-color="grey"-->
<!--                thumb-label-->
<!--                min="0"-->
<!--                max="100"-->
<!--            >-->
<!--                <template v-slot:append>-->
<!--                    <v-text-field-->
<!--                        v-model="percentageAllocatedToSupportUnlocks"-->
<!--                        @input="onInputPercentageAllocatedToSupportUnlocks"-->
<!--                        style="width: 50px"-->
<!--                        dense-->
<!--                        type="number"-->
<!--                        min="0"-->
<!--                        max="100"-->
<!--                    ></v-text-field>-->
<!--                </template>-->
<!--            </v-slider>-->
<!--        </v-card-text>-->
        <v-subheader>Allocate to Level ({{ goldAllocatedToSupportLevels }} of
            {{ validatedGoldAvailable.ToReadableAlphabetV2() }})</v-subheader>
        <v-card-text class="py-0">
            <v-slider
                dense
                v-model="percentageAllocatedToSupportLevels"
                @change="onChangedPercentageAllocatedToSupportLevels"
                track-color="grey"
                thumb-label
                min="0"
                max="100"
            >
                <template v-slot:append>
                    <v-text-field
                        v-model="percentageAllocatedToSupportLevels"
                        @input="onInputPercentageAllocatedToSupportLevels"
                        style="width: 50px"
                        dense
                        type="number"
                        min="0"
                        max="100"
                    ></v-text-field>
                </template>
            </v-slider>
        </v-card-text>

        <v-subheader>Allocate to Skills ({{ goldAllocatedToSupportSkills }} of
            {{ validatedGoldAvailable.ToReadableAlphabetV2() }})</v-subheader>
        <v-card-text class="py-0">
            <v-slider
                dense
                v-model="percentageAllocatedToSupportSkills"
                @change="onChangedPercentageAllocatedToSupportSkills"
                track-color="grey"
                thumb-label
                min="0"
                max="100"
            >
                <template v-slot:append>
                    <v-text-field
                        v-model="percentageAllocatedToSupportSkills"
                        @input="onInputPercentageAllocatedToSupportSkills"
                        style="width: 50px"
                        dense
                        type="number"
                        min="0"
                        max="100"
                    ></v-text-field>
                </template>
            </v-slider>
        </v-card-text>

    </v-card>
</template>

<script>
import SMPNum from "@/SMPNum";

export default {
    name: "SettingGoldBetweenSupportLevelsAndSkills",
    props: ["goldAvailableForSupports", "defaultInputSet"],
    data() {
        return {
            percentageAllocatedToSupportLevels: null,
            percentageAllocatedToSupportSkills: null,
            //percentageAllocatedToSupportUnlocks: null
        }
    },
    created() {
        this.percentageAllocatedToSupportSkills = this.defaultInputSet.ratioSupportSkill ?? 0;
        this.percentageAllocatedToSupportLevels = this.defaultInputSet.ratioAllocatedToSupportLevel;
        //this.percentageAllocatedToSupportUnlocks = 100 - (this.percentageAllocatedToSupportSkills + this.percentageAllocatedToSupportLevels);

        //when merge unlock and level support cost
        if(this.percentageAllocatedToSupportLevels + this.percentageAllocatedToSupportSkills < 100){
            this.percentageAllocatedToSupportLevels = 100 - this.percentageAllocatedToSupportSkills;
        }
    },
    computed: {
        validatedGoldAvailable(){
            if(!this.goldAvailableForSupports) {
                return SMPNum.fromNum(0);
            }else {
                return this.goldAvailableForSupports;
            }
        },
        // goldAllocatedToSupportUnlocks(){
        //     const totalGold = this.validatedGoldAvailable;
        //     return SMPNum.multSmpNum(totalGold, SMPNum.fromNum(this.percentageAllocatedToSupportUnlocks / 100)).ToReadableAlphabetV2();
        // },
        goldAllocatedToSupportLevels(){
            const totalGold = this.validatedGoldAvailable;
            return SMPNum.multSmpNum(totalGold, SMPNum.fromNum(this.percentageAllocatedToSupportLevels / 100)).ToReadableAlphabetV2();
        },
        goldAllocatedToSupportSkills(){
            const totalGold = this.validatedGoldAvailable;
            return SMPNum.multSmpNum(totalGold, SMPNum.fromNum(this.percentageAllocatedToSupportSkills / 100)).ToReadableAlphabetV2();
        },
    },
    methods: {
        validateShared(vipValue, following1, following2){
            let oldValue = 100 - (following1 + following2);
            let newValue = vipValue;
            let distance = newValue - oldValue;

            if (following2 > following1){
                let v1 = following1 - distance / 2;
                if (v1 > 0){
                    following1 = v1;
                    let v2 = following2 - distance / 2;
                    following2 = v2 > 0 ? v2 : 0;
                } else {
                    distance = distance - following1;
                    following1 = 0;
                    let v2 = following2 - distance;
                    following2 = v2 > 0 ? v2 : 0;
                }
            } else {
                let v1 = following2 - distance / 2;
                if (v1 > 0){
                    following2 = v1;
                    let v2 = following1 - distance / 2;
                    following1 = v2 > 0 ? v2 : 0;
                } else {
                    distance = distance - following2;
                    following2 = 0;
                    let v2 = following1 - distance;
                    following1 = v2 > 0 ? v2 : 0;
                }
            }

            return {following1, following2};
        },

        validateTwoShared(vipValue, following1){
            following1 = 100 - vipValue;
            return {following1};
        },

        // onInputPercentageAllocatedToSupportUnlocks() {
        //     this.onChangedPercentageAllocatedToSupportUnlocks();
        // },
        // onChangedPercentageAllocatedToSupportUnlocks() {
        //    let newShares = this.validateShared(
        //         this.percentageAllocatedToSupportUnlocks,
        //         this.percentageAllocatedToSupportLevels,
        //         this.percentageAllocatedToSupportSkills);
        //
        //    this.percentageAllocatedToSupportLevels = newShares.following1;
        //    this.percentageAllocatedToSupportSkills = newShares.following2;
        //
        //     this.emitState();
        // },

        onInputPercentageAllocatedToSupportLevels() {
            this.onChangedPercentageAllocatedToSupportLevels()
        },
        onChangedPercentageAllocatedToSupportLevels() {
            let newShares = this.validateTwoShared(
                this.percentageAllocatedToSupportLevels,
                this.percentageAllocatedToSupportSkills);

            this.percentageAllocatedToSupportSkills = newShares.following1;

            this.emitState();
        },

        onInputPercentageAllocatedToSupportSkills() {
            this.onChangedPercentageAllocatedToSupportSkills();
        },
        onChangedPercentageAllocatedToSupportSkills() {
            let newShares = this.validateTwoShared(
                this.percentageAllocatedToSupportSkills,
                this.percentageAllocatedToSupportLevels);

            this.percentageAllocatedToSupportLevels = newShares.following1;

            this.emitState();
        },



        emitState(){
            const outPayload = {
                percentageAllocatedToSupportLevels: this.percentageAllocatedToSupportLevels,
                percentageAllocatedToSupportSkills: this.percentageAllocatedToSupportSkills,
            }
            this.$emit("onUpdateSettingGoldBetweenSupportLevelsAndSkills", outPayload);
        },
    }
}
</script>

<style scoped>

</style>