<template>
    <div>
        FTT : <strong> {{ kpiTimeFarming }} </strong> <br/>
        GHP<small>({{ kpiGhostLevel }})</small>: <strong> {{ kpiGhostHp }} </strong><br/>
        NBHP<small>({{ kpiGhostLevel }})</small>: <strong> {{ kpiNormalBossHp }} </strong><br/>
        ZBHP<small>({{ kpiLevelThatZoneBossAppear }})</small>: <strong> {{ kpiZoneBossHp }} </strong><br/>

<!--        HeroElement: <strong> {{ kpiHeroDmgSessionBonus }} </strong><br/>-->
        DMG<small>({{ kpiHeroBaseLevel }})</small>: <strong> {{
            kpiHeroBaseDmg
        }} </strong> <small>({{kpiHeroDmgSessionBonus}})</small><br/>

        DPS
        <small>({{ kpiHeroBaseLevel }})</small>
        : <strong> {{
            kpiHeroBaseDps
        }} </strong><br/>


<!--        SupportElement: <strong> {{ kpiSupportElementType }} </strong><br/>-->
        Support DPS
        <small>({{ kpiSupportActiveCount }})</small>
        : <strong> {{
            kpiSupportDpsStandard
        }} </strong><br/>

        =========ITEM=========<br/>
        DMG Item: <strong> {{kpiDmgImpactBonus.ToReadableAlphabetV2()}} </strong><br/>
        DPS Item: <strong> {{kpiTotalDpsItemBonus.ToReadableAlphabetV2()}} </strong><br/>
        HP Item: <strong> {{kpiHpDpsItemBonus.ToReadableAlphabetV2()}} </strong><br/>


<!--        WAVE: <strong>{{ kpiWaveValue }} </strong>-->
    </div>
</template>

<script>
import SMPNum from "@/SMPNum";
import ElementTypeAndName from "../../constants/ElementTypeAndName";

export default {
    name: "GroupGhostHp",
    props: ['payload'],

    computed: {
        kpiWaveValue() {
            const {kpiWaveValue} = this.payload;
            return kpiWaveValue;
        },
        kpiTimeFarming() {
            const {kpiTimeFarming} = this.payload;
            return SMPNum.ToReadableTimeValue(kpiTimeFarming);
        },
        kpiGhostLevel() {
            const {kpiGameLevel} = this.payload;
            return kpiGameLevel;
        },
        kpiLevelThatZoneBossAppear() {
            const {kpiLevelThatZoneBossAppear} = this.payload;
            return kpiLevelThatZoneBossAppear;
        },
        kpiGhostHp() {
            const {kpiGhostHp} = this.payload;
            return kpiGhostHp.ToReadableAlphabetV2();
        },
        kpiNormalBossHp() {
            const {kpiNormalBossHp} = this.payload;
            return kpiNormalBossHp.ToReadableAlphabetV2();
        },
        kpiZoneBossHp() {
            const {kpiZoneBossHp} = this.payload;
            return kpiZoneBossHp.ToReadableAlphabetV2();
        },
        kpiHeroBaseLevel() {
            const {kpiHeroLevel} = this.payload;
            return kpiHeroLevel;
        },
        kpiHeroBaseDmg() {
            const {kpiHeroBaseDmg} = this.payload;
            return kpiHeroBaseDmg.ToReadableAlphabetV2();
        },
        kpiHeroBaseDps() {
            const {kpiHeroBaseDps} = this.payload;
            return kpiHeroBaseDps.ToReadableAlphabetV2();
        },
        kpiSupportActiveCount() {
            const {kpiSupportActiveCount} = this.payload;
            return kpiSupportActiveCount;
        },
        kpiSupportDpsStandard() {
            const {kpiSupportDpsStandard} = this.payload;
            return kpiSupportDpsStandard.ToReadableAlphabetV2();
        },
        kpiDmgImpactBonus(){
            const {kpiDmgImpactBonus} = this.payload;
            return kpiDmgImpactBonus;
        },
        kpiTotalDpsItemBonus(){
            const {kpiTotalDpsItemBonus} = this.payload;
            return kpiTotalDpsItemBonus;
        },
        kpiHpDpsItemBonus(){
            const {kpiHpItemBonus} = this.payload;
            return kpiHpItemBonus;
        },
        getTeamBattleInfo(){
            const {teamBattleInfo} = this.payload;
            return teamBattleInfo;
        },
        kpiSupportElementType(){
            let text = '';
            let elements = [];
            this.getTeamBattleInfo.supportsData.forEach(support => {
                if(!elements.includes(support.elementType)){
                    if(text !== ''){
                        text += " ";
                    }
                    text += ""+ElementTypeAndName[support.elementType];
                    elements.push(support.elementType);
                }
            });
            if(text === ''){
                text = 'NONE';
            }
            return text;
        },
        kpiHeroDmgSessionBonus(){
            const {heroDmgSessionGainBoss, heroDmgSessionFromPet} = this.payload;
            let bonusText = "";
            if(heroDmgSessionGainBoss.isGain){
                bonusText = "+"+heroDmgSessionGainBoss.bonusDmg.ToReadableAlphabetV2();
            } else {
                bonusText = "-"+heroDmgSessionGainBoss.bonusDmg.ToReadableAlphabetV2();
            }

            if(heroDmgSessionFromPet.bonusDmg){
                bonusText += " +"+heroDmgSessionFromPet.bonusDmg.ToReadableAlphabetV2();
            }

            return bonusText;
        },
    },
}
</script>

<style scoped>

</style>