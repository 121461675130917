<template>
    <v-card elevation="1"
            class="pa-1 mx-1"
            min-height="390"
            max-height="390"
            max-width="300"
            min-width="300"
    >
        <v-row>
            <v-col cols="7" class="mr-5">
                <v-card-title>GamePlay & Market</v-card-title>
            </v-col>
            <v-col class="pl-0 pt-7">
                <v-btn @click="didRefreshMarket()">Reload</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="ml-0 pt-0">
                <div class="font-weight-black pl-2 pr-3 pt-2">GamePlay Required</div>
                <v-row
                        class="ml-5 pt-3"
                        v-for="item in this.getGamePlayRequireText" :key="item">
                    <div v-html="item"></div>
                </v-row>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="ml-0 pt-0">
                <div class="font-weight-black pl-2 pr-3 pt-2">Market Place Shop</div>
                <v-row
                        class="ml-5 pt-3"
                        v-for="item in this.getMarketPlaceText" :key="item">
                    <div v-html="item"></div>
                </v-row>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="ml-0 pt-0">
                <div class="font-weight-black pl-2 pr-3 pt-2">Market require create more</div>
                <v-row
                        class="ml-5 pt-3"
                        v-for="item in this.getRequireMarketText" :key="item">
                    <div v-html="item"></div>
                </v-row>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
    import KpiItemRequireUtils from "./../../Utils/KpiItemRequireUtils.js";
    import {GlobalEvents, EVENT_REFRESH_MARKET} from "@/item-editor/events/GlobalEvents";

    export default {
        name: "PanelMarketItemRequire.vue",
        props: ["payload","gameLevel"],
        data() {
            return {
                kpiItemRequire: null,
            }
        },
        created(){
            this.kpiItemRequire = new KpiItemRequireUtils(this.$store.state.gamePlayDataService);
        },
        methods: {
            didRefreshMarket: function () {
                GlobalEvents.$emit(EVENT_REFRESH_MARKET);
            },
        },
        computed: {
            getGamePlayRequireText(){
                const {itemRequirePayload} = this.payload;
                let gamePlayRequire = this.kpiItemRequire.getGamePlayRequireStatusWithAmount(itemRequirePayload);
                if(gamePlayRequire.length > 0){
                    return gamePlayRequire;
                } else {
                    return ['no require'];
                }
            },
            getMarketPlaceText(){
                const {itemRequirePayload, marketWillBonusPayload} = this.payload;
                let gamePlayRequire = this.kpiItemRequire.getMarketShopItemStatusWithAmount(this.gameLevel, itemRequirePayload, marketWillBonusPayload);
                if(gamePlayRequire.length > 0){
                    return gamePlayRequire;
                } else {
                    return ['no item'];
                }
            },
            getRequireMarketText(){
                const {itemRequirePayload, marketWillBonusPayload} = this.payload;
                let marketStatus = this.kpiItemRequire.getMarketItemMatchWithGamePlayRequireStatusWithAmount(this.gameLevel,itemRequirePayload, marketWillBonusPayload);
                if(marketStatus.length > 0){
                    return marketStatus;
                } else {
                    return ['enough'];
                }
            },
        },
    }
</script>

<style scoped>

</style>