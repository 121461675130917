<template>
    <v-card
            class="mt-2"
            elevation="0"
            outlined
    >
        <v-card-subtitle><strong>INCLUDE QUEST GOLD</strong></v-card-subtitle>
        <v-row>
            <v-col class="pl-7 pt-0">
                <v-checkbox
                        v-model="isIncludeMiniQuestGold"
                        label="Include mini quest bonus"
                        @change="changedToggle"
                        class="pt-0 pl-0"
                ></v-checkbox>
                <v-card max-width="300"
                        elevation="0">
                    <v-text-field
                            label="Include mini quest gold percentage"
                            v-model="percentIncludeMiniQuestGold"
                            @input="changedSlider"
                            type="number"
                            :min="0"
                            :max="100"
                            :disabled="!isIncludeMiniQuestGold"
                    />
                </v-card>
            </v-col>

            <v-col class="pt-0">
                <v-checkbox
                        v-model="isIncludeDailyQuestGold"
                        label="Include daily quest bonus"
                        @change="changedToggle"
                        class="pt-0 pl-0"
                ></v-checkbox>
                <v-card max-width="300"
                        elevation="0">
                    <v-text-field
                            label="Include daily quest gold percentage"
                            v-model="percentIncludeDailyQuestGold"
                            @input="changedSlider"
                            type="number"
                            :min="0"
                            :max="100"
                            :disabled="!isIncludeDailyQuestGold"
                    />
                </v-card>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
    export default {
        name: "SettingGoldMiniQuestAndDailyQuest.vue",
        props: ["defaultInputSet"],
        data() {
            return {
                isIncludeMiniQuestGold: true,
                isIncludeDailyQuestGold: true,
                percentIncludeMiniQuestGold: 70,
                percentIncludeDailyQuestGold: 100,
            }
        },
        created() {
            this.isIncludeMiniQuestGold = this.defaultInputSet.isIncludeMiniQuestGold;
            this.percentIncludeMiniQuestGold = this.defaultInputSet.percentIncludeMiniQuestGold;
            this.isIncludeDailyQuestGold = this.defaultInputSet.isIncludeDailyQuestGold;
            this.percentIncludeDailyQuestGold = this.defaultInputSet.percentIncludeDailyQuestGold;
        },
        methods:{
            changedToggle() {
                this.emitState();
            },
            changedSlider() {
                this.emitState();
            },
            emitState(){
                const outPayload = {
                    isIncludeMiniQuestGold: this.isIncludeMiniQuestGold,
                    isIncludeDailyQuestGold: this.isIncludeDailyQuestGold,
                    percentIncludeMiniQuestGold: this.percentIncludeMiniQuestGold,
                    percentIncludeDailyQuestGold: this.percentIncludeDailyQuestGold,
                };
                this.$emit("onUpdateSettingGoldMiniAndDailyQuest", outPayload);
            },
        },
    }
</script>

<style scoped>

</style>