<template>
    <v-card
        max-width="760"
        min-width="760"
        min-height="700"
        max-height="700"
    >
        <v-tabs
            v-model="tab"
            background-color="deep-purple accent-4"
            centered
            dark
            icons-and-text1
        >
            <v-tabs-slider></v-tabs-slider>
            <v-tab href="#tab-market-place">
                <v-badge :color="getRequireMarketColor"
                         offset-x="140"
                         offset-y="1"
                         :content="getRequireMarketText"
                         :disabled="true"
                >
                    Market Place
                </v-badge>
            </v-tab>
            <v-tab href="#tab-gamePlay-kpi">
                GamePlay KPI
            </v-tab>
            <v-tab href="#tab-heroes">
                Heroes ({{kpiHeroPossibleUnlockCounter}})
            </v-tab>
            <v-tab href="#tab-support">
                Support ({{kpiSupportPossibleUnlockCounter}})
            </v-tab>
            <v-tab href="#tab-pet">
                Pets ({{kpiPetCount}})
            </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab"
        >
            <v-tab-item
                :key="1"
                value='tab-gamePlay-kpi'
            >
                <slot name="tabGamePlayKpi"></slot>
            </v-tab-item>
            <v-tab-item
                :key="2"
                value='tab-heroes'
            >
                <slot name="tabHeroes"></slot>
            </v-tab-item>
            <v-tab-item
                :key="3"
                value='tab-support'
            >
                <slot name="tabSupport"></slot>
            </v-tab-item>
            <v-tab-item
                :key="4"
                value='tab-pet'
            >
                <slot name="tabPet"></slot>
            </v-tab-item>
            <v-tab-item
                    :key="5"
                    value='tab-market-place'
            >
                <slot name="tabMarketPlace"></slot>
            </v-tab-item>
        </v-tabs-items>
    </v-card>
</template>

<script>
    import KpiItemRequireUtils from "./../../Utils/KpiItemRequireUtils.js";

export default {
    name: "GroupTab",
    props: ["payload"],
    data() {
        return {
            tab: 1,
            kpiItemRequire: null,
        }
    },
    created(){
        this.kpiItemRequire = new KpiItemRequireUtils(this.$store.state.gamePlayDataService);
    },
    computed: {
        kpiHeroPossibleUnlockCounter () {
            const {kpiHeroPossibleUnlockCounter} = this.payload;
            return kpiHeroPossibleUnlockCounter;
        },
        kpiSupportPossibleUnlockCounter () {
            const {kpiSupportPossibleUnlockCounter} = this.payload;
            return kpiSupportPossibleUnlockCounter;
        },
        kpiPetCount () {
            const {kpiPetCount} = this.payload;
            return kpiPetCount;
        },
        getItemRequireStatusColor(){
            const {itemRequirePayload, marketWillBonusPayload} = this.payload;
            return this.kpiItemRequire.getItemRequireStatusColor(itemRequirePayload, marketWillBonusPayload);
        },
        getRequireMarketText(){
            const {itemRequirePayload, marketWillBonusPayload} = this.payload;
            let gamePlayRequire = this.kpiItemRequire.getGamePlayRequireStatus(itemRequirePayload);
            let st = "";
            let marketMatchGamePlayRequire = this.kpiItemRequire.getMarketItemMatchWithGamePlayRequireStatus(itemRequirePayload, marketWillBonusPayload);
            if(gamePlayRequire.length > 0){
                gamePlayRequire.forEach(r => {
                    if(st !== ""){
                        st+=" | ";
                    }
                    if(marketMatchGamePlayRequire.length > 0){
                        if(marketMatchGamePlayRequire.includes(r)){
                            st += "-";
                        }
                    }
                    st +=""+r;
                });
            }
            return st;
        },
        getRequireMarketColor(){
            const {itemRequirePayload, marketWillBonusPayload} = this.payload;
            let marketMatchGamePlayRequire = this.kpiItemRequire.getMarketItemMatchWithGamePlayRequireStatus(itemRequirePayload, marketWillBonusPayload);
            if(marketMatchGamePlayRequire.length > 0){
                return 'red'
            } else {
                let gamePlayRequire = this.kpiItemRequire.getGamePlayRequireStatus(itemRequirePayload);
                if(gamePlayRequire.length > 0){
                    return 'green';
                } else {
                    return 'deep-blue';
                }
            }
        }
    }
}
</script>

<style scoped>

</style>