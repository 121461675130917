
let colors = [];

colors['orange']="#FFA500";
colors['rouge']="#D7707E";
colors['purple']="#6A0DAD";
colors['blue']="#0000FF";
colors['green']="#00FF00";
colors['brown']="#A52A2A";

let iconColorNames = [];
iconColorNames['blue']="Frame_ItemFrame01_Color_Blue.png";
iconColorNames['brown']="Frame_ItemFrame01_Color_Brown.png";
iconColorNames['green']="Frame_ItemFrame01_Color_Green.png";
iconColorNames['purple']="Frame_ItemFrame01_Color_Purple.png";
iconColorNames['rouge']="Frame_ItemFrame01_Color_Red.png";
iconColorNames['orange']="Frame_ItemFrame01_Color_Yellow.png";

let iconBattleShortcutFrame = [];
iconBattleShortcutFrame['defense']="Frame_StageFrame_n_Purple.png";
iconBattleShortcutFrame['attack']="Frame_StageFrame_n_Yellow.png";
iconBattleShortcutFrame['health']="Frame_StageFrame_n_Blue.png";

let iconBattleShortcutIcon = [];
iconBattleShortcutIcon['defense']="_0005_btn_icon_rune.png";
iconBattleShortcutIcon['attack']="_0005_btn_icon_fight.png";
iconBattleShortcutIcon['health']="_0007_btn_icon_potion.png";

let menuIcons = {
    "weapon":"_0004_btn_icon_sword.png",
    "shield":"_0008_btn_icon_defense.png",
    "ring":"_0004_btn_icon_ring.png",
    "boots":"_0007_btn_icon_speed.png",
    "potion":"_0006_btn_icon_posion.png",
    "scroll":"_0002_btn_icon_scroll.png",
    "glove":"glove-icon.png",
    "ensign":"icon-ensign.png",
    "lantern":"icon-lantern.png",
    "undefined":"_0002_btn_icon_scroll.png",
}

export default { colors, iconColorNames, iconBattleShortcutFrame, iconBattleShortcutIcon, menuIcons };