<template>
    <div v-html="computeBodyHtml"></div>
</template>

<script>
    import SMPNum from "@/SMPNum";

export default {
    name: "GroupGoldSpent",
    props: ['payload'],
    computed: {
        computeBodyHtml(){
            let heroSpent = this.kpiCumulatedGoldHeroSpent;
            let heroGold = this.kpiGoldAvailableForHeroes;
            let isHeroOutOfGold = SMPNum.greaterThan(heroSpent, heroGold);
            let heroGoldNagative = isHeroOutOfGold ? SMPNum.minus(heroSpent, heroGold) : undefined;

            let html = '';
            html += '============HEROES===========<br/>';
            html += `$G total for heroes (${this.kpiRatioAllocatedToHeroes}%) : <strong>${heroGold.ToReadableAlphabetV2()}</strong><br/>`;
            html += `&Sigma;$GAP : <strong>${this.getDescHTMLApplied(heroSpent.ToReadableAlphabetV2(), isHeroOutOfGold)}</strong><br/>`;

            html += `$G remain from &Sigma;$GAP : `;
            if(isHeroOutOfGold){
                html += `<strong>${this.getDescHTMLApplied('-'+heroGoldNagative.ToReadableAlphabetV2(), true)}</strong><br/>`;
            } else {
                html += `<strong>${this.kpiGoldRemainFromHeroSpent}</strong><br/>`;
            }

            html += '===========SUPPORTS==========<br/>';
            html += `$G total for support (${this.kpiRatioAllocatedToSupport}%) : <strong>${this.kpiGoldAvailableForSupport}</strong><br/>`;
            html += `&Sigma;$GAP : <strong>${this.kpiCumulatedGoldSupportSpent}</strong><br/>`;
            html += `$G remain from &Sigma;$GAP : <strong>${this.kpiGoldRemainFromSupportSpent}</strong><br/>`;
            html += '==============================<br/>';
            html += `$G ending : <strong>${this.kpiGoldEnding}</strong><br/>`;

            return html;
        },
        kpiGoldRemainFromSupportSpent(){
            const {kpiGoldRemainFromSupportSpent} = this.payload;
            return kpiGoldRemainFromSupportSpent.ToReadableAlphabetV2();
        },
        kpiCumulatedGoldSupportSpent () {
          const {kpiCumulatedGoldSupportSpent} = this.payload;
          return kpiCumulatedGoldSupportSpent.ToReadableAlphabetV2();
        },
        kpiRatioAllocatedToHeroes () {
            const {kpiRatioAllocatedToHeroes} = this.payload;
            return kpiRatioAllocatedToHeroes;
        },
        kpiGoldAvailableForHeroes (){
            const {kpiGoldAvailableForHeroes} = this.payload;
            return kpiGoldAvailableForHeroes;
        },
        kpiHeroLevel (){
            const {kpiHeroLevel} = this.payload;
            return kpiHeroLevel;
        },
        kpiCumulatedGoldHeroSpent () {
            const {kpiCumulatedGoldHeroSpent} = this.payload;
            return kpiCumulatedGoldHeroSpent;
        },
        kpiGoldRemainFromHeroSpent(){
            const {kpiGoldRemainFromHeroSpent} = this.payload;
            return kpiGoldRemainFromHeroSpent.ToReadableAlphabetV2();
        },
        kpiGoldEnding () {
            const {kpiGoldEnding} = this.payload;
            return kpiGoldEnding.ToReadableAlphabetV2();
        },
        kpiRatioAllocatedToHeroSkill () {
          const  {kpiRatioAllocatedToHeroSkill} = this.payload;
          return kpiRatioAllocatedToHeroSkill;
        },
        kpiGoldAvailableForHeroSkill () {
           const {kpiGoldAvailableForHeroSkill} = this.payload;
           return kpiGoldAvailableForHeroSkill.ToReadableAlphabetV2();
        },
        kpiGoldAvailableForSupport () {
            const { kpiGoldAvailableForSupport} = this.payload;
            return kpiGoldAvailableForSupport.ToReadableAlphabetV2();
        },
        kpiRatioAllocatedToSupport () {
            const { kpiRatioAllocatedToSupport} = this.payload;
            return kpiRatioAllocatedToSupport;
        },
        kpiSupportPossibleUnlockCounter () {
            const { kpiSupportPossibleUnlockCounter} = this.payload;
            return kpiSupportPossibleUnlockCounter;
        },
        kpiSupportPossibleLevel () {
            const { kpiSupportPossibleLevel} = this.payload;
            return kpiSupportPossibleLevel.ToReadableAlphabetV2();
        },
        kpiSupportPossibleSkillLevel () {
            const { kpiSupportPossibleSkillLevel} = this.payload;
            return kpiSupportPossibleSkillLevel.ToReadableAlphabetV2();
        }
    },
    methods:{

        getFullImpactDescription(impactBonus){
            let desc = this.getDescHTMLApplied(impactBonus.title+" "+ impactBonus.behaviorDesc[0].desc, impactBonus.behaviorDesc[0].isCanApply);
            if(impactBonus.behaviorDesc.length> 0){
                for(let index=0;index<impactBonus.joinBonus.length; index++){
                    let joinBonus = impactBonus.joinBonus[index];
                    desc += " | "+ this.getDescHTMLApplied(joinBonus.title + " " + impactBonus.behaviorDesc[index+1].desc, impactBonus.behaviorDesc[index+1].isCanApply);
                }
            }
            return desc;
        },
        getDescHTMLApplied(desc, isRed){
            return '<font color='+this.getColorByApply(isRed)+'>'+desc+'</font>';
        },
        getColorByApply(isRed){
            if(isRed){
                return "#ff3200";
            } else {
                return "#000000";
            }
        }
    }
}
</script>

<style scoped>

</style>