class ItemRequirePercentageModel {

    level = 0;
    hpRequirePercent = 0;
    dmgRequirePercent = 0;
    hpMarketPercent = 0;
    dmgMarketPercent = 0;

    constructor(level, requireItem){
        this.level = level;
        this.hpRequirePercent = requireItem.hpRequirePercent;
        this.dmgRequirePercent = requireItem.dmgRequirePercent;
        this.hpMarketPercent = requireItem.hpMarketPercent;
        this.dmgMarketPercent = requireItem.dmgMarketPercent;
    }
}

export default ItemRequirePercentageModel;