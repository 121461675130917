<template>
    <v-card
            min-width="450"
            max-width="450"
            min-height="550"
            class="pa-2"
    >
        <v-card
                flat
                color="transparent">
            <v-card-title>Achievement</v-card-title>
            <v-row class="pl-4 pt-0 pb-0 ma-0">
                Completed: {{pAchievementList.length}}
            </v-row>
            <v-row class="pl-4 pt-0 pb-0 ma-0">
                Reward: {{pReward}}
            </v-row>
        </v-card>
        <template>
            <v-virtual-scroll
                    :height="400"
                    item-height="30"
                    class="ml-6 pt-4"
                    :items="this.pAchievementList"
                    id="kpi-achievement-scroller"
            >
                <template v-slot:default="{ item }">
                    <div class="pt-5">
                        <v-row dense no-gutters class="mt-4 ml-0">
                            <v-col>
                                - {{ item.questTitleCode }} (reward:{{ item.reward }})
                            </v-col>
                        </v-row>
                    </div>
                </template>
            </v-virtual-scroll>
        </template>
    </v-card>
</template>

<script>

export default {
    name: "AchievementListPopup",
    props:["pAchievementList", "pReward"],
    data () {
      return {
      }
    },
    computed: {
    },
    methods: {
    },
    created() {
    },
}
</script>

<style scoped>

</style>