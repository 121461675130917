<template>
    <v-card
        min-width="550"
        class="mt-2"
        elevation="0"
    >
        <div class="font-weight-black">MARKET ITEM REQUIRE SETTING</div>
        <v-card
            class="pt-4 pb-0"
            elevation="0">
            <v-subheader>Min time (20 seconds) kill boss require item dmg</v-subheader>
            <v-slider
                v-model="timeRequireForDmgItem"
                track-color="grey"
                @input="onRatioChange"
                always-dirty
                thumb-label
                min="1"
                max="100"
            >
                <template v-slot:append>
                    <v-text-field
                        v-model="timeRequireForDmgItem"
                        class="mt-0 pt-0"
                        type="number"
                        min="1"
                        max="100"
                    ></v-text-field>
                </template>
            </v-slider>
        </v-card>
        <v-card
                class="pt-4 pb-0"
                elevation="0">
            <v-subheader>Min time (10 seconds) of hero can stay on boss for require item hp</v-subheader>
            <v-slider
                    v-model="timeRequireForHpItem"
                    track-color="grey"
                    @input="onRatioChange"
                    always-dirty
                    thumb-label
                    min="1"
                    max="100"
            >
                <template v-slot:append>
                    <v-text-field
                            v-model="timeRequireForHpItem"
                            class="mt-0 pt-0"
                            type="number"
                            min="1"
                            max="100"
                    ></v-text-field>
                </template>
            </v-slider>
        </v-card>
    </v-card>
</template>

<script>
export default {
    name: "PanelMarketItemRequireConfig",
    props:["payload", "defaultInputSet"],
    created() {
        this.timeRequireForDmgItem = this.defaultInputSet.timeRequireForDmgItem;
        this.timeRequireForHpItem = this.defaultInputSet.timeRequireForHpItem;
    },
    data() {
        return {
            timeRequireForDmgItem: 20,
            timeRequireForHpItem: 10
        }
    },
    computed: {
        kpiTotalDiamonds () {
            const { kpiTotalDiamonds } = this.payload;
            return kpiTotalDiamonds;
        },
        kpiDiamondAvailableForPet () {
            const { kpiDiamondAvailableForPet } = this.payload;
            return kpiDiamondAvailableForPet;
        }
    },
    methods: {
        onRatioChange() {
            this.emitState();
        },
        emitState(){
            const outPayload = {
                timeRequireForDmgItem: this.timeRequireForDmgItem,
                timeRequireForHpItem: this.timeRequireForHpItem
            };

            this.$emit("onUpdateSettingMarketRequire", outPayload);
        }
    }
}
</script>

<style scoped>

</style>