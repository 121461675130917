import axios from "axios";
import NetworkUtils from "@/NetworkUtils";
import store from "../../store";
const authorize =  `Bearer ${store.getters.getToken}`;
const config = {
    headers: { Authorization: authorize }
};

export default {
    loadData: async function (environmentId) {
        let data = await axios.get(NetworkUtils.HOST + "/simulator-item-require/getByEnvironmentId?environmentId="+environmentId, config);
        let configRQ = {};
        let list = [];
        if (data.data.length > 0) {
            configRQ = data.data[0].config;
            list = data.data[0].list;
        } else {
            configRQ = {
                startLevel: 1,
                stepLevel: 1,
                endLevel: 10000
            }
        }
        return {
            config : configRQ,
            list: list
        };
    },

    async pushSimulatorItemRequireData(simulatorData, environmentId) {
        let config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: authorize
            },
        };
        let data = JSON.stringify(simulatorData);
        //console.log('pushSimulatorItemRequireData ', config);
        await axios.post(NetworkUtils.HOST+"/simulator-item-require/insertOrUpdate?environmentId="+environmentId, data, config);
    },

}